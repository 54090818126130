import { Table } from "antd";
import React from "react";
import { SYDivider } from "../../../resources/styledComponents";
import moment from "moment";

function StudentBitChanges({ bits }) {
  const columns = [
    {
      title: "Tarih",
      dataIndex: "createdOn",
      render: (createdOn) => (
        <span>{moment(createdOn).local().format("DD MMM YY, ddd HH:mm")}</span>
      ),
    },
    {
      title: "Açıklama",
      dataIndex: "message",
    },
    {
      title: "Değişim",
      dataIndex: "saved",
      render: (saved) => (
        <span style={{ color: saved ? "green" : "red" }}>
          {saved ? "Yükleme" : "Kullanım"}
        </span>
      ),
    },
    {
      title: "Bit Değeri",
      dataIndex: "val",
    },
  ];
  return (
    <div>
      <SYDivider renk="mavi">Bit Değişiklikleri</SYDivider>
      <Table dataSource={bits} bordered size="small" columns={columns} />
    </div>
  );
}

export default StudentBitChanges;
