import {
  Button,
  Divider,
  notification,
  Popconfirm,
  Space,
  Table,
  Tag,
} from "antd";
import React, { useEffect, useState } from "react";
import { StaffService } from "../../../services/StaffService";
import { giveLessonType } from "../../../resources/getLessonType";
import { DeleteOutlined } from "@ant-design/icons";
import moment from "moment";

function LessonTaskList({ dataSource, sir }) {
  const deleteTask = (id, st) => {
    if (st > 0) {
      notification.error({
        message: "Uyarı!",
        description: "En az 1 öğrenci tarafından alınmış kaydı silemezsiniz.",
      });
    } else {
      StaffService.deleteLessonTaskById(id).then((data) => {
        notification.success({
          message: "Başarılı.",
          description: "Ders kaydı silindi.",
        });
        sir(true);
      });
    }
  };

  const colors = {
    4: "blue",
    5: "magenta",
    6: "purple",
  };
  const columns = [
    {
      title: "Ders Türü",
      dataIndex: "task",
      render: (task) => (
        <Tag color={colors[task.tip]}>{giveLessonType[task.tip]}</Tag>
      ),
    },
    {
      title: "Öğretmen",
      dataIndex: "teacher",
    },
    {
      title: "Dersler",
      dataIndex: "task",
      render: (task) => (
        <Tag color="geekblue">{task.lessons.name.toUpperCase()}</Tag>
      ),
    },
    //   {
    //     title: "Gruplar",
    //     dataIndex: "gruplar",
    //     key: "gruplar",
    //     render: (gruplar) => (
    //       <>
    //         {gruplar.map((grup) => {
    //           return <Tag key={grup.id}>{grup.name.toUpperCase()}</Tag>;
    //         })}
    //       </>
    //     ),
    //   },
    {
      title: "Konu",
      dataIndex: "task",
      render: (task) => task.konu,
    },
    {
      title: "Ders",
      dataIndex: "task",
      render: (task) => task.saatSayisi,
    },
    {
      title: "Saatler",
      dataIndex: "task",
      render: (task) => (
        <>
          {task.appos.map((appo) => {
            return (
              <Tag key={appo.id}>
                {moment(appo.dateTime).local().format("DD MMM, ddd   HH:mm")}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: "Alan Kişi",
      dataIndex: "students",
      render: (students) => <>{students.length}</>,
    },
    {
      title: "İşlem",
      key: "action",
      render: (text, record) => (
        <Space>
          <Popconfirm
            title="Silmek istediğinize emin misiniz?"
            onConfirm={() => deleteTask(record.task.id, record.students.length)}
            okText="Evet"
            cancelText="İptal"
          >
            <Button
              type="primary"
              shape="circle"
              icon={<DeleteOutlined />}
              danger
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];
  return (
    <div>
      <Divider orientation="left" style={{ color: "#1890FF" }}>
        Tanımlanmış Grup / Amfi dersleri
      </Divider>
      <Table size="small" bordered dataSource={dataSource} columns={columns} />
    </div>
  );
}

export default LessonTaskList;
