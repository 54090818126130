import { Button, Col, Form, Input, notification, Row, Space } from "antd";
import React, { useContext } from "react";
import { SYBox, SYDivider, SYP } from "../../resources/styledComponents";
import { SYColors } from "../../resources/SYColors";
import { HomeService } from "../../services/HomeService";
import { UserContext } from "../../context/UserContext";
import { GlobalContext } from "../../context/GlobalContext";
import { useTranslation } from "react-i18next";

function Contact() {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { myFullname, myEmail } = useContext(UserContext);
  const { kurum } = useContext(GlobalContext);
  const onFinish = (values) => {
    HomeService.newContactReq(values).then((data) => {
      form.resetFields();
      notification.success({
        message: t("contact.tesekkur"),
        description: t("contact.talepIletildiMessage"),
      });
    });
  };
  const onReset = () => {
    form.resetFields();
  };
  return (
    <SYBox>
      <SYBox
        style={{
          background:
            "radial-gradient(circle, rgba(10,52,103,1) 35%, rgba(20,69,130,1) 65%, rgba(9,66,136,1) 77%)",
        }}
        display="flex"
        alignItems="center"
        justifyContent="center"
        height={150}
        boxShadow="0 0 10px #333"
        textAlign="center"
      >
        <SYP fontSize="40px" color="white" m={0} p={15}>
          {t("contact.nasilYardim")}
        </SYP>
      </SYBox>
      <br />
      <br />
      <SYBox
        bg={SYColors.f5}
        border="1px solid #ddd"
        boxShadow="0 0 5px #eee"
        p={30}
        style={{ textAlign: "center", maxWidth: "800px", margin: "0 auto" }}
      >
        <Row>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <SYDivider renk="mavi">{t("forms.telefon")}</SYDivider>
            <SYP mb={0} fontSize="20px">
              {kurum && kurum.phone1}
            </SYP>
            <SYP fontSize="20px">{kurum && kurum.phone2}</SYP>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <SYDivider renk="mavi">{t("forms.adres")}</SYDivider>
            <SYP mb={0} fontSize="20px">
              {kurum && kurum.address1}
            </SYP>
            <SYP fontSize="20px">{kurum && kurum.address2}</SYP>
          </Col>
        </Row>
        <SYDivider renk="mavi">{t("contact.iletisimFormu")}</SYDivider>
        <Form
          form={form}
          onFinish={onFinish}
          style={{ paddingLeft: "30px", paddingRight: "20px" }}
          initialValues={myFullname && { fullname: myFullname, email: myEmail }}
        >
          <Form.Item
            name="fullname"
            rules={[{ required: true, message: t("forms.messages.gerekli") }]}
          >
            <Input placeholder={t("contact.adinizSoyadiniz")} />
          </Form.Item>
          <Form.Item
            name="phone"
            rules={[{ required: true, message: t("forms.messages.gerekli") }]}
          >
            <Input placeholder={t("contact.telefonNumaraniz")} />
          </Form.Item>
          <Form.Item
            name="email"
            rules={[{ required: true, message: t("forms.messages.gerekli") }]}
          >
            <Input placeholder={t("contact.emailAdresiniz")} />
          </Form.Item>
          <Form.Item
            name="subject"
            rules={[{ required: true, message: t("forms.messages.gerekli") }]}
          >
            <Input.TextArea placeholder={t("contact.neBilgisi")} />
          </Form.Item>
          <Form.Item>
            <Space>
              <Button type="primary" htmlType="submit">
                {t("forms.gonder")}
              </Button>
              <Button ghost type="primary" onClick={onReset}>
                {t("forms.temizle")}
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </SYBox>
      <br />
      <br />
    </SYBox>
  );
}

export default Contact;
