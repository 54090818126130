import { UploadOutlined } from "@ant-design/icons";
import {
  Form,
  Input,
  Upload,
  message,
  Button,
  Space,
  notification,
  Select,
} from "antd";
import React, { useState, useEffect } from "react";
import { StaffService } from "../../../services/StaffService";
import { getMyData } from "../../../resources/getUserData";
import { MainService } from "../../../services/MainService";
import { SYDivider } from "../../../resources/styledComponents";
import { useTranslation } from "react-i18next";

function NewQuestionForm() {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [lessons, setLessons] = useState([]);
  const [initialRender, setInitialRender] = useState(true);
  const [teachers, setTeachers] = useState([]);
  const [selectedTeacher, setSelectedTeacher] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      StaffService.getLessons().then((data) => {
        setLessons(data.data.filter((l) => l.isActive === true));
      });
    }
  }, [initialRender]);
  function beforeUpload(file) {
    const isPDF = file.type === "application/pdf";
    const isJPG = file.type === "image/jpeg";
    const isPNG = file.type === "image/png";
    if (!isPDF && !isJPG && !isPNG) {
      message.error(t("forms.upload.imgPdf"));
    }
    const isLt1M = file.size / 1024 / 1024 < 2;
    if (!isLt1M) {
      message.error(t("forms.upload.boyut"));
    }
    return (isPDF || isJPG || isPNG) && isLt1M;
  }
  const onFileChange = (info) => {
    setFileList(info.fileList.filter((file) => !!file.status));
  };
  const onReset = () => {
    form.resetFields();
    setFileList([]);
  };
  const onFinish = (values) => {
    values = {
      ...values,
      soruUrl: fileList.length > 0 ? fileList[0].response.url : null,
      studentId: parseInt(getMyData.getMyId()),
      toTeacherId: selectedTeacher > 0 ? selectedTeacher : 0,
    };

    MainService.yeniSoruSor(values).then((data) => {
      form.resetFields();
      setFileList([]);
      setTeachers([]);
      setSelectedTeacher(null);
      notification.success({
        message: t("global.basarili"),
        description: t("main.soru.soruIletildi"),
      });
    });
  };
  const ogretmenChanged = (a) => {
    setSelectedTeacher(a);
  };
  const lessonChanged = (val) => {
    if (val > -1) {
      StaffService.getTeachersbyLesson(val).then((data) => {
        setTeachers(data.data);
      });
    }
  };
  return (
    <div>
      <div
        style={{
          backgroundColor: "#f5f5f5",
          border: "1px solid #ddd",
          padding: "0 20px",
          maxWidth: "600px",
        }}
      >
        <SYDivider renk="mavi">{t("main.soru.yeniSoru")}</SYDivider>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item
            label={t("main.soru.soruDetay")}
            name="soru"
            rules={[{ required: true, message: t("forms.messages.gerekli") }]}
          >
            <Input.TextArea
              autoSize
              style={{ minHeight: "80px" }}
              placeholder={t("main.soru.soruDetayPlaceholder")}
            />
          </Form.Item>
          <Form.Item
            name="kategori"
            label={t("main.soru.kategoriSec")}
            rules={[{ required: true, message: t("forms.messages.gerekli") }]}
          >
            <Select
              placeholder={t("main.soru.kategoriSec")}
              allowClear
              onChange={lessonChanged}
            >
              <Select.Option key="gbt" value={-1}>
                {t("main.soru.genelBilgi")}
              </Select.Option>
              <Select.Option key="rs" value={-2}>
                {t("main.soru.rehberlik")}
              </Select.Option>
              {lessons.map((lesson) => (
                <Select.Option key={lesson.id} value={lesson.id}>
                  {t("main.soru.dersSorusu", { ders: lesson.name })}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          {teachers.length > 0 && (
            <Form.Item name="teacherId" label={t("forms.ogretmenSec")}>
              <Select
                placeholder={t("forms.placeholders.ogretmenSec")}
                onSelect={ogretmenChanged}
                allowClear
              >
                <Select.Option key={0} value={0}>
                  {t("forms.farketmez")}
                </Select.Option>
                {teachers.map((t) => (
                  <Select.Option key={t.teacher.id} value={t.teacher.id}>
                    {t.teacher.fullname}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
          <Form.Item label={<small>{t("main.soru.dosyaEkle")}</small>}>
            <div>
              <Upload
                action={process.env.REACT_APP_API_BASEURL + "upload"}
                headers={{
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                }}
                fileList={fileList}
                beforeUpload={beforeUpload}
                onChange={onFileChange}
              >
                {fileList.length >= 1 ? null : (
                  <Button icon={<UploadOutlined />}>
                    {t("forms.upload.dosyaSec")}
                  </Button>
                )}
              </Upload>
            </div>
          </Form.Item>
          <Form.Item>
            <Space>
              <Button type="primary" htmlType="submit">
                {t("forms.gonder")}
              </Button>
              <Button htmlType="button" onClick={onReset}>
                {t("forms.temizle")}
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default NewQuestionForm;
