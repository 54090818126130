import { Divider, Switch, Table, Tag, Input, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { StaffService } from "../../services/StaffService";
import moment from "moment";

function ContactReqList() {
  const [initialRender, setInitialRender] = useState(true);
  const [reqs, setReqs] = useState([]);
  const [noteInput, setNoteInput] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [values, setValues] = useState({});
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      StaffService.getContactReqs().then((data) => {
        setReqs(data.data);
      });
    }
  }, [initialRender]);

  const columns = [
    {
      title: "Ad Soyad",
      dataIndex: "reqs",
      render: (reqs) => reqs.fullname,
    },
    {
      title: "Telefon",
      dataIndex: "reqs",
      render: (reqs) => reqs.phone,
    },
    {
      title: "Email",
      dataIndex: "reqs",
      render: (reqs) => reqs.email,
    },
    {
      title: "Konu",
      dataIndex: "reqs",
      render: (reqs) => reqs.subject,
    },
    {
      title: "Arandı mı?",
      dataIndex: "reqs",
      render: (reqs) => (
        <Switch
          defaultChecked={reqs.isCalled}
          onChange={(a) => switchChanged(a, reqs.id)}
        ></Switch>
      ),
      textWrap: "word-break",
    },
    {
      title: "Tarih",
      dataIndex: "createdOn",
      key: "createdOn",
      render: (createdOn) => (
        <Tag>{moment(createdOn).local().format("DD MMM, ddd  HH:mm")}</Tag>
      ),
    },
    {
      title: "Not",
      dataIndex: "reqs",
      render: (reqs) => reqs.note,
    },
  ];
  const inputChange = (v) => {
    setNoteInput(v.target.value);
  };
  const switchChanged = (a, id) => {
    setModalVisible(true);
    setValues({ id: id, isCalled: a });
  };
  const saveNote = () => {
    var aa = noteInput;
    setNoteInput("");
    StaffService.updateContactReqs({ ...values, note: aa }).then((data) => {
      setInitialRender(true);
      setModalVisible(false);
    });
  };
  return (
    <div>
      <Modal
        cancelButtonProps={{ disabled: true }}
        cancelText="Kapat"
        okText="Kaydet"
        onOk={saveNote}
        visible={modalVisible}
        title="Arama ile ilgili mesajınızı yazınız"
      >
        <Input
          value={noteInput}
          autoFocus
          onChange={inputChange}
          placeholder="Notunuzu yazınız"
        />
        ,
      </Modal>
      <Divider>İletişim Talepleri</Divider>
      <Table bordered dataSource={reqs} columns={columns} />
    </div>
  );
}

export default ContactReqList;
