import React, { useContext, useEffect, useState } from "react";
import { Layout, Menu, Row, Col, Dropdown, Space, Badge } from "antd";
import { Switch, Route, Redirect, Link, useHistory } from "react-router-dom";
import {
  AppstoreTwoTone,
  CheckCircleTwoTone,
  CreditCardTwoTone,
  DashboardTwoTone,
  DownOutlined,
  HomeOutlined,
  IdcardTwoTone,
  InteractionTwoTone,
  LockOutlined,
  MailTwoTone,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  MessageOutlined,
  MessageTwoTone,
  PieChartTwoTone,
  PlusSquareTwoTone,
  ProfileTwoTone,
  QuestionCircleTwoTone,
  SafetyOutlined,
  ScheduleTwoTone,
  SnippetsTwoTone,
  UserOutlined,
  WalletTwoTone,
} from "@ant-design/icons";
import MainPage from "./MainPage";
//import SideLessons from "../../components/main/SideLessons";
import GetLesson from "../../components/main/GetLesson/GetLesson";
import "./Main.css";
import GetQuestion from "../../components/main/GetQuestion/GetQuestion";
import AnswerQuestion from "../../components/main/GetQuestion/AnswerQuestion";
import MyQuestions from "../../components/main/GetQuestion/MyQuestions";
import MainTeachers from "../../components/main/Teachers/MainTeachers";
import MainTeacherDetails from "../../components/main/Teachers/MainTeacherDetails";
import MyMessages from "../../components/main/Messages/MyMessages";
import MyLessons from "../../components/main/GetLesson/MyLessons";
import MyRequests from "../../components/main/GetLesson/MyRequests";
import { GlobalContext } from "../../context/GlobalContext";
import { UserContext } from "../../context/UserContext";
import TeacherExams from "../../components/main/Exams/TeacherExams";
import TeacherExamDetails from "../../components/main/Exams/TeacherExamDetails";
import StudentExams from "../../components/main/Exams/StudentExams";
import StudentExamDetails from "../../components/main/Exams/StudentExamDetails";
import { MainService } from "../../services/MainService";
import MyLessonsHistory from "../../components/main/GetLesson/MyLessonsHistory";
import { BitIcon } from "../../resources/styledComponents";
import UseBitCard from "../../components/main/Bit/UseBitCard";
import Contact from "../../components/home/Contact";
import { TeacherRoute } from "../../resources/PrivateRoute";
import Schedule from "../../components/main/GetLesson/Schedule";
import BitMarket from "../../components/main/Bit/BitMarket";
import { useTranslation } from "react-i18next";
const { Header, Content, Footer, Sider } = Layout;

function HomeLayout() {
  //const screens = Grid.useBreakpoint();
  const { t } = useTranslation();
  const [collapsed, setCollapsed] = useState(false);
  const [bp, setBp] = useState(null);
  const router = useHistory();
  const {
    bitValue,
    unreadMessages,
    setUnreadMessages,
    unreadExams,
    setUnreadExams,
    unreadQuestions,
    setUnreadQuestions,
    kurum,
  } = useContext(GlobalContext);
  const { myFullname, myRole, myId } = useContext(UserContext);
  const [initialRender, setInitialRender] = useState(true);
  // const [lapse, setLapse] = useState(!screens.lg);

  const toggle = () => {
    setCollapsed(!collapsed);
  };
  const menuSelected = (item) => {
    bp && setCollapsed(true);
    console.log(item);
    // router.push(item.item.props.link);
  };
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      MainService.getMyUnreadMessagesCount(myRole, parseInt(myId)).then(
        (data) => {
          setUnreadMessages(data.data.messages);
          setUnreadExams(data.data.exams);
          setUnreadQuestions(data.data.questions);
        }
      );
    }
  }, [
    initialRender,
    myId,
    bitValue,
    myRole,
    setUnreadMessages,
    setUnreadExams,
    setUnreadQuestions,
  ]);

  const logout = () => {
    localStorage.clear();
    router.push("/login");
  };
  const gohome = () => {
    router.push("/");
  };
  const gostaff = () => {
    router.push("/staff");
  };
  const goadmin = () => {
    router.push("/admin");
  };
  const usermenu = (
    <Menu>
      <Menu.Item onClick={gohome}>
        <HomeOutlined /> {t("main.menu.girisSayfasi")}
      </Menu.Item>
      {(myRole === "Admin" || myRole === "SuperAdmin") && (
        <Menu.Item onClick={gostaff}>
          <SafetyOutlined /> {t("main.menu.adminSayfasi")}
        </Menu.Item>
      )}
      {myRole === "SuperAdmin" && (
        <Menu.Item onClick={goadmin}>
          <SafetyOutlined /> {t("main.menu.superAdminSayfasi")}
        </Menu.Item>
      )}
      <Menu.Item onClick={() => router.push("/main/mymessages")}>
        <MessageOutlined /> {t("main.menu.mesajlarim")}
      </Menu.Item>
      <Menu.Item danger onClick={logout}>
        <LockOutlined /> {t("main.menu.cikis")}
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout>
      <Sider
        style={
          bp
            ? {
                position: "absolute",
                minHeight: "100vh",
                zIndex: "1",
                marginTop: "65px",
                borderRight: "1px solid #ddd",
              }
            : {
                backgroundColor: "#ffffff",
                borderRight: "1px solid #ddd",
              }
        }
        breakpoint="xl"
        collapsedWidth="0"
        onBreakpoint={(broken) => {
          console.log(broken);
          setBp(broken);
          setCollapsed(broken);
        }}
        onCollapse={() => {
          // setCollapsed(true);
        }}
        theme="light"
        trigger={null}
        collapsible
        collapsed={collapsed}
      >
        {kurum && (
          <img
            className="logo"
            src={process.env.REACT_APP_FILES_BASEURL + kurum.logo}
            alt="logo"
          />
        )}
        <Menu
          mode="inline"
          defaultSelectedKeys={["1"]}
          style={{
            borderRight: "none",
            borderTop: "1px solid #ddd",
            paddingBottom: "50px",
          }}
          onSelect={(item) => menuSelected(item)}
        >
          <Menu.Item key="1" icon={<DashboardTwoTone />}>
            <Link to="/main/mainpage">{t("main.menu.kontrolPaneli")}</Link>
          </Menu.Item>
          {myRole === "Student" && (
            <>
              <Menu.Item key="12" icon={<WalletTwoTone />}>
                <Link to="/main/bit">{t("main.menu.bitHesabim")}</Link>
              </Menu.Item>
              <Menu.Item key="13" icon={<CreditCardTwoTone />}>
                <Link to="/main/usebitcard">{t("main.menu.bitYukle")}</Link>
              </Menu.Item>
              <Menu.Item key="2" icon={<PlusSquareTwoTone />}>
                <Link to="/main/getlesson">{t("main.menu.dersAl")}</Link>
              </Menu.Item>
            </>
          )}
          {myRole === "Teacher" && (
            <Menu.Item key="15" icon={<ScheduleTwoTone />}>
              <Link to="/main/schedule">{t("main.menu.ajanda")}</Link>
            </Menu.Item>
          )}
          <Menu.Item key="8" icon={<AppstoreTwoTone />}>
            <Link to="/main/mylessons">{t("main.menu.derslerim")}</Link>
          </Menu.Item>
          <Menu.Item key="11" icon={<InteractionTwoTone />}>
            <Link to="/main/mylessonshistory">
              {t("main.menu.dersGecmisim")}
            </Link>
          </Menu.Item>
          {myRole === "Student" && (
            <>
              <Menu.Item key="9" icon={<SnippetsTwoTone />}>
                <Link to="/main/myreqs">{t("main.menu.taleplerim")}</Link>
              </Menu.Item>
              <Menu.Item key="3" icon={<QuestionCircleTwoTone />}>
                <Link to="/main/getquestion">{t("main.menu.soruSor")}</Link>
              </Menu.Item>
              <Menu.Item key="5" icon={<ProfileTwoTone />}>
                <Link to="/main/myquestions">{t("main.menu.sorularim")}</Link>
              </Menu.Item>
            </>
          )}
          {myRole === "Teacher" && (
            <Menu.Item key="4" icon={<CheckCircleTwoTone />}>
              <Badge count={unreadQuestions} offset={[8, 0]}>
                <Link to="/main/answerquestion">
                  {t("main.menu.soruCevapla")}
                </Link>
              </Badge>
            </Menu.Item>
          )}

          <Menu.Item key="6" icon={<IdcardTwoTone />}>
            <Link to="/main/teachers">{t("main.menu.ogretmenler")}</Link>
          </Menu.Item>
          <Menu.Item key="7" icon={<MessageTwoTone />}>
            <Link to="/main/mymessages">
              <Badge count={unreadMessages} offset={[8, 0]}>
                <span>{t("main.menu.mesajlarim")}</span>
              </Badge>
            </Link>
          </Menu.Item>
          <Menu.Item key="10" icon={<PieChartTwoTone />}>
            <Badge count={unreadExams} offset={[8, 0]}>
              <Link to="/main/exams">{t("main.menu.sinavlar")}</Link>
            </Badge>
          </Menu.Item>
          <Menu.Item key="14" icon={<MailTwoTone />}>
            <Link to="/main/contact">{t("main.menu.iletisim")}</Link>
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout>
        <Header
          className="site-layout-sub-header-background"
          style={{ padding: 0, borderBottom: "1px solid #ddd" }}
        >
          <Row
            justify="space-between"
            align="middle"
            style={{ margin: "0 10px 0 0" }}
          >
            <Col>
              {collapsed ? (
                <MenuUnfoldOutlined
                  className="trigger"
                  onClick={() => toggle()}
                />
              ) : (
                <MenuFoldOutlined
                  className="trigger"
                  onClick={() => toggle()}
                />
              )}
            </Col>
            <Col>
              <Space>
                {bitValue !== null && myRole === "Student" && (
                  <>
                    <BitIcon
                      width="44px"
                      height="44px"
                      style={{ marginTop: "10px" }}
                    />

                    <div style={{ marginTop: "-15px" }}>
                      <span
                        style={{
                          fontSize: "20px",
                          fontWeight: "700",
                          color: "#DD6400",
                        }}
                      >
                        {bitValue.toString()}
                      </span>
                      {/* <span style={{ fontWeight: "500" }}> Bit'in var</span> */}
                    </div>
                  </>
                )}
              </Space>
            </Col>
            <Col>
              <Dropdown trigger={["click"]} overlay={usermenu}>
                <span
                  style={{
                    cursor: "pointer",
                    color: "#1890FF",
                    border: "1px solid #ddd",
                    padding: "8px",

                    borderRadius: "3px",
                    backgroundColor: "#fafafa",
                  }}
                >
                  <UserOutlined />{" "}
                  <span
                    className="ant-dropdown-link"
                    onClick={(e) => e.preventDefault()}
                  >
                    {myFullname || t("global.giris")} <DownOutlined />
                  </span>
                </span>
              </Dropdown>
            </Col>
          </Row>
        </Header>
        <Content
          style={{ margin: "16px 16px 0", minHeight: "calc(100vh - 202px)" }}
        >
          {/* <Row>
            <Col flex="1 1 500px">
              {" "} */}
          <Switch>
            <Route exact path={"/main"}>
              <Redirect to={"/main/mainpage"} />
            </Route>
            <Route path={"/main/mainpage"}>
              <MainPage />
            </Route>
            <Route path={"/main/getlesson"}>
              <GetLesson />
            </Route>
            <Route path={"/main/getquestion"}>
              <GetQuestion />
            </Route>
            <TeacherRoute
              path={"/main/answerquestion"}
              component={AnswerQuestion}
            />
            <Route path={"/main/myquestions"}>
              <MyQuestions />
            </Route>
            <Route path={"/main/teachers/:id"}>
              <MainTeacherDetails />
            </Route>
            <Route path={"/main/teachers"}>
              <MainTeachers />
            </Route>
            <Route path={"/main/mymessages"}>
              <MyMessages />
            </Route>
            <Route path={"/main/mylessons"}>
              <MyLessons />
            </Route>
            <Route path={"/main/mylessonshistory"}>
              <MyLessonsHistory />
            </Route>
            <Route path={"/main/myreqs"}>
              <MyRequests />
            </Route>
            <Route path={"/main/exams/student/:id"}>
              <StudentExamDetails />
            </Route>
            <Route path={"/main/exams/:id"}>
              <TeacherExamDetails />
            </Route>
            <Route path={"/main/exams"}>
              {myRole === "Student" ? <StudentExams /> : <TeacherExams />}
            </Route>
            <Route path={"/main/bit"}>
              <BitMarket />
            </Route>
            <Route path={"/main/usebitcard"}>
              <UseBitCard />
            </Route>
            <Route path={"/main/contact"}>
              <Contact />
            </Route>
            <TeacherRoute path={"/main/schedule"} component={Schedule} />
            <Route>
              <Redirect to={"/home"} />
            </Route>
          </Switch>
          {/* </Col>
             <Col
              flex="0 1 300px"
              style={{
                backgroundColor: "#f5f5f5",
                borderLeft: "1px solid #ddd",
                borderRight: "1px solid #ddd",
                padding: "10px",
                margin: "-16px -16px 0 0",
                marginBottom: "-50px",
              }}
            >
              <SideLessons />
            </Col> 
          </Row>*/}
        </Content>
        <Footer style={{ textAlign: "center", marginTop: "50px" }}>
          {kurum && kurum.name} ©2021 Created by Hypotenuse
        </Footer>
      </Layout>
    </Layout>
  );
}

export default HomeLayout;
