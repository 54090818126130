import {
  Row,
  Col,
  Descriptions,
  Button,
  Form,
  Select,
  Space,
  Modal,
  List,
  notification,
  Popconfirm,
} from "antd";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { StaffService } from "../../services/StaffService";
import { getLocation } from "../../resources/il-ilce";
import Title from "antd/lib/typography/Title";
import moment from "moment";
import { giveLessonType } from "../../resources/getLessonType";
import { MainService } from "../../services/MainService";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { giveLessonReqTime } from "../../resources/getLessonReqTime";
import { SYSpan } from "../../resources/styledComponents";

function LessonReqDetails() {
  const { id } = useParams();
  const [initialRender, setInitialRender] = useState(true);
  const [reqs, setReqs] = useState(null);
  const [atamaPaneli, setAtamaPaneli] = useState(false);
  const [teachers, setTeachers] = useState([]);
  const [secilenler, setSecilenler] = useState([]);
  const [saatler, setSaatler] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [form] = Form.useForm();
  const router = useHistory();
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      StaffService.getLessonReqById(id).then((data) => {
        setReqs(data.data);
        StaffService.getTeachersbyLesson(data.data.detay.lessonsId).then(
          (data2) => {
            setTeachers(data2.data);
          }
        );
      });
    }
  }, [initialRender, id]);
  const dersTanimlaClicked = () => {
    setAtamaPaneli(true);
  };
  const ogretmenChanged = (e, item, val) => {
    setSecilenler([]);
    StaffService.getSchedule(e).then((data) => {
      setSaatler(data.data);
      setModalVisible(true);
    });
  };
  const timeSelected = (item, index) => {
    if (secilenler.length + 1 === reqs.detay.saatSayisi) {
      setModalVisible(false);
    }
    setSaatler(saatler.filter((s) => s.id !== item.id));
    setSecilenler([...secilenler, item]);
  };
  const secilenClicked = (item) => {
    setSecilenler(secilenler.filter((a) => a.id !== item.id));
    setSaatler([...saatler, item]);
  };
  const modalHandleOk = () => {
    setModalVisible(false);
  };
  const modalHandleCancel = () => {
    setModalVisible(false);
  };
  const formFinished = (values) => {
    var secimler = [];
    // var ucret = 0;
    secilenler.forEach((s) => {
      secimler.push(s.id);
      //    ucret = ucret + s.onlinegrup;
    });
    values = {
      ...values,
      tip: reqs.detay.netur,
      konu: reqs.detay.konu,
      rate: reqs.detay.rate,
      saatSayisi: reqs.detay.saatSayisi,
      zaman: reqs.detay.zaman,
      nerede: reqs.detay.nerede,
      secilenler: secimler,
      isteyenId: reqs.detay.studentId,
      lessonsId: reqs.detay.lessonsId,
      isPersonal: reqs.detay.isPersonal,
      kisi1: reqs.detay.kisi1,
      kisi2: reqs.detay.kisi2,
      kisi3: reqs.detay.kisi3,
      kisi4: reqs.detay.kisi4,
      kisi5: reqs.detay.kisi5,
      tel1: reqs.detay.tel1,
      tel2: reqs.detay.tel2,
      tel3: reqs.detay.tel3,
      tel4: reqs.detay.tel4,
      tel5: reqs.detay.tel5,
    };

    MainService.yeniLessonTask(values).then((data) => {
      StaffService.deleteLessonReq(reqs.detay.id).then((data) => {
        notification.success({
          message: "Başarılı",
          description: "Talep başarı ile oluşturuldu...",
        });
        router.goBack();
      });
    });
  };
  const onReset = () => {
    form.resetFields();
    setSecilenler([]);
    setSaatler([]);
    setAtamaPaneli(false);
  };
  const deleteReq = (id) => {
    StaffService.deleteLessonReq(id).then((data) => {
      notification.success({
        message: "Başarılı",
        description: "Ders talebi iptal edildi",
      });
      router.goBack();
    });
  };
  return (
    <>
      <Modal
        className="modaldiv"
        visible={modalVisible}
        title={
          <div style={{ width: "100%", wordWrap: "break-word" }}>
            {secilenler.length === 0 ? (
              "Saatleri seçin"
            ) : (
              <Row gutter={[10, 10]}>
                {secilenler.map((sc) => (
                  <Col key={sc.id}>
                    <Button
                      type="primary"
                      ghost
                      onClick={() => secilenClicked(sc)}
                    >
                      {" "}
                      {moment(sc.dateTime)
                        .local()
                        .format("DD MMM, ddd   HH:mm")}{" "}
                      ❌
                    </Button>
                  </Col>
                ))}
              </Row>
            )}
          </div>
        }
        onOk={modalHandleOk}
        onCancel={modalHandleCancel}
        width="95%"
      >
        <List
          grid={{
            gutter: 10,
            xs: 2,
            sm: 3,
            md: 4,
            lg: 5,
            xl: 6,
            xxl: 7,
          }}
          dataSource={saatler}
          renderItem={(item, index) => (
            <List.Item>
              <Button block onClick={() => timeSelected(item, index)}>
                {moment(item.dateTime).local().format("DD MMM, ddd   HH:mm")}
              </Button>
            </List.Item>
          )}
        />
      </Modal>
      {reqs && (
        <Row gutter={[16, 16]}>
          <Col
            xs={24}
            sm={18}
            md={18}
            lg={18}
            xl={18}
            style={{ backgroundColor: "white", border: "1px solid #ddd" }}
          >
            <Descriptions
              size="small"
              title={
                <Title level={3} style={{ fontWeight: 700, color: "#1890FF" }}>
                  {giveLessonType[reqs.detay.netur]} Talebi
                </Title>
              }
              bordered
              column={{ xxl: 2, xl: 2, lg: 1, md: 1, sm: 1, xs: 1 }}
            >
              <Descriptions.Item label="Mevcut Kredi">
                <span style={{ color: "tomato" }}>{reqs.isteyen.bitValue}</span>
              </Descriptions.Item>
              <Descriptions.Item label="Grup Hazır mı?">
                <span style={{ color: "tomato" }}>
                  {reqs.detay.isPersonal
                    ? "Hazır"
                    : reqs.detay.netur > 3
                    ? "Yok"
                    : null}
                </span>
              </Descriptions.Item>

              <Descriptions.Item label="İsteyen">
                {reqs.isteyen.fullname}
              </Descriptions.Item>
              <Descriptions.Item label="Öğretmen">
                {reqs.ogretmen !== null ? reqs.ogretmen.fullname : "Farketmez"}
              </Descriptions.Item>
              <Descriptions.Item label="Konum">
                {getLocation(reqs.isteyen.il, reqs.isteyen.ilce)}
              </Descriptions.Item>
              <Descriptions.Item label="Tarih">
                {moment(reqs.detay.createdOn)
                  .local()
                  .format("DD MMM, ddd   HH:mm")}
              </Descriptions.Item>
              <Descriptions.Item label="Ders">{reqs.ders}</Descriptions.Item>
              <Descriptions.Item label="Konu">
                {reqs.detay.konu}
              </Descriptions.Item>
              {/* <Descriptions.Item label="Ders Yeri">
                {reqs.detay.nerede === "online"
                  ? "Online"
                  : reqs.detay.nerede === "ev"
                  ? "Evde"
                  : "Kurumda"}
              </Descriptions.Item> */}

              <Descriptions.Item label="Saat">
                {reqs.detay.saatSayisi} saat
              </Descriptions.Item>
              <Descriptions.Item label="Ne zaman?">
                <SYSpan color="tomato">
                  {giveLessonReqTime[reqs.detay.nezaman]}
                </SYSpan>
              </Descriptions.Item>
              <Descriptions.Item label="Ders Geçmişi">
                {reqs.detay.rate}/5
              </Descriptions.Item>
              <Descriptions.Item label="Okul">
                {reqs.isteyen.school}
              </Descriptions.Item>
              <Descriptions.Item label="E-mail">
                {reqs.isteyen.email}
              </Descriptions.Item>
              <Descriptions.Item label="Telefon">
                {reqs.isteyen.phone}
              </Descriptions.Item>
              {reqs.detay.isPersonal && (
                <>
                  <Descriptions.Item label="1.Kişi">
                    {reqs.detay.kisi1}
                  </Descriptions.Item>
                  <Descriptions.Item label="2.Kişi">
                    {reqs.detay.kisi2}
                  </Descriptions.Item>
                  <Descriptions.Item label="3.Kişi">
                    {reqs.detay.kisi3}
                  </Descriptions.Item>
                  <Descriptions.Item label="4.Kişi">
                    {reqs.detay.kisi4}
                  </Descriptions.Item>
                  <Descriptions.Item label="5.Kişi">
                    {reqs.detay.kisi5}
                  </Descriptions.Item>
                </>
              )}
            </Descriptions>
          </Col>
          <Col
            xs={24}
            sm={6}
            md={6}
            lg={6}
            xl={6}
            style={{ textAlign: "center" }}
          >
            <Space>
              <Button onClick={dersTanimlaClicked} type="primary">
                <EditOutlined /> Dersi Tanımla
              </Button>
              <Popconfirm
                title="Silmek istediğinize emin misiniz?"
                onConfirm={() => deleteReq(reqs.detay.id)}
                okText="Evet"
                cancelText="İptal"
              >
                <Button type="primary" danger>
                  <DeleteOutlined /> Talebi Sil
                </Button>
              </Popconfirm>
            </Space>
            {teachers.length === 0 && (
              <p style={{ margin: "20px", color: "tomato" }}>
                Bu derse ait atanacak öğretmen olmadığından dolayı ders ataması
                yapamazsınız. Öncelikli olarak bu derse en az 1 öğretmen
                tanımlayınız
              </p>
            )}
            {reqs.ucretler &&
              reqs.isteyen.bitValue <
                reqs.detay.saatSayisi *
                  reqs.ucretler[`ucret${reqs.detay.netur}`] && (
                <p style={{ margin: "20px", color: "tomato" }}>
                  Öğrencinin kredisi ({reqs.isteyen.bitValue}) bu ders için
                  gerekli{" "}
                  {reqs.detay.saatSayisi *
                    reqs.ucretler[`ucret${reqs.detay.netur}`]}{" "}
                  krediyi karşılamıyor
                </p>
              )}
            {atamaPaneli && (
              <div style={{ padding: "15px" }}>
                <Form form={form} onFinish={formFinished} layout="vertical">
                  {teachers.length > 0 && (
                    <>
                      {secilenler.length !== reqs.detay.saatSayisi && (
                        <p style={{ textAlign: "left", color: "tomato" }}>
                          {reqs.detay.saatSayisi +
                            " saat dersi bir öğretmene atayınız:"}
                        </p>
                      )}
                      <Form.Item name="teacherId" label="Öğretmen Seçiniz">
                        <Select
                          placeholder="Talep edilen öğretmeni seçiniz"
                          onSelect={(e, item, val) =>
                            ogretmenChanged(e, item, val)
                          }
                          allowClear
                        >
                          {teachers.map((t) => (
                            <Select.Option
                              key={t.teacher.id}
                              value={t.teacher.id}
                            >
                              {t.teacher.fullname}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                      {secilenler.length > 0 && (
                        <Form.Item label="Seçilenler">
                          <Space direction="vertical">
                            {secilenler.map((secilen) => (
                              <Button type="primary" ghost key={secilen.id}>
                                {moment(secilen.dateTime)
                                  .local()
                                  .format("DD MMM, ddd   HH:mm")}
                              </Button>
                            ))}
                          </Space>
                        </Form.Item>
                      )}
                      <Form.Item>
                        <Button
                          type="primary"
                          block
                          htmlType="submit"
                          disabled={secilenler.length !== reqs.detay.saatSayisi}
                        >
                          Kaydet
                        </Button>
                      </Form.Item>
                      <Form.Item>
                        <Button type="primary" block ghost onClick={onReset}>
                          Sıfırla
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form>
              </div>
            )}
          </Col>
        </Row>
      )}
    </>
  );
}

export default LessonReqDetails;
