import { Button, Input, Space, Form, notification } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { SYBox, SYDivider } from "../../resources/styledComponents";
import ValidationService from "../../services/ValidationService";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function NewPassword() {
  const query = useQuery();
  const router = useHistory();
  const [form] = Form.useForm();
  const [initialRender, setInitialRender] = useState(true);
  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      if (query.get("token") == null) {
        router.push("/login/loginpage");
      }
      ValidationService.checkChangeToken(query.get("token"))
        .then((data) => {})
        .catch((e) => {
          router.push("/login/loginpage");
        });
    }
  }, [initialRender, query, router]);

  const onFinish = (values) => {
    const ctoken = executeRecaptcha("new-password");
    ctoken.then((datam) => {
      values = { ...values, cdata: datam };
      ValidationService.changePassword({
        password: values.password,
        token: query.get("token"),
      }).then((data) => {
        notification.success({
          message: "Başarılı",
          description: "Şifreniz başarı ile değiştirildi",
        });
        router.push("/login/loginpage");
      });
    });
  };
  const onReset = () => {
    form.resetFields();
  };

  return (
    <SYBox
      bg="#f5f5f5"
      width="100%"
      height="100vh"
      className="login-page2"
      alignItems="center"
      display="flex"
    >
      <br />
      <br />
      <SYBox
        style={{ margin: "30px" }}
        p="30px"
        border="1px solid #ddd"
        bg="white"
        maxWidth="800px"
      >
        <SYDivider renk="mavi" orientation="left">
          <Space size="large">
            <img
              src={require("../../assets/images/logo.png")}
              alt="logo"
              width="200px"
            />
            <span>Yeni Şifre Tanımlama</span>
          </Space>
        </SYDivider>
        <Form form={form} onFinish={onFinish} onReset={onReset}>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Şifre girmelisiniz!",
              },
              {
                pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,25}$/,
                message:
                  "Şifreniz 6-25 haneli olmalı , harf ve numara içermelidir",
              },
            ]}
            hasFeedback
          >
            <Input.Password placeholder="Yeni Şifreniz" />
          </Form.Item>

          <Form.Item
            name="confirm"
            dependencies={["password"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Şifreyi doğrulamalısınız!",
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject("Şifreleriniz eşleşmiyor!");
                },
              }),
            ]}
          >
            <Input.Password placeholder="Yeni şifreniz tekrar" />
          </Form.Item>
          <Form.Item>
            <Space>
              <Button block type="default" htmlType="reset">
                Temizle
              </Button>
              <Button block type="primary" htmlType="submit">
                Gönder
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </SYBox>
    </SYBox>
  );
}

export default NewPassword;
