import { Col, Row, Select, Space } from "antd";
import React, { useState, useContext } from "react";
import { getLessonType } from "../../../resources/getLessonType";
import EvdeOfisteOzelDers from "./EvdeOfisteOzelDers";
import OnlineOzelDers from "./OnlineOzelDers";
import OnlineGrupDersi from "./OnlineGrupDersi";
import OfisteGrupDersi from "./OfisteGrupDersi";
import JoinGroupLesson from "./JoinGroupLesson";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "../../../context/GlobalContext";

function GetLesson() {
  const [lessonType, setLessonType] = useState(null);
  const [gruphazir, setGruphazir] = useState(true);
  const { t } = useTranslation();
  const { lessonTypes } = useContext(GlobalContext);
  const handleSelect = (value) => {
    setLessonType(value);
  };
  return (
    <div>
      <div style={{ textAlign: "center" }}>
        <Space>
          <Select
            onSelect={handleSelect}
            placeholder={t("main.dersal.tursec")}
            style={{ minWidth: "250px" }}
          >
            {lessonTypes.map(
              (lt) =>
                lt.key !== 6 && (
                  <Select.Option value={lt.key} key={lt.key}>
                    {lt.value}
                  </Select.Option>
                )
            )}
          </Select>
        </Space>
      </div>
      {lessonType === 1 && (
        <OnlineOzelDers tip={getLessonType.OnlineOzelDers} />
      )}
      {lessonType === 2 && (
        <EvdeOfisteOzelDers tip={getLessonType.OfisteOzelDers} />
      )}
      {lessonType === 3 && (
        <EvdeOfisteOzelDers tip={getLessonType.EvdeOzelDers} />
      )}
      {lessonType === 4 && (
        <OnlineGrupDersi tip={getLessonType.OnlineGrupDersi} grup={gruphazir} />
      )}
      {lessonType === 5 && (
        <OfisteGrupDersi tip={getLessonType.OfisteGrupDersi} />
      )}
      <div className="butsdiv">
        <p>{t("main.dersal.dersAl")}</p>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} md={12} lg={8} xl={8}>
            <div className="but1 online" onClick={() => handleSelect(1)}>
              {t("global.derstipleri.1")}
            </div>
          </Col>

          <Col xs={24} sm={12} md={12} lg={8} xl={8}>
            <div
              className="but1 online"
              onClick={() => {
                handleSelect(4);
                setGruphazir(true);
              }}
            >
              {t("global.derstipleri.4")}
            </div>
          </Col>
        </Row>
        <p>{t("main.dersal.dersTalep")}</p>
        <Row gutter={[16, 16]}>
          {/* <Col xs={24} sm={12} md={12} lg={8} xl={8}>
            <div className="but1 online" onClick={() => handleSelect(1)}>
              Online Özel Ders
            </div>
          </Col> */}
          <Col xs={24} sm={12} md={12} lg={8} xl={8}>
            <div className="but1 ofiste" onClick={() => handleSelect(2)}>
              {t("global.derstipleri.2")}
            </div>
          </Col>
          <Col xs={24} sm={12} md={12} lg={8} xl={8}>
            <div className="but1 evde" onClick={() => handleSelect(3)}>
              {t("global.derstipleri.3")}
            </div>
          </Col>
          <Col xs={24} sm={12} md={12} lg={8} xl={8}>
            <div
              className="but1 online"
              onClick={() => {
                handleSelect(4);
                setGruphazir(false);
              }}
            >
              {t("global.derstipleri.4")}
            </div>
          </Col>
          <Col xs={24} sm={12} md={12} lg={8} xl={8}>
            <div className="but1 ofiste" onClick={() => handleSelect(5)}>
              {t("global.derstipleri.5")}
            </div>
          </Col>
          {/* <Col xs={24} sm={12} md={12} lg={8} xl={8}>
            <div className="but1 online" onClick={() => handleSelect(6)}>
              Online Amfi Dersi
            </div>
          </Col> */}
        </Row>
      </div>
      <p>{t("main.dersal.grupKatil")}</p>

      <JoinGroupLesson tip={getLessonType.OnlineGrupDersi} />
      <JoinGroupLesson tip={getLessonType.OfisteGrupDersi} />
      <JoinGroupLesson tip={getLessonType.OnlineAmfiDersi} />
    </div>
  );
}

export default GetLesson;
