import React from "react";
import HomeLayout from "./pages/home/HomeLayout";
import { Switch, Route, Redirect } from "react-router-dom";
import LoginLayout from "./pages/login/LoginLayout";
import MainLayout from "./pages/main/MainLayout";
import StaffLayout from "./pages/staff/StaffLayout";
import { AdminRoute, PrivateRoute } from "./resources/PrivateRoute";
import AdminLayout from "./pages/admin/AdminLayout";

function App() {
  return (
    <Switch>
      <Route exact path={"/"}>
        <Redirect to={"/home"} />
      </Route>
      <Route path={"/home"} component={HomeLayout} />
      <Route path={"/admin"} component={AdminLayout} />
      <Route path={"/login"} component={LoginLayout} />
      <PrivateRoute path={"/main"} component={MainLayout} />
      <AdminRoute path={"/staff"} component={StaffLayout} />
      <Route>
        <Redirect to={"/home"} />
      </Route>
    </Switch>
  );
}

export default App;
