import React, { useState, useEffect, useContext } from "react";
import {
  Divider,
  Form,
  Input,
  Select,
  Radio,
  message,
  InputNumber,
  notification,
  Space,
  Upload,
  Switch,
  Button,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { StaffService } from "../../../services/StaffService";
import { GlobalContext } from "../../../context/GlobalContext";
import { useTranslation } from "react-i18next";

function TeacherNewExam() {
  const [form] = Form.useForm();
  const [choiceDisabled, setChoiceDisabled] = useState(false);
  const [numberDisabled, setNumberDisabled] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [lessons, setLessons] = useState([]);
  const [initialRender, setInitialRender] = useState(true);
  const [cevaplar, setCevaplar] = useState(null);
  const { setOpenModal } = useContext(GlobalContext);
  const [choiceNumber, setChoiceNumber] = useState(5);
  const { t } = useTranslation();
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      StaffService.getLessons().then((data) => {
        setLessons(data.data);
      });
    }
  }, [initialRender]);
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };
  const questionChanged = (q) => {
    setChoiceDisabled(true);
    q > 0 && setCevaplar(Array.from({ length: q }, (v, k) => "X"));
  };
  const choiceNumberChanged = (c) => {
    setChoiceNumber(c.target.value);
  };

  const answerSelected = (a, index) => {
    !numberDisabled && setNumberDisabled(true);
    let temp = cevaplar;
    temp[index] = a.target.value;
    setCevaplar(temp);
  };
  const onReset = () => {
    form.resetFields();
    setFileList([]);
    setCevaplar(null);
    setChoiceNumber(5);
    setNumberDisabled(false);
    setChoiceDisabled(false);
  };
  function beforeUpload(file) {
    const isPDF = file.type === "application/pdf";
    if (!isPDF) {
      message.error(t("forms.upload.onlyPdf"));
    }
    const isLt1M = file.size / 1024 / 1024 < 2;
    if (!isLt1M) {
      message.error(t("forms.upload.boyut"));
    }
    return isPDF && isLt1M;
  }
  const onFileChange = (info) => {
    setFileList(info.fileList.filter((file) => !!file.status));
  };
  const saveExam = (values) => {
    if (fileList[0]) {
      StaffService.newExam(
        values,
        cevaplar.join(""),
        fileList[0].response.url
      ).then((data) => {
        notification.success({
          message: t("global.basarili"),
          description: t("main.exam.sinavEklendi"),
        });
        setOpenModal(false);
      });
    } else {
      notification.error({
        message: t("global.haya"),
        description: t("forms.upload.onlyPdf"),
      });
    }
  };
  return (
    <div style={{ maxWidth: "800px" }}>
      <Divider>{t("main.exam.yeniSinav")}</Divider>
      <Form
        form={form}
        {...layout}
        onFinish={saveExam}
        initialValues={{ choice: 5, isActive: true }}
      >
        <Form.Item
          name="title"
          label={t("main.exam.baslik")}
          rules={[{ required: true, message: t("forms.messages.gerekli") }]}
        >
          <Input />
        </Form.Item>
        {lessons.length > 0 && (
          <Form.Item
            name="lessonsId"
            label={t("main.exam.ders")}
            rules={[{ required: true, message: t("forms.messages.gerekli") }]}
          >
            <Select
              showSearch
              placeholder={t("forms.placeholders.derssec")}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {lessons.map((lesson) => (
                <Select.Option key={lesson.id} value={lesson.id}>
                  {lesson.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <Form.Item
          name="description"
          label={t("main.exam.konuDetay")}
          rules={[{ required: true, message: t("forms.messages.gerekli") }]}
        >
          <Input />
        </Form.Item>
        <Form.Item label={t("main.exam.sinavPdf")}>
          <div style={{ maxWidth: "300px" }}>
            <Upload
              action={process.env.REACT_APP_API_BASEURL + "upload"}
              headers={{
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              }}
              fileList={fileList}
              beforeUpload={beforeUpload}
              onChange={onFileChange}
            >
              {fileList.length >= 1 ? null : (
                <Button icon={<UploadOutlined />}>
                  {t("forms.upload.pdfSec")}
                </Button>
              )}
            </Upload>
          </div>
        </Form.Item>
        <Form.Item name="choice" label={t("main.exam.secSayisi")}>
          <Radio.Group
            onChange={choiceNumberChanged}
            buttonStyle="solid"
            disabled={choiceDisabled}
          >
            <Radio.Button value={5}>{t("main.exam.5sec")}</Radio.Button>
            <Radio.Button value={4}>{t("main.exam.4sec")}</Radio.Button>
            <Radio.Button value={3}>{t("main.exam.3sec")}</Radio.Button>
            <Radio.Button value={2}>{t("main.exam.dy")}</Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name="questions"
          label={t("main.exam.soruSayisi")}
          rules={[
            {
              type: "number",
              min: 3,
              max: 200,
              message: t("main.exam.soruAralik"),
            },
          ]}
        >
          <InputNumber onChange={questionChanged} disabled={numberDisabled} />
        </Form.Item>
        {cevaplar && cevaplar.length > 2 && (
          <Form.Item {...tailLayout}>
            <div
              className="cadiv"
              style={{ maxWidth: `${(choiceNumber + 2) * 38}px` }}
            >
              <Divider>{t("main.exam.cevaplar")}</Divider>
              {cevaplar.map((cevap, index) => (
                <div className={index % 2 === 0 ? "abg" : "bbg"} key={index}>
                  <span style={{ fontWeight: "bold", fontSize: "16px" }}>
                    {index + 1}.{" "}
                  </span>
                  <Radio.Group
                    defaultValue="X"
                    buttonStyle="solid"
                    size="small"
                    onChange={(a) => answerSelected(a, index)}
                  >
                    <Space>
                      <Radio.Button className="crb" value="A">
                        {choiceNumber === 2 ? "D" : "A"}
                      </Radio.Button>
                      <Radio.Button className="crb" value="B">
                        {choiceNumber === 2 ? "Y" : "B"}
                      </Radio.Button>
                      {choiceNumber > 2 && (
                        <Radio.Button className="crb" value="C">
                          C
                        </Radio.Button>
                      )}
                      {choiceNumber > 3 && (
                        <Radio.Button className="crb" value="D">
                          D
                        </Radio.Button>
                      )}
                      {choiceNumber > 4 && (
                        <Radio.Button className="crb" value="E">
                          E
                        </Radio.Button>
                      )}
                      <Radio.Button className="crb irb" value="X">
                        X
                      </Radio.Button>
                    </Space>
                  </Radio.Group>
                </div>
              ))}
            </div>
          </Form.Item>
        )}
        <Form.Item
          name="isActive"
          label={t("main.exam.aktifmi")}
          valuePropName="checked"
        >
          <Switch></Switch>
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Space>
            <Button type="primary" htmlType="submit">
              {t("global.kaydet")}
            </Button>
            <Button htmlType="button" onClick={onReset}>
              {t("forms.temizle")}
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
}

export default TeacherNewExam;
