import React, { useState, useEffect, useContext } from "react";
import {
  Divider,
  Form,
  Input,
  Select,
  Button,
  InputNumber,
  Upload,
  Modal,
  Space,
  Tooltip,
  Table,
  Switch,
  Tag,
  notification,
} from "antd";
import { StaffService } from "../../services/StaffService";
import { iller, ilceler, getLocation } from "../../resources/il-ilce";
import ReactQuill from "react-quill";
import {
  PlusOutlined,
  InfoCircleTwoTone,
  SearchOutlined,
  MessageOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import {
  giveLessonType,
  lessonTypeOptions,
} from "../../resources/getLessonType";
import { SYSpan } from "../../resources/styledComponents";
import Messages from "../../components/main/Messages/Messages";
import { getMessageType } from "../../resources/getMessageType";
import { UserContext } from "../../context/UserContext";
import { GlobalContext } from "../../context/GlobalContext";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
function Teachers() {
  const [form] = Form.useForm();
  const router = useHistory();
  const [quillValue, setQuillValue] = useState("");
  const [initialRender, setInitialRender] = useState(true);
  const [groups, setGroups] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [lessons, setLessons] = useState([]);
  const [selectedIl, setSelectedIl] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [newPanel, setNewPanel] = useState(false);
  const [previewTitle, setPreviewTitle] = useState("");
  const { openModal, setOpenModal } = useContext(GlobalContext);
  const [toId, setToId] = useState(null);
  const { myId } = useContext(UserContext);
  function compare(a, b) {
    var atitle = a.name;
    var btitle = b.name;
    var alfabe =
      "AaBbCcÇçDdEeFfGgĞğHhIıİiJjKkLlMmNnOoÖöPpQqRrSsŞşTtUuÜüVvWwXxYyZz0123456789";
    if (atitle.length === 0 || btitle.length === 0) {
      return atitle.length - btitle.length;
    }
    for (var i = 0; i < atitle.length && i < btitle.length; i++) {
      var ai = alfabe.indexOf(atitle[i]);
      var bi = alfabe.indexOf(btitle[i]);
      if (ai !== bi) {
        return ai - bi;
      }
    }
  }
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      StaffService.getGropus().then((data) => {
        setGroups(data.data);
      });
      StaffService.getLessons().then((data) => {
        setLessons(data.data);
      });
      StaffService.getTeachers().then((data) => {
        setTeachers(data.data);
      });
    }
  }, [initialRender]);
  const onFinish = (values) => {
    values = {
      ...values,
      details: quillValue,
      photo: fileList[0].response.url,
    };
    StaffService.newTeacher(values).then((data) => {
      setTeachers([...teachers, data.data]);
      setNewPanel(false);
      form.resetFields();
      setFileList([]);
      setQuillValue("");
      notification.success({
        message: "Başarılı",
        description: "Öğretmen başarı ile eklendi.",
      });
    });
  };
  const ilSelected = (il) => {
    form.setFieldsValue({ ilce: null });
    setSelectedIl(il);
  };
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };
  const quillChange = (e) => {
    setQuillValue(e);
  };
  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["clean"],
      [{ align: [] }],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };
  const uploadButton = (
    <div>
      <PlusOutlined style={{ fontSize: "26px" }} />
      <div className="ant-upload-text">Foto Yükle</div>
    </div>
  );

  const handleChangeImage = ({ file, fileList }) => {
    setFileList(fileList);
  };

  const handleCancel = () => setPreviewVisible(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };
  const goDetails = (id) => {
    router.push("/staff/teachers/" + id);
  };
  const columns = [
    {
      title: "Ad Soyad",
      dataIndex: "ogretmen",
      render: (ogretmen) => <span>{ogretmen.fullname}</span>,
    },
    {
      title: "Konum",
      dataIndex: "ogretmen",
      render: (ogretmen) => (
        <span>{getLocation(ogretmen.il, ogretmen.ilce)}</span>
      ),
    },
    {
      title: "Gruplar",
      dataIndex: "gruplar",
      render: (gruplar) => (
        <>
          {gruplar.map((grup) => {
            return (
              <Tag color="blue" key={grup.id}>
                {grup.name.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: "Dersler",
      dataIndex: "dersler",
      render: (dersler) => (
        <>
          {dersler.map((ders) => {
            return (
              <Tag color="magenta" key={ders.id}>
                {ders.name.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: "Email",
      dataIndex: "ogretmen",
      render: (ogretmen) => <span>{ogretmen.email}</span>,
    },
    {
      title: "Aktif mi",
      dataIndex: "ogretmen",
      render: (ogretmen) => (
        <span>
          {ogretmen.isActive ? (
            <SYSpan color="green">Aktif</SYSpan>
          ) : (
            <SYSpan color="red">Pasif</SYSpan>
          )}
        </span>
      ),
    },
    {
      title: "İşlem",
      render: (text, record) => (
        <Space>
          <Button
            onClick={() => goDetails(record.ogretmen.id)}
            type="primary"
            shape="circle"
            icon={<SearchOutlined />}
          />
          <Button
            shape="circle"
            icon={<MessageOutlined />}
            onClick={() => sendMesaj(record.ogretmen.id)}
          />
        </Space>
      ),
    },
  ];

  const newPanelToggle = () => {
    setNewPanel(!newPanel);
  };

  const sendMesaj = (id) => {
    setToId(id);
    setOpenModal(true);
  };
  return (
    <div>
      <Modal
        closable
        title="Mesajınızı yazınız"
        visible={openModal}
        footer={null}
        onCancel={() => setOpenModal(false)}
      >
        <Messages ft={getMessageType.AdminToOgretmen} from={myId} to={toId} />
      </Modal>
      <Divider>Öğretmen Tanımlama ve Listeleme Ekranı</Divider>

      <Button
        type={newPanel ? "danger" : "primary"}
        onClick={newPanelToggle}
        style={{ marginBottom: "15px" }}
      >
        {newPanel ? "Vazgeç" : "Öğretmen Ekle"}
      </Button>
      {!newPanel && (
        <div>
          {teachers && teachers.length > 0 && (
            <Table
              columns={columns}
              dataSource={teachers}
              bordered
              size="small"
            />
          )}
        </div>
      )}
      {newPanel && (
        <div style={{ maxWidth: "800px" }}>
          <Divider>Yeni Öğretmen Formu</Divider>
          <Form {...layout} form={form} onFinish={onFinish}>
            <Form.Item
              name="fullname"
              label="Ad Soyad"
              rules={[{ required: true, message: "Öğretmen adı gereklidir" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="email"
              label="Email adresi"
              rules={[
                { required: true, message: "Email adresi gereklidir" },
                { type: "email", message: "Geçerli email adresi giriniz." },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="phone"
              label={
                <Space>
                  <span>Telefon</span>
                  <Tooltip title="Öğrenciler ile paylaşılmayacaktır">
                    <InfoCircleTwoTone />
                  </Tooltip>
                </Space>
              }
              rules={[{ required: true, message: "Telefon gereklidir" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="password"
              label={
                <Space>
                  <span>Şifre</span>
                  <Tooltip title="Kullanıcı daha sonra kendi şifresini belirleyebilir">
                    <InfoCircleTwoTone />
                  </Tooltip>
                </Space>
              }
              rules={[
                { required: true, message: "Şifre gereklidir" },
                {
                  min: 6,
                  message: "Şifre en az 6 haneli olmalı",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="il"
              label="İl"
              rules={[{ required: true, message: "İl seçmelisiniz" }]}
            >
              <Select
                showSearch
                onChange={ilSelected}
                placeholder="İl seçiniz"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {iller.sort(compare).map((il) => (
                  <Select.Option value={il.id} key={il.id}>
                    {il.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            {selectedIl && (
              <Form.Item
                name="ilce"
                label="İlce"
                rules={[{ required: true, message: "İlçe seçmelisiniz" }]}
              >
                <Select
                  showSearch
                  placeholder="İlçe seçiniz"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {ilceler
                    .filter((i) => i.il_id === selectedIl)
                    .sort(compare)
                    .map((ilce) => (
                      <Select.Option value={ilce.id} key={ilce.id}>
                        {ilce.name}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            )}
            <Form.Item
              name="groups"
              label="Ders Grupları"
              rules={[
                {
                  required: true,
                  message: "Grup seçmelisiniz!",
                  type: "array",
                },
              ]}
            >
              <Select mode="multiple" placeholder="Ders gruplarını seçiniz">
                {groups.sort(compare).map((grup) => (
                  <Select.Option key={grup.id} value={grup.id}>
                    {grup.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="lessons"
              label="Dersler"
              rules={[
                {
                  required: true,
                  message: "Ders seçmelisiniz!",
                  type: "array",
                },
              ]}
            >
              <Select
                mode="multiple"
                placeholder="Öğretmenin derslerini seçiniz"
              >
                {lessons.sort(compare).map((ders) => (
                  <Select.Option key={ders.id} value={ders.id}>
                    {ders.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            {lessonTypeOptions.map((lt) => (
              <Form.Item
                name={"ucret" + lt.key}
                label={lt.value + " ücreti (Bit)"}
                rules={[
                  {
                    required: true,
                    message: "Bu bilgi gereklidir",
                  },
                  {
                    type: "number",
                    min: 5,
                    max: 500,
                    message: "Ücret 5 Bit - 500 Bit aralığında olabilir ",
                  },
                ]}
              >
                <InputNumber />
              </Form.Item>
            ))}
            {/* <Form.Item
              name="evdeders"
              label="Evde özel ders ücreti (Bit)"
              rules={[
                {
                  required: true,
                  message: "Bu bilgi gereklidir",
                },
                {
                  type: "number",
                  min: 5,
                  max: 500,
                  message: "Ücret 5 Bit - 500 Bit aralığında olabilir ",
                },
              ]}
            >
              <InputNumber />
            </Form.Item>
            <Form.Item
              name="ofisteders"
              label="Ofiste özel ders ücreti (Bit)"
              rules={[
                {
                  required: true,
                  message: "Bu bilgi gereklidir",
                },
                {
                  type: "number",
                  min: 5,
                  max: 500,
                  message: "Ücret 5 Bit - 500 Bit aralığında olabilir ",
                },
              ]}
            >
              <InputNumber />
            </Form.Item>
            <Form.Item
              name="onlineders"
              label="Online özel ders ücreti (Bit)"
              rules={[
                {
                  required: true,
                  message: "Bu bilgi gereklidir",
                },
                {
                  type: "number",
                  min: 5,
                  max: 500,
                  message: "Ücret 5 Bit - 500 Bit aralığında olabilir ",
                },
              ]}
            >
              <InputNumber />
            </Form.Item>
            <Form.Item
              name="onlinegrup"
              label="Online grup dersi ücreti (Bit)"
              rules={[
                {
                  required: true,
                  message: "Bu bilgi gereklidir",
                },
                {
                  type: "number",
                  min: 5,
                  max: 500,
                  message: "Ücret 5 Bit - 500 Bit aralığında olabilir ",
                },
              ]}
            >
              <InputNumber />
            </Form.Item>
            <Form.Item
              name="onlineamfi"
              label="Online amfi dersi ücreti (Bit)"
              rules={[
                {
                  required: true,
                  message: "Bu bilgi gereklidir",
                },
                {
                  type: "number",
                  min: 5,
                  max: 500,
                  message: "Ücret 5 Bit - 500 Bit aralığında olabilir ",
                },
              ]}
            >
              <InputNumber />
            </Form.Item> */}
            <Form.Item label="Öğretmen Detayı">
              <ReactQuill
                modules={modules}
                theme="snow"
                onChange={(e) => quillChange(e)}
              />
            </Form.Item>
            <Form.Item label="Fotoğraf">
              <div className="clearfix">
                <Upload
                  action={process.env.REACT_APP_API_BASEURL + "upload"}
                  headers={{
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                  }}
                  listType="picture-card"
                  fileList={fileList}
                  onChange={handleChangeImage}
                  onPreview={handlePreview}
                >
                  {fileList.length >= 1 ? null : uploadButton}
                </Upload>
                <Modal
                  visible={previewVisible}
                  title={previewTitle}
                  footer={null}
                  onCancel={handleCancel}
                >
                  <img
                    alt="example"
                    style={{ width: "100%" }}
                    src={previewImage}
                  />
                </Modal>
              </div>
              <small>Fotoğraf ölçüleri 1:1 oranında olmalıdır</small>
            </Form.Item>
            <Form.Item
              name="tip1"
              label={giveLessonType[1] + " 'e açık"}
              initialValue={true}
            >
              <Switch defaultChecked={true}></Switch>
            </Form.Item>
            <Form.Item
              name="tip2"
              label={giveLessonType[2] + " 'e açık"}
              initialValue={true}
            >
              <Switch defaultChecked={true}></Switch>
            </Form.Item>
            <Form.Item
              name="tip3"
              label={giveLessonType[3] + " 'e açık"}
              initialValue={true}
            >
              <Switch defaultChecked={true}></Switch>
            </Form.Item>
            <Form.Item
              name="tip4"
              label={giveLessonType[4] + " 'e açık"}
              initialValue={true}
            >
              <Switch defaultChecked={true}></Switch>
            </Form.Item>
            <Form.Item
              name="tip5"
              label={giveLessonType[5] + " 'e açık"}
              initialValue={true}
            >
              <Switch defaultChecked={true}></Switch>
            </Form.Item>
            <Form.Item
              name="tip6"
              label={giveLessonType[6] + " 'e açık"}
              initialValue={true}
            >
              <Switch defaultChecked={true}></Switch>
            </Form.Item>
            <Form.Item name="isActive" label="Aktif mi ?" initialValue={true}>
              <Switch defaultChecked={true}></Switch>
            </Form.Item>
            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit">
                Kaydet
              </Button>
            </Form.Item>
          </Form>
        </div>
      )}
    </div>
  );
}

export default Teachers;
