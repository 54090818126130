import { Row, Statistic, Col } from "antd";
import React, { useState, useEffect } from "react";
import StarGraph from "../charts/StarGraph";
import { StaffService } from "../../../services/StaffService";

function DashGlobalReport() {
  const [stats, setStats] = useState({
    global: [],
    teachers: [],
    students: [],
    dayBitTotals: [],
    lessonTaskDetails: [],
    messagesDetails: [],
    scheduleDetails: [],
  });
  const [initialRender, setInitialRender] = useState(true);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      StaffService.getStarReport().then((data) => {
        setStats(data.data);
        setLoading(false);
        console.log(data.data);
      });
    }
  }, [initialRender]);

  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col xs={12} sm={8} md={6} lg={4} xl={4} xxl={3}>
          <Statistic
            loading={loading}
            title="Öğretmen Sayısı"
            value={stats.teCount}
            className="stats-bar"
          />
        </Col>
        <Col xs={12} sm={8} md={6} lg={4} xl={4} xxl={3}>
          <Statistic
            loading={loading}
            title="Öğrenci Sayısı"
            value={stats.stCount}
            className="stats-bar"
          />
        </Col>
        <Col xs={12} sm={8} md={6} lg={4} xl={4} xxl={3}>
          <Statistic
            loading={loading}
            title="Tanımlı Ders Sayısı"
            value={stats.lessonTaskCount}
            className="stats-bar"
          />
        </Col>
        <Col xs={12} sm={8} md={6} lg={4} xl={4} xxl={3}>
          <Statistic
            loading={loading}
            title="Bekleyen Talep Sayısı"
            value={stats.lessonReqCount}
            className="stats-bar"
          />
        </Col>

        <Col xs={12} sm={8} md={6} lg={4} xl={4} xxl={3}>
          <Statistic
            loading={loading}
            title="Raporlanan Ders Sayısı"
            value={stats.lessonRprCount}
            className="stats-bar"
          />
        </Col>
        <Col xs={12} sm={8} md={6} lg={4} xl={4} xxl={3}>
          <Statistic
            loading={loading}
            title="Randevu Sayısı"
            value={stats.apposCount}
            className="stats-bar"
          />
        </Col>
        <Col xs={12} sm={8} md={6} lg={4} xl={4} xxl={3}>
          <Statistic
            loading={loading}
            title="Sınav Sayısı"
            value={stats.examCount}
            className="stats-bar"
          />
        </Col>
        <Col xs={12} sm={8} md={6} lg={4} xl={4} xxl={3}>
          <Statistic
            loading={loading}
            title="Sınav Katılımı"
            value={stats.stExamsOkCount + "/" + stats.stExamsCount}
            className="stats-bar"
          />
        </Col>
        <Col xs={12} sm={8} md={6} lg={4} xl={4} xxl={3}>
          <Statistic
            loading={loading}
            title="Soru Çözüm Raporu"
            value={stats.questionAnswer + "/" + stats.questionTotal}
            className="stats-bar"
          />
        </Col>
        <Col xs={12} sm={8} md={6} lg={4} xl={4} xxl={3}>
          <Statistic
            loading={loading}
            title="Bit Paketi"
            value={stats.bitCount}
            className="stats-bar"
          />
        </Col>
        <Col xs={12} sm={8} md={6} lg={4} xl={4} xxl={3}>
          <Statistic
            loading={loading}
            title="Kullanılan Kart"
            value={stats.cardCount}
            className="stats-bar"
          />
        </Col>
        <Col xs={12} sm={8} md={6} lg={4} xl={4} xxl={3}>
          <Statistic
            loading={loading}
            title="Kartla Yüklenen Bit"
            value={stats.cardTotal}
            className="stats-bar"
          />
        </Col>
        <Col xs={12} sm={8} md={6} lg={4} xl={4} xxl={3}>
          <Statistic
            loading={loading}
            title="Açık Randevu Sayısı"
            value={stats.scheduleCount}
            className="stats-bar"
          />
        </Col>
        <Col xs={12} sm={8} md={6} lg={4} xl={4} xxl={3}>
          <Statistic
            loading={loading}
            title="İletişim Cevap"
            value={stats.contactResCount + "/" + stats.contactCount}
            className="stats-bar"
          />
        </Col>
        <Col xs={12} sm={8} md={6} lg={4} xl={4} xxl={3}>
          <Statistic
            loading={loading}
            title="Açık Genel Uyarı"
            value={stats.mainAlertsCount}
            className="stats-bar"
          />
        </Col>
        <Col xs={12} sm={8} md={6} lg={4} xl={4} xxl={3}>
          <Statistic
            loading={loading}
            title="Mesajlaşma Sayısı"
            value={stats.messagesCount}
            className="stats-bar"
          />
        </Col>

        <Col xs={12} sm={8} md={6} lg={4} xl={4} xxl={3}>
          <Statistic
            loading={loading}
            title="Ders Mesajı Sayısı"
            value={stats.lessonMsgCount}
            className="stats-bar"
          />
        </Col>

        <Col xs={12} sm={8} md={6} lg={4} xl={4} xxl={3}>
          <Statistic
            loading={loading}
            title="Zoom Tanımı"
            value={stats.zoomCount}
            className="stats-bar"
          />
        </Col>
      </Row>
      <StarGraph stats={stats} />
    </div>
  );
}

export default DashGlobalReport;
