import React, { useState, useEffect, useContext } from "react";
import {
  Form,
  Input,
  Grid,
  Select,
  InputNumber,
  Rate,
  Space,
  Button,
  Divider,
  Row,
  Col,
} from "antd";
import { StaffService } from "../../services/StaffService";
import { SYP } from "../../resources/styledComponents";
import { SYColors } from "../../resources/SYColors";
import { Link, useHistory } from "react-router-dom";
import { GlobalContext } from "../../context/GlobalContext";
import { Trans, useTranslation } from "react-i18next";

function HomeLessonReqForm() {
  const [form] = Form.useForm();
  const { useBreakpoint } = Grid;
  const [initialRender, setInitialRender] = useState(true);
  const [lessons, setLessons] = useState([]);
  const { kurum, lessonReqTimes, lessonTypes, desc } = useContext(
    GlobalContext
  );
  const { t } = useTranslation();

  const router = useHistory();

  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      StaffService.getLessons().then((data) => {
        console.log("lessons:", data.data);
        setLessons(data.data.filter((l) => l.isActive === true));
      });
    }
  }, [initialRender]);
  const layout = useBreakpoint().md
    ? {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
      }
    : null;
  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };
  const onFinish = (values) => {
    router.push("/login/signuppage", { veri: values });
  };
  const onReset = () => {
    form.resetFields();
  };

  return (
    <div className="cont-cont">
      <div className="container">
        <Divider style={{ color: "#1890FF" }}>
          <Trans i18nKey="home.talep.derstalepformu">Ders Talep Formu</Trans>
        </Divider>

        <Form
          initialValues={{ rate: 3 }}
          style={{ marginBottom: "50px", width: "100%" }}
          form={form}
          {...layout}
          onFinish={onFinish}
          onReset={onReset}
          layout={useBreakpoint().md ? "horizontal" : "vertical"}
        >
          <Row style={{ width: "100%" }} align="middle" gutter={[20, 20]}>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={12}
              xl={12}
              xxl={12}
              style={{
                textAlign: "right",
                paddingRight: "20px",
                boxShadow: "15px 0px 10px -13px #ccc",
              }}
            >
              <h3>
                <Trans i18nKey="home.talep.metin">
                  Siteye <Link to="/login/signuppage">üye olarak </Link>daha
                  detaylı ders talep formlarına ulaşabilir, sizler için
                  hazırlanmış grup derslerine katılabilirsiniz. Talep formunu
                  doldurduğunuzda üyelik formu doldurmanız istenecek ve
                  talebiniz sisteme iletilecektir.
                  <br /> Talebinizle ilgili en kısa sürede size dönüş
                  yapılacaktır. <br /> Teşekkür Ederiz.
                </Trans>
              </h3>
              <SYP color={SYColors.blue}>{kurum && kurum.name}</SYP>
              {useBreakpoint().lg && (
                <img
                  src={require("../../assets/images/contact-bg-2.png")}
                  alt="banner"
                  width="100%"
                  style={{
                    marginRight: "-20px",
                    marginTop: "-50px",
                  }}
                />
              )}
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
              <Form.Item
                label={t("forms.dersturu")}
                name="netur"
                rules={[
                  { required: true, message: t("forms.messages.gerekli") },
                ]}
              >
                <Select placeholder={t("forms.placeholders.talepturusec")}>
                  {lessonTypes.map((lt) => (
                    <Select.Option value={lt.key} key={lt.key}>
                      {lt.value}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="lessonsId"
                label={t("forms.dersseciniz")}
                rules={[
                  { required: true, message: t("forms.messages.gerekli") },
                ]}
              >
                <Select
                  placeholder={t("forms.placeholders.derssec")}
                  allowClear
                >
                  {lessons.map((lesson) => (
                    <Select.Option key={lesson.id} value={lesson.id}>
                      {lesson.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label={t("forms.konu")}
                name="konu"
                rules={[
                  { required: true, message: t("forms.messages.gerekli") },
                ]}
              >
                <Input placeholder={t("forms.placeholders.konugir")} />
              </Form.Item>
              <Form.Item
                label={t("forms.derssayisi")}
                name="saatSayisi"
                rules={[
                  { required: true, message: t("forms.messages.gerekli") },
                  {
                    type: "number",
                    min: 1,
                    max: 100,
                    message: t("forms.messages.saataralik"),
                  },
                ]}
              >
                <InputNumber />
              </Form.Item>
              <Form.Item name="rate" label={t("forms.derstemelbilgisi")}>
                <Rate tooltips={desc} />
              </Form.Item>

              <Form.Item
                name="nezaman"
                label={t("forms.zaman")}
                rules={[
                  { required: true, message: t("forms.messages.gerekli") },
                ]}
              >
                <Select placeholder={t("forms.placeholders.zaman")} allowClear>
                  {lessonReqTimes.map((lt) => (
                    <Select.Option key={lt.key} value={lt.key}>
                      {lt.value}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item {...tailLayout}>
                <Space>
                  <Button type="primary" ghost htmlType="reset">
                    {t("forms.temizle")}
                  </Button>
                  <Button type="primary" htmlType="submit">
                    {t("forms.talebimigonder")}
                  </Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
}

export default HomeLessonReqForm;
