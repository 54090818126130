import { axios } from "../resources/httpClient";

export const AdminService = {
  getCompanies: () => {
    return axios.get("/company");
  },
  newCompany: (values) => {
    return axios.post("/company", { ...values });
  },
  newAboutUs: (values) => {
    return axios.post("/aboutus", { ...values });
  },
};
