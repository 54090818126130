import { Tabs } from "antd";
import React, { useContext } from "react";
import FromAdminMessages from "./FromAdminMessages";
import FromStudentMessages from "./FromStudentMessages";
import FromTeacherMessages from "./FromTeacherMessages";
import { UserContext } from "../../../context/UserContext";
import SentMessages from "./SentMessages";
import { useTranslation } from "react-i18next";

function MyMessages() {
  const { myRole } = useContext(UserContext);
  const { t } = useTranslation();

  return (
    <div>
      <Tabs defaultActiveKey={myRole === "Student" ? "2" : "1"}>
        {myRole !== "Student" && (
          <Tabs.TabPane tab={t("main.mesaj.ogrencilerden")} key="1">
            <FromStudentMessages />
          </Tabs.TabPane>
        )}
        <Tabs.TabPane tab={t("main.mesaj.ogretmenlerden")} key="2">
          <FromTeacherMessages />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t("main.mesaj.sistemden")} key="3">
          <FromAdminMessages />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t("main.mesaj.gonderdiklerim")} key="4">
          <SentMessages />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
}

export default MyMessages;
