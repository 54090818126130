import React, { createContext, useState } from "react";
import { getInitialUserData } from "../resources/getUserData";

export const UserContext = createContext();
export const UserContextProvider = ({ children }) => {
  const [userValues, setUserValues] = useState(
    getInitialUserData.getFullData()
  );

  return (
    <UserContext.Provider
      value={{
        myId:
          parseInt(userValues.myId) > 0 ? parseInt(userValues.myId) : undefined,
        myEmail: userValues.myEmail,
        myRole: userValues.myRole,
        myFullname: userValues.myFullname,
        setUserValues,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
