import {
  Card,
  List,
  Row,
  Col,
  Space,
  Button,
  Rate,
  Divider,
  Form,
  Input,
  message,
  Upload,
  notification,
  Popconfirm,
  Tooltip,
  Popover,
  Descriptions,
} from "antd";
import Avatar from "antd/lib/avatar/avatar";
import React, { useEffect, useState, useContext } from "react";
import { MainService } from "../../../services/MainService";
import LessonMessages from "./LessonMessages";
import {
  DeleteOutlined,
  EyeInvisibleOutlined,
  FieldTimeOutlined,
  FilePdfTwoTone,
  HourglassOutlined,
  MessageTwoTone,
  UploadOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import { GlobalContext } from "../../../context/GlobalContext";
import Modal from "antd/lib/modal/Modal";
import { UserContext } from "../../../context/UserContext";
import Countdown from "antd/lib/statistic/Countdown";
import moment from "moment";
import LessonReportForm from "./LessonReportForm";
import { useTranslation } from "react-i18next";

function MyLessons() {
  const [initialRender, setInitialRender] = useState(true);
  const [derslerim, setDerslerim] = useState([]);
  const [activeTaskId, setActiveTaskId] = useState(null);
  const { openModal, setOpenModal, lessonTypes } = useContext(GlobalContext);
  const [form] = Form.useForm();
  const { myRole, myFullname, myId } = useContext(UserContext);
  const [fileList, setFileList] = useState([]);
  const [reportModal, setReportModal] = useState(false);
  const [activeUcret, setActiveUcret] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    if (initialRender) {
      setOpenModal(false);
      setInitialRender(false);
      MainService.getMyLessonTasks(myId, myRole === "Student" ? 0 : 1).then(
        (data) => {
          setDerslerim(data.data);
        }
      );
    }
  }, [initialRender, myId, myRole, setOpenModal]);

  const getMessageModal = (taskId) => {
    setActiveTaskId(taskId);
    setOpenModal(true);
  };
  const getReportModal = (taskId, ucret) => {
    setActiveUcret(ucret);
    setActiveTaskId(taskId);
    setReportModal(true);
  };
  const saveMessage = (values) => {
    values = {
      ...values,
      lessonTaskId: activeTaskId,
      fromRole: myRole,
      fromName: myFullname,
    };
    MainService.newLessonMessage(values).then((data) => {
      form.resetFields();
      setOpenModal(false);
      setInitialRender(true);
    });
  };
  function beforeUpload(file) {
    const isPDF = file.type === "application/pdf";
    if (!isPDF) {
      message.error(t("forms.upload.onlyPdf"));
    }
    const isLt1M = file.size / 1024 / 1024 < 2;
    if (!isLt1M) {
      message.error(t("forms.upload.boyut"));
    }
    return isPDF && isLt1M;
  }
  const onFileChange = (info, id) => {
    setFileList(info.fileList.filter((file) => !!file.status));
    if (info.fileList[0].response && info.fileList[0].response.success) {
      MainService.addPdfToTask(id, info.fileList[0].response.url).then(
        (data) => {
          setInitialRender(true);
        }
      );
      notification.success({
        message: t("global.basarili"),
        description: t("forms.upload.eklendi"),
      });
      setFileList([]);
    }
  };
  const deletePdf = (id) => {
    MainService.deleteLessonTaskPdf(id).then((data) => {
      notification.success({
        message: t("global.basarili"),
        description: t("main.dersal.pdfSilindi"),
      });
      setInitialRender(true);
    });
  };

  const zoomPop = (appom) => (
    <>
      <Descriptions
        column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
        bordered
        size="small"
      >
        <Descriptions.Item label="zoom id">{appom.zoom_id}</Descriptions.Item>
        <Descriptions.Item label="zoom password">
          {appom.zoom_password}
        </Descriptions.Item>
      </Descriptions>
      <Button
        style={{ marginTop: "5px" }}
        type="primary"
        block
        onClick={() =>
          window.open(
            myRole === "Student" ? appom.join_url : appom.start_url,
            "_blank"
          )
        }
      >
        <VideoCameraOutlined /> {t("main.dersal.zoomaBaglan")}
      </Button>
    </>
  );

  return (
    <div>
      <Divider style={{ color: "#1890FF" }}>
        {t("main.dersal.onaylananDerslerim")}
      </Divider>
      <Modal
        closable
        title={t("main.dersal.degerlendirmeFormu")}
        visible={reportModal}
        footer={null}
        onCancel={() => setReportModal(false)}
      >
        <LessonReportForm
          sir={setInitialRender}
          closeModal={setReportModal}
          taskId={activeTaskId}
          bit={activeUcret}
        />
      </Modal>
      <Modal
        closable
        title={t("main.dersal.mesajYaz")}
        visible={openModal}
        footer={null}
        onCancel={() => setOpenModal(false)}
      >
        <Form form={form} onFinish={saveMessage}>
          <Form.Item
            label={t("main.dersal.mesajiniz")}
            name="message"
            rules={[
              {
                required: true,
                message: t("main.dersal.mesajYaz"),
              },
            ]}
          >
            <Input.TextArea
              autoFocus
              showCount
              style={{ width: "100%" }}
              placeholder={t("main.dersal.mesajYaz")}
              bordered
              autoSize={{ minRows: 2, maxRows: 6 }}
            />
          </Form.Item>
          <Row justify="end" gutter={10}>
            <Col>
              <Form.Item>
                <Button
                  onClick={() => {
                    form.resetFields();
                  }}
                >
                  {t("forms.temizle")}
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  {t("forms.gonder")}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      <List
        grid={{
          gutter: 16,
          xs: 1,
          sm: 1,
          md: 1,
          lg: 2,
          xl: 2,
          xxl: 3,
        }}
        dataSource={derslerim}
        renderItem={(item) => (
          <List.Item key={item.task.id}>
            <Card
              headStyle={{
                color: "white",
                backgroundColor: [1, 4, 6].includes(item.task.tip)
                  ? "#4285F6"
                  : item.task.tip === 3
                  ? "#ED4235"
                  : "#34A856",
              }}
              style={{ borderColor: "#ddd" }}
              size="small"
              type="inner"
              extra={
                <Tooltip
                  title={
                    item.appos.length === 0
                      ? t("main.dersal.listedenKaldir")
                      : t("main.dersal.dersBekleniyor")
                  }
                >
                  <Button
                    onClick={() =>
                      item.appos.length === 0 &&
                      getReportModal(item.task.id, item.task.ucret)
                    }
                    danger={item.appos.length === 0}
                    size="small"
                    icon={
                      item.appos.length === 0 ? (
                        <EyeInvisibleOutlined />
                      ) : (
                        <HourglassOutlined />
                      )
                    }
                  />
                </Tooltip>
              }
              actions={[
                // myRole === "Student" && item.task.pdfUrl !== null && (
                //   <img
                //     onClick={() =>
                //       window.open(
                //         process.env.REACT_APP_FILES_BASEURL + item.task.pdfUrl,
                //         "_blank"
                //       )
                //     }
                //     style={{ marginTop: "5px" }}
                //     width={110}
                //     src={require("../../../assets/images/pdf-indir.png")}
                //     alt="pdf"
                //   />
                // ),
                myRole === "Teacher" && item.task.pdfUrl === null ? (
                  <Upload
                    showUploadList={false}
                    action={process.env.REACT_APP_API_BASEURL + "upload"}
                    headers={{
                      Authorization: `Bearer ${localStorage.getItem("token")}`,
                    }}
                    fileList={fileList}
                    beforeUpload={beforeUpload}
                    onChange={(a) => onFileChange(a, item.task.id)}
                  >
                    {fileList.length >= 1 ? null : (
                      <Button icon={<UploadOutlined />}>
                        {t("forms.upload.pdfSec")}
                      </Button>
                    )}
                  </Upload>
                ) : (
                  item.task.pdfUrl !== null && (
                    <Space>
                      <Button
                        onClick={() =>
                          window.open(
                            process.env.REACT_APP_FILES_BASEURL +
                              item.task.pdfUrl,
                            "_blank"
                          )
                        }
                      >
                        <FilePdfTwoTone twoToneColor="tomato" />{" "}
                        {t("main.dersal.pdfGoster")}
                      </Button>
                      {myRole === "Teacher" && (
                        <Popconfirm
                          title={t("global.silEminmisin")}
                          onConfirm={() => deletePdf(item.task.id)}
                          okText={t("global.evet")}
                          cancelText={t("global.iptal")}
                        >
                          <DeleteOutlined style={{ color: "red" }} />
                        </Popconfirm>
                      )}
                    </Space>
                  )
                ),
                // <>
                //   {item.task.zoomLink !== null && (
                //     <img
                //       width={100}
                //       src={require("../../../assets/images/zoom-logo.png")}
                //       alt="zoom"
                //     />
                //   )}
                // </>,
              ]}
              title={
                <Row justify="space-between">
                  <Col>
                    <span>
                      {lessonTypes[item.task.tip - 1].value.toUpperCase()}
                    </span>
                  </Col>
                  <Col>
                    <span style={{ marginRight: "10px" }}>
                      {t("main.dersal.saatSayisi", {
                        saat: item.task.saatSayisi,
                      })}
                    </span>
                  </Col>
                </Row>
              }
            >
              <Space>
                <Avatar
                  size={64}
                  src={process.env.REACT_APP_FILES_BASEURL + item.photo}
                  style={{ border: "1px solid #eee" }}
                />
                <Space direction="vertical">
                  <span>{item.teacher.toUpperCase()}</span>
                  <span>{item.task.lessons.name + " / " + item.task.konu}</span>
                </Space>
              </Space>
              <div style={{ width: "100%", margin: "15px 0 0 0" }}>
                {item.timelog.map((appo) => (
                  <div key={appo.id}>
                    <Space align="baseline" size="small">
                      <Button
                        key={appo.id}
                        size="small"
                        type="primary"
                        ghost
                        style={{ margin: "0 10px 10px 0" }}
                      >
                        {moment(appo.dateTime)
                          .local()
                          .format("DD MMM, ddd   HH:mm")}
                      </Button>
                      {[1, 4, 6].includes(item.task.tip) && (
                        <Popover
                          content={() => zoomPop(appo)}
                          title={t("main.dersal.zoomDetay")}
                          trigger="click"
                        >
                          <Button type="default" size="small">
                            <img
                              src={require("../../../assets/images/zoom.png")}
                              alt="zoom"
                              height="12px"
                            />
                          </Button>
                        </Popover>
                      )}
                      <Countdown
                        prefix={<FieldTimeOutlined />}
                        valueStyle={{ fontSize: "15px" }}
                        value={moment(appo.dateTime)}
                      />
                    </Space>
                    <br />
                  </div>
                ))}
              </div>
              <Space>
                <span>{t("forms.seviye")} : </span>
                <Rate value={item.task.rate} />
              </Space>
              <Divider
                className="mdivider"
                plain
                orientation="left"
                style={{ color: "#4285F6" }}
              >
                {t("main.dersal.katilimcilar")}
              </Divider>

              <ol>
                {item.students.map(
                  (student, ind) =>
                    ind < 5 && <li key={student.id}>{student.fullname}</li>
                )}
                {!item.task.isPersonal && item.students.length > 5 && (
                  <span>...</span>
                )}
                {!item.task.isPersonal && <p>({item.students.length} kişi)</p>}
                {item.task.isPersonal && (
                  <>
                    <li>{item.task.kisi2}</li>
                    <li>{item.task.kisi3}</li>
                    <li>{item.task.kisi4}</li>
                    <li>{item.task.kisi5}</li>
                  </>
                )}
              </ol>

              <div>
                <Divider
                  className="mdivider"
                  style={{ color: "#4285F6" }}
                  orientation="left"
                  plain
                >
                  <Space size="large">
                    <span>
                      {t("main.dersal.mesajlar") +
                        "(" +
                        item.messages.length +
                        ")"}
                    </span>
                    <span
                      onClick={() => getMessageModal(item.task.id)}
                      style={{ cursor: "pointer" }}
                    >
                      <MessageTwoTone twoToneColor="#1890FF" />
                      <span> {t("global.ekle")}</span>
                    </span>
                  </Space>
                </Divider>

                <LessonMessages
                  messages={item.messages}
                  sir={setInitialRender}
                />
              </div>
            </Card>
          </List.Item>
        )}
      />
    </div>
  );
}

export default MyLessons;
