import {
  Card,
  List,
  Row,
  Col,
  Space,
  Button,
  Rate,
  Divider,
  Alert,
  Tag,
} from "antd";
import Avatar from "antd/lib/avatar/avatar";
import React, { useEffect, useState, useContext } from "react";
//import { giveLessonType } from "../../../resources/getLessonType";
import { MainService } from "../../../services/MainService";
import LessonMessages from "./LessonMessages";
import { FilePdfTwoTone } from "@ant-design/icons";
import { UserContext } from "../../../context/UserContext";

import moment from "moment";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "../../../context/GlobalContext";

function MyLessonsHistory() {
  const [initialRender, setInitialRender] = useState(true);
  const [derslerim, setDerslerim] = useState([]);
  const { myRole, myId } = useContext(UserContext);
  const { t } = useTranslation();
  const { lessonTypes } = useContext(GlobalContext);

  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      MainService.getMyLessonTasksHistory(
        myId,
        myRole === "Student" ? 0 : 1
      ).then((data) => {
        setDerslerim(data.data);
      });
    }
  }, [initialRender, myId, myRole]);

  return (
    <div>
      <Divider style={{ color: "#1890FF" }}>
        {t("main.dersal.gecmisDersler")}
      </Divider>

      <List
        grid={{
          gutter: 16,
          xs: 1,
          sm: 1,
          md: 1,
          lg: 2,
          xl: 2,
          xxl: 3,
        }}
        dataSource={derslerim}
        renderItem={(item) => (
          <List.Item key={item.task.id}>
            <Card
              headStyle={{
                color: "white",
                backgroundColor: [1, 4, 6].includes(item.task.tip)
                  ? "#4285F6"
                  : item.task.tip === 3
                  ? "#ED4235"
                  : "#34A856",
              }}
              style={{ borderColor: "#ddd" }}
              size="small"
              type="inner"
              actions={[
                item.task.pdfUrl !== null && (
                  <Space>
                    <Button
                      onClick={() =>
                        window.open(
                          process.env.REACT_APP_FILES_BASEURL +
                            item.task.pdfUrl,
                          "_blank"
                        )
                      }
                    >
                      <FilePdfTwoTone twoToneColor="tomato" />{" "}
                      {t("main.dersal.pdfGoster")}
                    </Button>
                  </Space>
                ),
              ]}
              title={
                <Row justify="space-between">
                  <Col>
                    <span>
                      {lessonTypes[item.task.tip - 1].value.toUpperCase()}
                    </span>
                  </Col>
                  <Col>
                    <span style={{ marginRight: "10px" }}>
                      {t("main.dersal.saatSayisi", {
                        saat: item.task.saatSayisi,
                      })}
                    </span>
                  </Col>
                </Row>
              }
            >
              <Alert
                style={{ marginBottom: "10px" }}
                type={item.report.isCompleted ? "info" : "error"}
                description={
                  <Space>
                    <Tag color={item.report.isCompleted ? "success" : "error"}>
                      {item.report.isCompleted
                        ? t("main.dersal.dersGerceklesti")
                        : t("main.dersal.dersGerceklesmedi")}
                    </Tag>
                    {item.report.message}
                  </Space>
                }
                message={
                  <Space size="large" align="baseline">
                    <b>{t("main.dersal.dersRaporunuz")}</b>{" "}
                    {item.report.isCompleted && (
                      <Rate
                        style={{
                          fontSize: "14px",
                          border: "1px solid #ddd",
                          backgroundColor: "#fff",
                          padding: "0 3px 3px 3px",
                          borderRadius: "99px",
                          boxShadow: "0 0 5px #gray",
                        }}
                        disabled
                        defaultValue={item.report.star}
                      ></Rate>
                    )}
                  </Space>
                }
              ></Alert>
              <Space>
                <Avatar
                  size={64}
                  src={process.env.REACT_APP_FILES_BASEURL + item.photo}
                  style={{ border: "1px solid #eee" }}
                />
                <Space direction="vertical">
                  <span>{item.teacher.toUpperCase()}</span>
                  <span>{item.task.lessons.name + " / " + item.task.konu}</span>
                </Space>
              </Space>
              <div style={{ width: "100%", margin: "15px 0 0 0" }}>
                {item.timelog.map((appo) => (
                  <Button
                    key={appo.id}
                    size="small"
                    type="primary"
                    ghost
                    style={{ margin: "0 10px 10px 0" }}
                  >
                    {moment(appo.dateTime)
                      .local()
                      .format("DD MMM, ddd   HH:mm")}
                  </Button>
                ))}
              </div>
              <Space>
                <span>{t("forms.seviye")}: </span>
                <Rate value={item.task.rate} />
              </Space>
              <Divider
                className="mdivider"
                plain
                orientation="left"
                style={{ color: "#4285F6" }}
              >
                {t("main.dersal.katilimcilar")}
              </Divider>

              <ol>
                {item.students.map(
                  (student, ind) =>
                    ind < 5 && <li key={student.id}>{student.fullname}</li>
                )}
                {!item.task.isPersonal && item.students.length > 5 && (
                  <span>...</span>
                )}
                {!item.task.isPersonal && <p>({item.students.length} kişi)</p>}
                {item.task.isPersonal && (
                  <>
                    <li>{item.task.kisi2}</li>
                    <li>{item.task.kisi3}</li>
                    <li>{item.task.kisi4}</li>
                    <li>{item.task.kisi5}</li>
                  </>
                )}
              </ol>
              <div>
                <Divider
                  className="mdivider"
                  style={{ color: "#4285F6" }}
                  orientation="left"
                  plain
                >
                  <Space size="large">
                    <span>
                      {t("main.dersal.mesajlar") +
                        " (" +
                        item.messages.length +
                        ")"}
                    </span>
                  </Space>
                </Divider>

                <LessonMessages
                  messages={item.messages}
                  sir={setInitialRender}
                />
              </div>
            </Card>
          </List.Item>
        )}
      />
    </div>
  );
}

export default MyLessonsHistory;
