import React, { useState, useEffect } from "react";
import { StaffService } from "../../services/StaffService";
import {
  List,
  Switch,
  Divider,
  Row,
  Col,
  Form,
  Input,
  Space,
  Button,
  notification,
} from "antd";

function Lessons() {
  const [form] = Form.useForm();
  const [initialRender, setInitialRender] = useState(true);
  const [lessons, setLessons] = useState([]);
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      StaffService.getLessons().then((data) => {
        setLessons(data.data);
      });
    }
  }, [initialRender]);
  const onFinish = (values) => {
    StaffService.newLesson(values).then((data) => {
      setLessons([...lessons, data.data]);
      notification.success({
        message: "Başarılı",
        description: "Yeni grup eklendi!",
      });
      form.resetFields();
    });
  };
  const onReset = () => {
    form.resetFields();
  };
  const onActiveChange = (index, item) => {
    StaffService.changeLessonActive(item.id, !item.isActive).then((data) => {
      notification.success({
        message: "Başarılı...",
        description: "İşlem başarı ile yapıldı...",
      });
    });
  };

  return (
    <div>
      <Divider>Dersleri Tanımlama ve Listeleme Ekranı</Divider>
      <Row gutter={[24, 24]}>
        <Col sm={24} xs={24} md={24} lg={12} xl={12}>
          <div style={{ backgroundColor: "white" }}>
            <List
              header={
                <div style={{ color: "#1890FF", fontSize: "16px" }}>
                  Kayıtlı Dersler Listesi
                </div>
              }
              footer={
                <div style={{ color: "#1890FF" }}>
                  {lessons.length} adet kayıtlı dersiniz bulunuyor
                </div>
              }
              bordered
              dataSource={lessons}
              renderItem={(item, index) => (
                <List.Item>
                  {item.name}
                  <div style={{ float: "right" }}>
                    <Switch
                      defaultChecked={item.isActive}
                      onChange={() => onActiveChange(index, item)}
                    />
                  </div>
                </List.Item>
              )}
            />
          </div>
        </Col>
        <Col sm={24} xs={24} md={24} lg={12} xl={12}>
          <div
            style={{
              border: "1px solid #ddd",
              padding: "20px",
              backgroundColor: "#fff",
            }}
          >
            <Divider>Yeni Ders Ekle</Divider>
            <Form form={form} onFinish={onFinish}>
              <Form.Item
                name="name"
                label="Ders Adı"
                rules={[{ required: true, message: "Ders Adı gereklidir" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item name="isActive" label="Aktif mi ?" initialValue={true}>
                <Switch defaultChecked={true}></Switch>
              </Form.Item>
              <Form.Item>
                <Space style={{ marginLeft: "80px" }}>
                  <Button type="primary" htmlType="submit">
                    Kaydet
                  </Button>
                  <Button htmlType="button" onClick={onReset}>
                    Sıfırla
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Lessons;
