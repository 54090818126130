export const getLessonType = {
  OnlineOzelDers: 1,
  OfisteOzelDers: 2,
  EvdeOzelDers: 3,
  OnlineGrupDersi: 4,
  OfisteGrupDersi: 5,
  OnlineAmfiDersi: 6,
};
export const giveLessonType = {
  1: "Online Özel Ders",
  2: "Ofiste Özel Ders",
  3: "Evde Özel Ders",
  4: "Online Grup Dersi",
  5: "Ofiste Grup Dersi",
  6: "Online Amfi Dersi",
};
export const lessonTypeOptions = [
  { key: 1, value: "Online Özel Ders" },
  { key: 2, value: "Ofiste Özel Ders" },
  { key: 3, value: "Evde Özel Ders" },
  { key: 4, value: "Online Grup Dersi" },
  { key: 5, value: "Ofiste Grup Dersi" },
  { key: 6, value: "Online Amfi Dersi" },
];
