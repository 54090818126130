import { axios } from "../resources/httpClient";

export const MainService = {
  yeniDersTalebi: (values) => {
    return axios.post("/lessonreq", { ...values });
  },
  yeniSoruSor: (values) => {
    return axios.post("/question", { ...values });
  },
  getSorular: () => {
    return axios.get("/question");
  },
  getSorularByCevapsiz: () => {
    return axios.get("/question?aktifmi=1&cevaplandi=0");
  },
  getSorularById: (id) => {
    return axios.get("/question/" + id);
  },
  yeniCevapGir: (values, id) => {
    return axios.post("/question/" + id, { ...values });
  },
  ChangeActiveQuestion: (id, value) => {
    return axios.put("/question/" + id + "/active?value=" + value);
  },
  DeleteQuestion: (id) => {
    return axios.delete("/question/" + id);
  },
  yeniMesajKaydet: (values) => {
    return axios.post("/messages", { ...values });
  },
  getMesajlarim: (id, tip) => {
    return axios.get("/messages/" + id + "/" + tip);
  },
  getMyUnreadMessagesCount: (role, id) => {
    return axios.get("/messages/" + role + "/" + id + "/count");
  },
  getMySentMessages: (id, role) => {
    return axios.get("/messages/" + id + "/sent/" + role);
  },
  deleteMessage: (id, tip) => {
    return axios.delete("/messages/" + id + "?tip=" + tip);
  },
  yeniLessonTask: (values) => {
    return axios.post("/lessontask", { ...values });
  },
  getMyLessonTasks: (id, tip) => {
    return axios.get("/lessontask/mylessons/" + id + "?role=" + tip);
  },
  getMyLessonTasksHistory: (id, tip) => {
    return axios.get("/lessontask/mylessons/" + id + "/1?role=" + tip);
  },
  getMyRequests: (id) => {
    return axios.get("/lessonreq/myreqs/" + id);
  },
  getOpenedGroupLessons: (tip) => {
    return axios.get("/lessontask/openGroupLessons?tip=" + tip);
  },
  getTeacherExams: (id) => {
    return axios.get("/exams/teacher/" + id);
  },
  getStudentExams: (id) => {
    return axios.get("/exams/student/" + id);
  },
  getExamStudents: (id) => {
    return axios.get("/exams/" + id + "/students");
  },
  searchStudent: (text) => {
    return axios.get("/search/student?text=" + text);
  },
  saveExamStudents: (value) => {
    return axios.post("/exams/saveexamtudents", { ...value });
  },
  saveExamAnswers: (value) => {
    return axios.post("/exams/examanswers", { ...value });
  },
  getStudentExambyId: (id, eid) => {
    return axios.get("/exams/student/" + id + "/exam/" + eid);
  },
  addPdfToTask: (id, url) => {
    return axios.post("/lessontask/" + id + "/addpdf?url=" + url);
  },
  newLessonMessage: (value) => {
    return axios.post("/lessonmessage", { ...value });
  },
  deleteLessonMessage: (id) => {
    return axios.delete("/lessonmessage/" + id);
  },
  deleteLessonTaskPdf: (id) => {
    return axios.delete("/lessontask/" + id + "/deletepdf");
  },
  joinLessonTask: (id, sid) => {
    return axios.put("/lessontask/" + id + "/join?sid=" + sid);
  },
  saveMainAlert: (values) => {
    return axios.post("/mainalert", { ...values });
  },
  getAllAlerts: () => {
    return axios.get("/mainalert/all");
  },
  getAlerts: (role) => {
    return axios.get("/mainalert?role=" + role);
  },
  changeAlertActive: (id, deger) => {
    return axios.put("/mainalert/" + id + "/isactive?deger=" + deger);
  },
  changeIsModalActive: (id, deger) => {
    return axios.put("/mainalert/" + id + "/ismodal?deger=" + deger);
  },
  deleteAlert: (id) => {
    return axios.delete("/mainalert/" + id);
  },
  saveLessonReport: (values) => {
    return axios.post("/lessonreport", { ...values });
  },
  saveBitChanges: (values) => {
    return axios.post("/bitchanges", { ...values });
  },
  checkCard: (cardNo) => {
    return axios.post("/bit/checkcard", { cardNo: cardNo });
  },
  getMyBitChanges: (id) => {
    return axios.get("/bitchanges/" + id);
  },
  getScheduleAppos: (id) => {
    return axios.get("/schedule/" + id + "/appos");
  },
  getMainTeacherStats: (id) => {
    return axios.get("/lessonreport/teacher/" + id + "/stats");
  },
  getMainStudentStats: (id) => {
    return axios.get("/lessonreport/student/" + id + "/stats");
  },
};
