import { Button, Form, Input, Rate, Switch, Space } from "antd";
import React, { useContext, useState } from "react";
import { UserContext } from "../../../context/UserContext";
import { MainService } from "../../../services/MainService";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "../../../context/GlobalContext";

function LessonReportForm({ sir, closeModal, taskId, bit }) {
  const { myRole, myId } = useContext(UserContext);
  const { t } = useTranslation();
  const { desc } = useContext(GlobalContext);
  const [form] = Form.useForm();
  const [isComp, setIsComp] = useState(true);
  const onReset = () => {
    form.resetFields();
  };
  const onFinish = (values) => {
    values = {
      ...values,
      from: myRole,
      studentId: myRole === "Student" ? myId : null,
      teacherId: myRole !== "Student" ? myId : null,
      isCompleted: isComp,
      lessonTaskId: taskId,
      bitValue: bit,
    };
    MainService.saveLessonReport(values).then((data) => {
      onReset();
      closeModal(false);
      sir(true);
    });
  };

  return (
    <div>
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        onReset={onReset}
        initialValues={{ star: 3 }}
      >
        <Form.Item label={t("main.dersal.gerceklestimi")} name="isCompleted">
          <Switch
            checked={isComp}
            onChange={() => setIsComp(!isComp)}
            checkedChildren={t("global.evet")}
            unCheckedChildren={t("global.hayir")}
          />
        </Form.Item>
        <Form.Item
          label={
            isComp
              ? t("main.dersal.dersYorum")
              : t("main.dersal.nedenGerceklesmedi")
          }
          name="message"
          rules={[{ required: true, message: t("forms.messages.gerekli") }]}
        >
          <Input placeholder={t("forms.placeholders.yorum")} />
        </Form.Item>
        {isComp && (
          <Form.Item
            name="star"
            label={t("main.dersal.degerlendir")}
            rules={[
              { required: true, message: t("forms.messages.gerekli") },
              {
                type: "number",
                min: 1,
                max: 5,
                message: t("forms.messages.yildizaralik"),
              },
            ]}
          >
            <Rate tooltips={desc} />
          </Form.Item>
        )}
        <Form.Item>
          <Space>
            <Button type="default" danger onClick={() => closeModal(false)}>
              {t("global.iptal")}
            </Button>
            <Button type="default" htmlType="reset">
              {t("forms.temizle")}
            </Button>
            <Button type="primary" htmlType="submit">
              {t("forms.gonder")}
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
}

export default LessonReportForm;
