import React from "react";
import { Column, Pie } from "@ant-design/charts";
import { Col, Divider, Row } from "antd";
import { SYDivider } from "../../../resources/styledComponents";

const StarGraph = ({ stats }) => {
  var configStar = (t) => {
    return {
      appendPadding: 20,
      angleField: "value",
      colorField: "type",
      color:
        t === 1
          ? ["#ED4235", "#FBBD05", "#4285F6", "#62DAAB", "#34A856", "#A4549F"]
          : ({ type }) => {
              switch (type.charAt(0)) {
                case "1":
                  return "#ED4235";
                case "2":
                  return "#FBBD05";
                case "3":
                  return "#4285F6";
                case "4":
                  return "#62DAAB";
                case "5":
                  return "#34A856";
                default:
                  break;
              }
            },
      radius: 1,
      innerRadius: 0.64,
      statistic: {
        title: {
          formatter: function formatter(v) {
            return v === undefined ? "Toplam" : v.type;
          },
          style: {
            fontSize: "14px",
            paddingBottom: "5px",
          },
        },
        value: {
          formatter: function formatter(v) {
            return "".concat(v, " adet");
          },
        },
      },
      label: {
        type: "inner",
        offset: "-50%",
        style: { textAlign: "center" },
        autoRotate: false,
        content: "{percentage}",
      },
      interactions: [
        { type: "pie-legend-active" },
        { type: "element-active" },
        { type: "pie-statistic-active" },
      ],
    };
  };
  var configBit = {
    appendPadding: 20,
    xField: "type",
    yField: "value",
    columnWidthRatio: 0.8,
    meta: {
      type: { alias: "Tarih" },
      value: { alias: "Bit Getirisi" },
    },
    label: {
      position: "middle",
      layout: [
        { type: "interval-adjust-position" },
        { type: "interval-hide-overlap" },
        { type: "adjust-color" },
      ],
    },
  };
  var configTasks = {
    appendPadding: 20,
    color: "#6DC8A6",
    xField: "type",
    yField: "value",
    columnWidthRatio: 0.8,
    meta: {
      type: { alias: "Ders Türü" },
      value: { alias: "Ders Sayısı" },
    },
  };
  var configSchedule = {
    appendPadding: 20,
    color: "#2E77D0",
    xField: "type",
    yField: "value",
    columnWidthRatio: 0.8,
    meta: {
      type: { alias: "Öğretmen" },
      value: { alias: "Boş Ders Sayısı" },
    },
  };
  return (
    <div>
      {stats && (
        <>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
              <div
                style={{
                  border: "1px solid #ddd",
                  backgroundColor: "white",
                  boxShadow: "0 0 5px #eee",
                }}
              >
                <SYDivider renk="mavi">Toplam Yıldız Raporu</SYDivider>
                <div style={{ width: "100%" }}>
                  {<Pie {...configStar(0)} data={stats.global} />}
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
              <div
                style={{
                  border: "1px solid #ddd",
                  backgroundColor: "white",
                  boxShadow: "0 0 5px #eee",
                }}
              >
                <SYDivider renk="mavi">Öğretmenlerin Yıldız Raporu</SYDivider>

                <div style={{ width: "100%" }}>
                  {<Pie {...configStar(0)} data={stats.teachers} />}
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
              <div
                style={{
                  border: "1px solid #ddd",
                  backgroundColor: "white",
                  boxShadow: "0 0 5px #eee",
                }}
              >
                <SYDivider renk="mavi">Öğrencilerin Yıldız Raporu</SYDivider>

                <div style={{ width: "100%" }}>
                  {<Pie {...configStar(0)} data={stats.students} />}
                </div>
              </div>
            </Col>
          </Row>
          <Divider />
          <div
            style={{
              padding: "20px",
              border: "1px solid #ddd",
              boxShadow: "0 0 5px #eee",
              backgroundColor: "white",
            }}
          >
            <SYDivider renk="mavi">
              Günlük Bit kazanç raporu (Son 30 gün)
            </SYDivider>
            <Column {...configBit} data={stats.dayBitTotals} />
          </div>
          <Divider />
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
              <div
                style={{
                  padding: "20px",
                  border: "1px solid #ddd",
                  boxShadow: "0 0 5px #eee",
                  backgroundColor: "white",
                }}
              >
                <SYDivider renk="mavi">Bekleyen Dersler Raporu</SYDivider>
                <Column {...configTasks} data={stats.lessonTaskDetails} />
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
              <div
                style={{
                  border: "1px solid #ddd",
                  backgroundColor: "white",
                  boxShadow: "0 0 5px #eee",
                }}
              >
                <SYDivider renk="mavi">Mesajlar Raporu</SYDivider>

                <div style={{ width: "100%" }}>
                  {<Pie {...configStar(1)} data={stats.messagesDetails} />}
                </div>
              </div>
            </Col>
          </Row>

          <Divider />
          <div
            style={{
              padding: "20px",
              border: "1px solid #ddd",
              boxShadow: "0 0 5px #eee",
              backgroundColor: "white",
            }}
          >
            <SYDivider renk="mavi">Öğretmenlerin Boş Dersler Raporu</SYDivider>
            <Column {...configSchedule} data={stats.scheduleDetails} />
          </div>
        </>
      )}
    </div>
  );
};
export default StarGraph;
