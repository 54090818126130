import { Button, Divider } from "antd";
import React from "react";
import styled from "styled-components";
import {
  border,
  color,
  flexbox,
  layout,
  shadow,
  space,
  typography,
  variant,
} from "styled-system";

export const SYCenterDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${(props) => props.yukseklik || "80px"};
  border: 1px solid #ddd;
  background-color: white;
`;
export const SYBlackButton = styled(Button)`
  background-color: #37363a;
  color: white;
`;
export const SYBox = styled("div")(
  {
    boxSizing: "border-box",
  },
  space,
  color,
  border,
  shadow,
  typography,
  layout,
  flexbox
);

export const SYDivider = styled(Divider)(
  variant({
    prop: "renk",
    variants: {
      mavi: {
        color: "#1890FF!important",
      },
      siyah: {
        color: "#333333!important",
      },
    },
  }),
  variant({
    prop: "yazi",
    variants: {
      buyuk: {
        fontSize: "16px!important",
      },
      kucuk: {
        fontSize: "14px!important",
      },
    },
  }),
  border
);

export const SYSpan = styled("span")(
  { letterSpacing: (props) => (props.spacing ? "0.03em" : 0) },
  space,
  color,
  typography
);

export const SYP = styled("p")(
  { letterSpacing: (props) => (props.spacing ? "0.03em" : 0) },
  space,
  color,
  typography
);

export function BitIcon({ ...props }) {
  return (
    <svg
      width="500"
      height="500"
      viewBox="0 0 500 500"
      {...props}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="250"
        cy="250"
        r="240"
        fill="#FBBD05"
        stroke="#E2A900"
        strokeWidth="20"
      />
      <path
        d="M135.72 333.88C126.92 333.88 118.12 332.92 109.32 331C100.52 329.24 92.36 326.84 84.84 323.8V188.92C84.84 185.4 84.6 183.16 84.12 182.2C83.64 181.08 82.36 179.88 80.28 178.6L69 173.08L70.2 154.84C85.08 152.28 96.36 150.44 104.04 149.32C111.72 148.2 117.72 147.56 122.04 147.4C123.64 150.76 124.76 154.92 125.4 159.88V207.88C134.52 204.68 143.56 203.08 152.52 203.08C169.32 203.08 182.44 208.44 191.88 219.16C201.48 229.72 206.28 244.92 206.28 264.76C206.28 286.68 200.2 303.72 188.04 315.88C176.04 327.88 158.6 333.88 135.72 333.88ZM144.12 302.92C151.64 302.92 157.24 302.12 160.92 300.52C162.2 296.2 163.24 291.16 164.04 285.4C164.84 279.64 165.24 274.04 165.24 268.6C165.24 243.96 157 231.64 140.52 231.64C136.36 231.64 131.32 232.36 125.4 233.8V299.8C129.56 301.88 135.8 302.92 144.12 302.92ZM241.183 153.4L281.983 142.6L291.823 179.08L251.263 190.12L241.183 153.4ZM231.103 308.92L247.903 303.88V245.56C247.903 242.04 247.583 239.8 246.943 238.84C246.463 237.72 245.103 236.52 242.863 235.24L232.303 229.48L233.503 211.48C246.783 208.92 257.023 207.08 264.223 205.96C271.583 204.68 278.303 203.96 284.383 203.8C286.463 210.68 287.743 215.8 288.223 219.16V295.96C288.223 299.32 288.463 301.56 288.943 302.68C289.423 303.8 290.783 305 293.023 306.28L304.783 313L303.823 331H231.823L231.103 308.92ZM378.799 334.12C364.719 334.12 354.319 330.68 347.599 323.8C340.879 316.76 337.519 306.12 337.519 291.88V233.56H320.719V216.04L363.919 176.68L377.839 178.36V205.48H418.159V233.8H377.839V283.24C377.839 290.92 378.879 296.04 380.959 298.6C383.199 301.16 387.439 302.44 393.679 302.44C398.959 302.44 406.319 301.72 415.759 300.28L418.639 324.76C412.879 327.48 406.399 329.72 399.199 331.48C392.159 333.24 385.359 334.12 378.799 334.12Z"
        fill="white"
      />
    </svg>
  );
}
export const SYCard = ({ children, header, action, footer }) => (
  <SYBox
    boxShadow="0 0 5px #eee"
    bg="#FDF7E3"
    border="1px solid #F1E6BC"
    width="500px"
    p="20px"
  >
    <SYBox
      bg="#FBF2D5"
      p="10px"
      borderBottom="1px solid #F1E6BC"
      margin="-20px -20px 20px -20px"
    >
      <SYBox display="flex" justifyContent="space-between">
        <SYSpan fontWeight="500">{header}</SYSpan>
        {action}
      </SYBox>
    </SYBox>
    {children}
    {footer !== null && (
      <SYBox
        bg="rgba(255, 255, 255, .4)"
        p="10px"
        borderTop="1px solid #F1E6BC"
        margin="20px -20px -20px -20px"
      >
        {footer}
      </SYBox>
    )}
  </SYBox>
);
