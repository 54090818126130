import { Divider, Row, Col, Card, Tag, Button } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { StaffService } from "../../services/StaffService";
import { useTranslation } from "react-i18next";

function TeachersBar() {
  const [initialRender, setInitialRender] = useState(true);
  const [teachers, setTeachers] = useState([]);
  const { t } = useTranslation();
  const router = useHistory();
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      StaffService.getTeachers(true, 8).then((data) => {
        setTeachers(data.data);
      });
    }
  }, [initialRender]);
  const teacherSelected = (id) => {
    router.push("/home/teachers/" + id);
  };

  return (
    <div className="cont-cont">
      <div className="container" style={{ backgroundColor: "#f5f5f5" }}>
        <Divider style={{ color: "#1890FF" }}>
          {t("home.teachers.baziOgretmenler")}
        </Divider>

        <Row gutter={[16, 16]}>
          {teachers.map((teacher) => (
            <Col xs={24} sm={12} md={8} lg={6} xl={6} key={teacher.key}>
              <Card
                hoverable
                cover={
                  <img
                    alt="example"
                    src={
                      process.env.REACT_APP_FILES_BASEURL +
                      teacher.ogretmen.photo
                    }
                    style={{ border: "1px solid #eee" }}
                  />
                }
              >
                <Card.Meta
                  title={
                    <Button
                      onClick={() => teacherSelected(teacher.ogretmen.id)}
                    >
                      <b>{teacher.ogretmen.fullname}</b>
                    </Button>
                  }
                />
                <br />
                {teacher.gruplar.map((grup) => (
                  <Tag color="blue" key={grup.id}>
                    {grup.name}
                  </Tag>
                ))}
                <p></p>
                {teacher.dersler.map((ders) => (
                  <Tag color="magenta" key={ders.id}>
                    {ders.name}
                  </Tag>
                ))}
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
}

export default TeachersBar;
