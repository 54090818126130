import {
  FacebookOutlined,
  InstagramOutlined,
  TwitterOutlined,
} from "@ant-design/icons";
import { Col, Divider, Image, Row, Space } from "antd";
import React from "react";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { GlobalContext } from "../../context/GlobalContext";
import { useTranslation } from "react-i18next";
function HomeFooter() {
  const { kurum } = useContext(GlobalContext);
  const { t } = useTranslation();
  const goSocial = (sec) => {
    switch (sec) {
      case 1:
        window.open(kurum.instagram, "_blank");
        break;
      case 2:
        window.open(kurum.facebook, "_blank");
        break;
      case 3:
        window.open(kurum.twitter, "_blank");
        break;
      // case 4:
      //   window.open("http://youtube.com", "_blank");
      //   break;

      default:
        break;
    }
  };
  return (
    <div
      style={{
        backgroundColor: "#37363A",
        color: "whitesmoke",
        marginTop: "30px",
      }}
    >
      <Row
        gutter={[0, 16]}
        style={{ borderBottom: "1px solid #434246" }}
        align="middle"
      >
        <Col
          style={{ padding: "20px", borderRight: "1px solid #434246" }}
          xs={24}
          sm={12}
          md={12}
          lg={6}
          xl={6}
          xxl={6}
        >
          {kurum && (
            <img
              className="logo-footer"
              src={process.env.REACT_APP_FILES_BASEURL + kurum.logo}
              alt="logo"
            />
          )}
        </Col>
        <Col
          style={{ padding: "20px", borderRight: "1px solid #434246" }}
          xs={24}
          sm={12}
          md={12}
          lg={6}
          xl={6}
          xxl={6}
        >
          <ul className="footer-liste" style={{ listStyleType: "none" }}>
            <li>
              <Link to="/home/aboutus" style={{ color: "whitesmoke" }}>
                {t("anamenu.hakkimizda")}
              </Link>
            </li>
            <li>
              <Link to="/home/contact" style={{ color: "whitesmoke" }}>
                {t("anamenu.iletisim")}
              </Link>
            </li>
            <li>
              <Link to="/login/loginpage" style={{ color: "whitesmoke" }}>
                {t("global.giris")}
              </Link>
            </li>
            <li>
              <Link to="/login/signuppage" style={{ color: "whitesmoke" }}>
                {t("global.kayitol")}
              </Link>
            </li>
          </ul>
        </Col>

        <Col
          style={{ padding: "20px", borderRight: "1px solid #434246" }}
          xs={24}
          sm={12}
          md={12}
          lg={6}
          xl={6}
          xxl={6}
        >
          <p style={{ fontSize: "18px", fontWeight: "300" }}>
            {kurum && kurum.phone1}
          </p>
          <p style={{ fontSize: "18px", fontWeight: "300" }}>
            {kurum && kurum.phone2}
          </p>
          <Space>
            <InstagramOutlined className="sosyal" onClick={() => goSocial(1)} />
            <FacebookOutlined className="sosyal" onClick={() => goSocial(2)} />
            <TwitterOutlined className="sosyal" onClick={() => goSocial(3)} />
            {/* <YoutubeOutlined className="sosyal" onClick={() => goSocial(4)} /> */}
          </Space>
        </Col>
        <Col
          style={{ padding: "20px" }}
          xs={24}
          sm={12}
          md={12}
          lg={6}
          xl={6}
          xxl={6}
        >
          <p>{t("forms.adres")}</p>
          <p>{kurum && kurum.address1}</p>
          <p>{kurum && kurum.address2}</p>
        </Col>
      </Row>
      <div
        style={{
          textAlign: "center",
          padding: "20px",
          color: "#939398",
          fontWeight: "300",
        }}
      >
        <Space>
          <span>{t("home.footer.kullanimSartlari")} </span>
          <Divider style={{ borderColor: "#999999" }} type="vertical" />
          <span>{t("home.footer.gizlilikPolitikasi")} </span>
          <Divider style={{ borderColor: "#999999" }} type="vertical" />
          <span>{t("home.footer.tumHaklarSakli")}. Copyright © 2020 </span>
          <Divider style={{ borderColor: "#999999" }} type="vertical" />
          <span>Developed by Hypotenuse </span>
        </Space>
        <br />
        <br />
        <Image
          style={{ opacity: ".7" }}
          src={require("../../assets/images/paymethods.png")}
          width={250}
        />

        <br />
      </div>
    </div>
  );
}

export default HomeFooter;
