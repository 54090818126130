import { Button, Form, Input, Space, notification } from "antd";
import React, { useContext, useRef, useState } from "react";
import {
  BitIcon,
  SYBox,
  SYP,
  SYSpan,
} from "../../../resources/styledComponents";
import { SYColors } from "../../../resources/SYColors";
import { MainService } from "../../../services/MainService";
import moment from "moment";
import { UserContext } from "../../../context/UserContext";
import { GlobalContext } from "../../../context/GlobalContext";
import { useTranslation } from "react-i18next";
const useFocus = () => {
  const htmlElRef = useRef(null);
  const setFocus = () => {
    htmlElRef.current && htmlElRef.current.focus();
  };

  return [htmlElRef, setFocus];
};
function UseBitCard() {
  const [form] = Form.useForm();
  const [input1Ref, setInput1Focus] = useFocus();
  const [input2Ref, setInput2Focus] = useFocus();
  const [input3Ref, setInput3Focus] = useFocus();
  const [input4Ref, setInput4Focus] = useFocus();
  const [card, setCard] = useState(null);
  const { myId } = useContext(UserContext);
  const { bitValue, setBitValue, kurum } = useContext(GlobalContext);
  const { t } = useTranslation();

  const onFinish = (values) => {
    const cardNo =
      values.bir + "-" + values.iki + "-" + values.uc + "-" + values.dort;
    MainService.checkCard(cardNo).then((data) => {
      if (!data.data.message) {
        setCard(data.data);
      } else {
        notification.error({ message: t(`main.usecard.${data.data.message}`) });
      }
    });
  };
  const onReset = () => {
    form.resetFields();
  };

  const onChangeInput = (val, no) => {
    const veri = val.target.value.length;
    if (veri === 4) {
      no === 1
        ? setInput2Focus()
        : no === 2
        ? setInput3Focus()
        : no === 3
        ? setInput4Focus()
        : setInput1Focus();
    }
  };
  const yukle = () => {
    const values = {
      val: card.val,
      used: false,
      saved: true,
      studentId: myId,
      message: card.val + t("main.usecard.bitYuklemesi"),
      tip: "kart",
      cardNo: card.cardNo,
    };
    MainService.saveBitChanges(values).then((data) => {
      setBitValue(bitValue + card.val);
      localStorage.setItem("bitValue", bitValue + card.val);
      notification.success({
        message: t("global.basarili"),
        description: t("main.usecard.yuklendiMesaj"),
      });
      setCard(null);
      form.resetFields();
    });
  };
  return (
    <div>
      <SYBox
        boxShadow="0 0 10px #ddd"
        p="20px"
        border="1px solid #F1E6BC"
        maxWidth="440px"
        overflowX="scroll"
        borderRadius="10px"
        bg="#FDF7E3"
        textAlign="center"
      >
        <Space direction="vertical">
          <img
            style={{ float: "right" }}
            width={200}
            src={kurum && process.env.REACT_APP_FILES_BASEURL + kurum.logo}
            alt="logo"
          />
          <Space size="large">
            <BitIcon width="64" height="64" />
            <Space direction="vertical" size="small" align="center">
              <SYSpan fontSize="26px" fontWeight="700" color={SYColors.orange}>
                {t("main.usecard.kartBaslik")}
              </SYSpan>
              <SYSpan>{t("main.usecard.kartNoGir")}</SYSpan>
            </Space>
          </Space>
        </Space>
        <br />
        <br />
        <Form
          form={form}
          onFinish={onFinish}
          onReset={onReset}
          layout="inline"
          style={{ textAlign: "center" }}
        >
          <Form.Item style={{ marginLeft: "40px" }}>
            <Input.Group compact>
              <Form.Item style={{ width: "80px" }} name="bir">
                <Input
                  autoCapitalize="off"
                  autoComplete="off"
                  autoCorrect="off"
                  onChange={(a) => onChangeInput(a, 1)}
                  ref={input1Ref}
                  maxLength={4}
                  placeholder="XXXX"
                  size="large"
                  autoFocus
                  required
                />
              </Form.Item>
              <Form.Item style={{ width: "80px" }} name="iki">
                <Input
                  autoCapitalize="off"
                  autoComplete="off"
                  autoCorrect="off"
                  onChange={(a) => onChangeInput(a, 2)}
                  ref={input2Ref}
                  maxLength={4}
                  placeholder="XXXX"
                  size="large"
                  required
                />
              </Form.Item>
              <Form.Item style={{ width: "80px" }} name="uc">
                <Input
                  autoCapitalize="off"
                  autoComplete="off"
                  autoCorrect="off"
                  onChange={(a) => onChangeInput(a, 3)}
                  ref={input3Ref}
                  maxLength={4}
                  placeholder="XXXX"
                  size="large"
                  required
                />
              </Form.Item>
              <Form.Item style={{ width: "80px" }} name="dort">
                <Input
                  autoCapitalize="off"
                  autoComplete="off"
                  autoCorrect="off"
                  onChange={(a) => onChangeInput(a, 4)}
                  ref={input4Ref}
                  maxLength={4}
                  placeholder="XXXX"
                  size="large"
                  required
                />
              </Form.Item>
            </Input.Group>
          </Form.Item>
          <Form.Item>
            <Button
              style={{ marginTop: "10px", marginLeft: "280px" }}
              type="primary"
              htmlType="submit"
            >
              {t("main.usecard.kontrolEt")}
            </Button>
          </Form.Item>
        </Form>
        {card && (
          <SYBox fontSize="16px">
            <SYP color={SYColors.green}>{t("main.usecard.gecerli")}</SYP>
            <SYP>
              {t("main.usecard.bitDegeri")} : {card.val}
            </SYP>
            <SYP>
              {t("main.usecard.gecerlilikTarihi")} :{" "}
              {moment(card.endDate).local().format("DD MMMM YYYY")}
            </SYP>
            <Button type="primary" onClick={() => yukle()}>
              {t("main.usecard.yukle")}
            </Button>
          </SYBox>
        )}
      </SYBox>
    </div>
  );
}

export default UseBitCard;
