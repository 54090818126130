import React, { useState, useEffect, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Col, Row, Image, Descriptions, Space, Tag, Button } from "antd";
import Title from "antd/lib/typography/Title";
import { StaffService } from "../../../services/StaffService";
import { getLocation } from "../../../resources/il-ilce";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "../../../context/GlobalContext";

function MainTeacherDetails() {
  const { id } = useParams();
  const { t } = useTranslation();
  const { lessonTypes } = useContext(GlobalContext);
  const [initialRender, setInitialRender] = useState(true);
  const [teacher, setTeacher] = useState(null);
  const router = useHistory();
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      StaffService.getTeacher(id).then((data) => {
        setTeacher(data.data);
      });
    }
  }, [initialRender, id]);
  function createMarkup(gelenDetay) {
    return {
      __html: gelenDetay,
    };
  }
  return (
    <div style={{ margin: "20px" }}>
      {teacher && (
        <Row gutter={[16, 16]}>
          <Col
            xs={24}
            sm={6}
            md={6}
            lg={6}
            xl={6}
            style={{ textAlign: "center" }}
          >
            <Space direction="vertical">
              <Button
                type="primary"
                block
                ghost
                onClick={() => router.goBack()}
              >
                {t("global.geridon")}
              </Button>
              <Image
                style={{
                  maxWidth: "200px",
                  border: "1px solid #ddd",
                }}
                src={
                  process.env.REACT_APP_FILES_BASEURL + teacher.ogretmen.photo
                }
                alt=""
              />
              <div>
                {teacher.gruplar.map((grup) => (
                  <Tag color="blue" key={grup.id}>
                    {grup.name}
                  </Tag>
                ))}
              </div>
              <div>
                {teacher.dersler.map((ders) => (
                  <Tag color="magenta" key={ders.id}>
                    {ders.name}
                  </Tag>
                ))}
              </div>
              <Button type="primary" block>
                {t("home.teachers.dersverdi", { sayi: teacher.sayi })}
              </Button>
              {/* <Button type="ghost" danger block>
                {t("global.mesajGonder")}
              </Button> */}
            </Space>
          </Col>
          <Col
            xs={24}
            sm={18}
            md={18}
            lg={18}
            xl={18}
            style={{
              backgroundColor: "white",
              border: "1px solid #ddd",
              padding: "20px",
              marginTop: "8px",
            }}
          >
            <Space>
              <Title level={2} style={{ fontWeight: 700, textAlign: "center" }}>
                {teacher.ogretmen.fullname}
              </Title>
            </Space>

            <Descriptions
              size="small"
              bordered
              column={{ xxl: 2, xl: 2, lg: 1, md: 1, sm: 1, xs: 1 }}
            >
              <Descriptions.Item label={t("descriptions.konum")} span={2}>
                {getLocation(teacher.ogretmen.il, teacher.ogretmen.ilce)}
              </Descriptions.Item>
              {lessonTypes.map((lt, index) => (
                <Descriptions.Item
                  label={lt.value + t("global.ucreti")}
                  key={index}
                >
                  {teacher.ucretler[`ucret${lt.key}`]} Bit
                </Descriptions.Item>
              ))}

              <Descriptions.Item label={t("descriptions.dersGruplari")}>
                {teacher.gruplar.map((grup) => (
                  <Tag color="blue" key={grup.id}>
                    {grup.name}
                  </Tag>
                ))}
              </Descriptions.Item>
              <Descriptions.Item label={t("descriptions.dersler")}>
                {teacher.dersler.map((ders) => (
                  <Tag color="magenta" key={ders.id}>
                    {ders.name}
                  </Tag>
                ))}
              </Descriptions.Item>
            </Descriptions>
            <br />
            <div
              dangerouslySetInnerHTML={createMarkup(teacher.ogretmen.details)}
              style={{ textAlign: "justify" }}
            ></div>
          </Col>
        </Row>
      )}
    </div>
  );
}

export default MainTeacherDetails;
