import { List, notification, Popconfirm } from "antd";
import React, { useContext } from "react";
import { DeleteOutlined, UserOutlined } from "@ant-design/icons";
import { UserContext } from "../../../context/UserContext";
import { MainService } from "../../../services/MainService";
import moment from "moment";
import { useTranslation } from "react-i18next";
function LessonMessages({ messages, sir }) {
  const { myRole, myEmail } = useContext(UserContext);
  const { t } = useTranslation();
  const deleteMessage = (id) => {
    MainService.deleteLessonMessage(id).then((data) => {
      notification.success({
        message: t("global.basarili"),
        description: t("global.mesajSilindi"),
      });
      sir(true);
    });
  };
  return (
    <div>
      {messages.length > 0 && (
        <List
          size="small"
          style={{ borderColor: "#eee" }}
          // bordered
          itemLayout="horizontal"
          dataSource={messages}
          renderItem={(item, index) => (
            <List.Item
              className="mesajlist"
              // style={{ backgroundColor: index % 2 === 0 ? "white" : "#fafafa" }}
            >
              <span
                style={{
                  color: item.fromRole === "Student" ? "#821E8F" : "#34A856",
                }}
              >
                <UserOutlined /> {item.fromName}
                {" - "}
                <small style={{ color: "gray" }}>
                  {moment(item.createdOn).local().format("DD MMM, ddd  HH:mm")}
                </small>
              </span>
              <br />
              {item.message}
              {((item.fromRole === myRole && item.owner === myEmail) ||
                myRole === "Teacher") && (
                <Popconfirm
                  title={t("global.silEminmisin")}
                  onConfirm={() => deleteMessage(item.id)}
                  okText={t("global.evet")}
                  cancelText={t("global.iptal")}
                >
                  <DeleteOutlined
                    style={{
                      color: "red",
                      marginLeft: "10px",
                      cursor: "pointer",
                    }}
                  />
                </Popconfirm>
              )}
            </List.Item>
          )}
        />
      )}
    </div>
  );
}

export default LessonMessages;
