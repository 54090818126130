import { Col, Row } from "antd";
import React from "react";

function AdsImages({ ads }) {
  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={ads.col === 1 ? 24 : 12}
          xl={ads.col === 1 ? 24 : 12}
          xxl={ads.col === 1 ? 24 : 12}
        >
          <img
            src={process.env.REACT_APP_FILES_BASEURL + ads.image1}
            alt="ads1"
            width="100%"
            style={{ border: "1px solid #ddd", boxShadow: "0 0 5px #eee" }}
          />
        </Col>
        {ads.col === 2 && (
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <img
              src={process.env.REACT_APP_FILES_BASEURL + ads.image2}
              alt="ads2"
              width="100%"
              style={{ border: "1px solid #ddd", boxShadow: "0 0 5px #eee" }}
            />
          </Col>
        )}
      </Row>
    </div>
  );
}

export default AdsImages;
