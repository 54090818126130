import { Row, Col, Divider, Card, Tag, Space, Button } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { HomeService } from "../../services/HomeService";
import format from "date-fns/format";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "../../context/GlobalContext";
import { useContext } from "react";

function UpcomingLessons() {
  const [initialRender, setInitialRender] = useState(true);
  const { t } = useTranslation();
  const { lessonTypes } = useContext(GlobalContext);
  const [upcoming, setUpcoming] = useState([]);
  const router = useHistory();
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      HomeService.getUpcomingLessons(3).then((data) => {
        setUpcoming(data.data);
      });
    }
  }, [initialRender]);
  return (
    <div>
      {upcoming.length > 0 && (
        <div style={{ padding: "20px" }}>
          <Divider style={{ color: "#1890FF" }}>
            {t("home.upcoming.yaklasanlar")}
          </Divider>

          <Row gutter={[16, 16]}>
            {upcoming.map((u, index) => (
              <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6} key={index}>
                <div>
                  <Card
                    type="inner"
                    size="small"
                    title={lessonTypes[u.detay.tip - 1].value}
                    extra={
                      <Button
                        style={{ backgroundColor: "white" }}
                        type="primary"
                        ghost
                        size="small"
                        onClick={() => router.push("/main/getLesson")}
                      >
                        {t("home.upcoming.katil")}
                      </Button>
                    }
                    style={{
                      border: "1px solid #ddd",
                      width: "100%",
                      boxShadow: "0 0 5px #eee",
                    }}
                  >
                    <Tag color="blue" style={{ marginBottom: "5px" }}>
                      {u.detay.lessons.name}
                    </Tag>
                    <p style={{ color: "#1890FF", fontWeight: "500" }}>
                      {u.detay.konu.toUpperCase()}
                    </p>
                    <p>
                      {t("home.upcoming.ogretmen")} : <b>{u.ogretmen}</b>
                    </p>
                    <Space direction="vertical">
                      <div style={{ width: "100%" }}>
                        {u.appos.map((appo) => (
                          <Tag key={appo.id}>
                            {format(
                              new Date(appo.dateTime),
                              "DD MMM, ddd, HH:mm"
                            )}
                          </Tag>
                        ))}
                      </div>
                    </Space>
                  </Card>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      )}
    </div>
  );
}

export default UpcomingLessons;
