import { MessageOutlined, UnorderedListOutlined } from "@ant-design/icons";
import {
  Button,
  Descriptions,
  Divider,
  Modal,
  notification,
  Select,
  Space,
  Spin,
  Table,
  Tooltip,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { getMessageType } from "../../../resources/getMessageType";
import { MainService } from "../../../services/MainService";
import Messages from "../Messages/Messages";
import { UserContext } from "../../../context/UserContext";
import { GlobalContext } from "../../../context/GlobalContext";
import { useTranslation } from "react-i18next";

function TeacherExamDetails() {
  const location = useLocation();
  const router = useHistory();
  const { veri } = location.state || { veri: null };
  const { Option } = Select;
  const { t } = useTranslation();

  const [value, setValue] = useState([]);
  const [options, setOptions] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [stext, setStext] = useState(0);
  const [initialRender, setInitialRender] = useState(true);
  const [dataSource, setDataSource] = useState([]);
  const [page, setPage] = useState(1);
  const { openModal, setOpenModal } = useContext(GlobalContext);
  const [toId, setToId] = useState();
  const { myId } = useContext(UserContext);
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      MainService.getExamStudents(veri.exam.id).then((data) => {
        const sdata = []
          .concat(data.data)
          .sort((a, b) => (a.exam.net < b.exam.net ? 1 : -1));
        setDataSource(sdata);
        console.log(sdata);
      });
    }
  }, [initialRender, veri.exam.id]);

  const goMessage = (sId) => {
    setToId(sId);
    setOpenModal(true);
  };
  const onSearch = (searchText) => {
    setStext(searchText.length);
    setFetching(true);
    if (searchText.length > 2) {
      MainService.searchStudent(searchText).then((data) => {
        setOptions(data.data);
        setFetching(false);
      });
    } else {
      setFetching(false);
      setOptions([]);
    }
  };

  const onSelect = (data) => {
    setStext(0);
    //  setOpenModal(true);
  };

  const onChange = (value) => {
    setValue(value);
    setOptions([]);
  };
  const gonder = () => {
    var ogr = [];
    value.forEach((o) => ogr.push(parseInt(o.value)));
    const giden = { examId: veri.exam.id, students: ogr };
    console.log("giden:", giden);
    MainService.saveExamStudents(giden)
      .then((data) => {
        notification.success({
          message: t("global.basarili"),
          description: t("main.exam.sinavGonderildi"),
        });
        setValue([]);
        setOptions([]);
        setInitialRender(true);
      })
      .catch((error) => {
        notification.error({
          message: t("main.exam.kayitYapilmadi"),
          description: t("main.exam.sinavGonderilmiş"),
        });
      });
  };
  const columns = [
    {
      key: "index",
      title: t("main.exam.sira"),
      render: (value, item, index) => <b>{(page - 1) * 10 + index + 1}.</b>,
    },
    {
      title: t("forms.adsoyad"),
      dataIndex: "student",
      render: (student) => <span>{student.fullname}</span>,
    },
    {
      title: t("main.exam.yapildi"),
      dataIndex: "exam",
      render: (exam) => (
        <span>
          {exam.isOk ? (
            <span style={{ color: "#34A856" }}>{t("global.evet")}</span>
          ) : (
            <span style={{ color: "tomato" }}>{t("global.hayir")}</span>
          )}
        </span>
      ),
    },
    {
      title: t("main.exam.dogru"),
      dataIndex: "exam",
      render: (exam) => <span>{exam.dogru}</span>,
    },
    {
      title: t("main.exam.yanlis"),
      dataIndex: "exam",
      render: (exam) => <span>{exam.yanlis}</span>,
    },
    {
      title: t("main.exam.bos"),
      dataIndex: "exam",
      render: (exam) => <span>{exam.bos}</span>,
    },
    {
      title: t("main.exam.net"),
      dataIndex: "exam",
      render: (exam) => <b>{exam.net}</b>,
    },
    {
      title: t("main.exam.islem"),
      key: "action",
      render: (text, record) => (
        <Space>
          <Tooltip title={t("global.mesajGonder")}>
            <Button
              onClick={() => goMessage(record.student.id)}
              type="default"
              shape="circle"
              icon={<MessageOutlined />}
            />
          </Tooltip>
          {record.exam.isOk && (
            <Tooltip title={t("main.exam.detaylar")}>
              <Button
                onClick={() =>
                  router.push("/main/exams/student/" + record.exam.examId, {
                    verim: record.exam.studentId,
                  })
                }
                type="primary"
                shape="circle"
                icon={<UnorderedListOutlined />}
              />
            </Tooltip>
          )}
        </Space>
      ),
    },
  ];
  return (
    <div
      style={{
        border: "1px solid #ddd",
        padding: "15px",
        backgroundColor: "white",
      }}
    >
      {veri === null && <p>{t("main.exam.kayitYok")}</p>}
      {veri !== null && (
        <div>
          <Modal
            closable
            title={t("main.dersal.mesajYaz")}
            visible={openModal}
            footer={null}
            onCancel={() => setOpenModal(false)}
          >
            <Messages
              ft={getMessageType.OgretmenToOgrenci}
              from={myId}
              to={toId}
            />
          </Modal>
          <h3 style={{ color: "#1890FF" }}>{veri.exam.title}</h3>
          <p>{veri.exam.description}</p>
          <Descriptions
            size="small"
            bordered
            column={{ xxl: 2, xl: 2, lg: 1, md: 1, sm: 1, xs: 1 }}
            style={{ backgroundColor: "white" }}
          >
            <Descriptions.Item label={t("main.exam.ders")}>
              {veri.lesson.name}
            </Descriptions.Item>
            <Descriptions.Item label={t("main.exam.secSayisi")}>
              {veri.exam.choice}
            </Descriptions.Item>
            <Descriptions.Item label={t("main.exam.soruSayisi")}>
              {veri.exam.questions}
            </Descriptions.Item>
            <Descriptions.Item label={t("main.exam.sinavPdf")}>
              <Button
                type="link"
                onClick={() =>
                  window.open(
                    process.env.REACT_APP_FILES_BASEURL + veri.exam.pdflink,
                    "_blank"
                  )
                }
              >
                {" "}
                {veri.exam.pdflink.split("-").pop()}
              </Button>
            </Descriptions.Item>
            <Descriptions.Item label={t("main.exam.cevaplar")}>
              {veri.exam.keys.split("").map((k, index) => (
                <span key={index}>
                  {index > 4 && index % 5 === 0 ? <br /> : null}
                  <span key={index} style={{ fontSize: "16px" }}>
                    <b style={{ color: "gray" }}>
                      {index > 0 && index % 5 !== 0 ? ",  " : ""}{" "}
                      {index + 1 + ". "}
                    </b>
                    <b style={{ color: k === "X" ? "tomato" : "#34A856" }}>
                      {k + " "}
                    </b>
                  </span>
                </span>
              ))}
            </Descriptions.Item>
          </Descriptions>
          <Divider style={{ color: "#1890FF" }} orientation="left">
            {t("main.exam.sinavGonder")}
          </Divider>
          <div>
            <Select
              mode="multiple"
              labelInValue
              value={value}
              placeholder={t("main.exam.ogrenciSec")}
              notFoundContent={
                fetching ? (
                  <Spin size="small" />
                ) : stext < 3 ? (
                  t("main.exam.3karakterGir")
                ) : (
                  t("main.exam.ogrenciYok")
                )
              }
              filterOption={false}
              onSearch={onSearch}
              onChange={onChange}
              onSelect={onSelect}
              showSearch
              style={{ width: "100%" }}
            >
              {options.map((d) => (
                <Option key={d.value}>{d.label}</Option>
              ))}
            </Select>
            <br />
            <br />
            <Button type="primary" onClick={gonder} disabled={value.length < 1}>
              {t("forms.gonder")}
            </Button>
          </div>
        </div>
      )}
      <Divider style={{ color: "#1890FF" }} orientation="left">
        {t("main.exam.gonderilenlerSonuclar")}
      </Divider>
      <Table
        pagination={{
          onChange(current) {
            setPage(current);
          },
        }}
        size="small"
        dataSource={dataSource}
        columns={columns}
        bordered
      />
    </div>
  );
}

export default TeacherExamDetails;
