import { axios } from "../resources/httpClient";

const ValidationService = {
  checkLogin: (values) => {
    return axios.post("login", values);
  },
  getForgetPasswordLink: (values) => {
    return axios.post("/login/getpasscode", { ...values });
  },
  changePassword: (values) => {
    return axios.post("/login/changepassword", { ...values });
  },
  checkChangeToken: (token) => {
    return axios.get("/login/checkchangetoken?token=" + token);
  },
};

export default ValidationService;
