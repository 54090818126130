import React from "react";
import { SYDivider, SYSpan } from "../../../resources/styledComponents";
import { Table } from "antd";
import moment from "moment";
import { SYColors } from "../../../resources/SYColors";
import { useTranslation } from "react-i18next";
function MyBitChanges({ bitChanges }) {
  const { t } = useTranslation();
  const columns = [
    {
      title: t("forms.tarih"),
      dataIndex: "item",
      render: (item) => (
        <span>
          {moment(item.createdOn).local().format("DD MMM, ddd HH:mm")}
        </span>
      ),
    },
    {
      title: t("home.bitchanges.bilgi"),
      dataIndex: "item",
      render: (item) => <span>{item.message}</span>,
    },
    {
      title: t("home.bitchanges.hareket"),
      dataIndex: "item",
      render: (item) => (
        <SYSpan color={item.saved ? SYColors.green : SYColors.red}>
          {item.saved ? "Yükleme" : "Harcama"}
        </SYSpan>
      ),
    },
    {
      title: t("home.bitchanges.bitDegeri"),
      dataIndex: "item",
      render: (item) => <span>{item.val}</span>,
    },
  ];
  return (
    <div>
      <SYDivider renk="mavi">{t("home.bitchanges.bitHareket")}</SYDivider>
      <Table
        scroll={{ x: 500 }}
        dataSource={bitChanges}
        size="small"
        bordered
        columns={columns}
      />
    </div>
  );
}

export default MyBitChanges;
