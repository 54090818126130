import React, { useContext, useEffect, useState } from "react";
import { MainService } from "../../../services/MainService";
import { getMessageType } from "../../../resources/getMessageType";
import { Avatar, List, Button, Badge, Popconfirm } from "antd";
import format from "date-fns/format";
import { DeleteOutlined, MessageOutlined } from "@ant-design/icons";
import { UserContext } from "../../../context/UserContext";
import { GlobalContext } from "../../../context/GlobalContext";
import Modal from "antd/lib/modal/Modal";
import Messages from "./Messages";
import { SYP } from "../../../resources/styledComponents";
import { useTranslation } from "react-i18next";

function FromAdminMessages() {
  const [mesajlarim, setMesajlarim] = useState([]);
  const [initialRender, setInitialRender] = useState(true);
  const { myId, myRole } = useContext(UserContext);
  const {
    unreadMessages,
    setUnreadMessages,
    openModal,
    setOpenModal,
  } = useContext(GlobalContext);
  const [messageId, setMessageId] = useState(null);
  const [yanit, setYanit] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      MainService.getMesajlarim(
        myId,
        myRole === "Teacher"
          ? getMessageType.AdminToOgretmen
          : getMessageType.AdminToOgrenci
      ).then((data) => {
        setMesajlarim(data.data.messages.reverse());
        setUnreadMessages(
          unreadMessages - data.data.unread < 1
            ? 0
            : unreadMessages - data.data.unread
        );
      });
    }
  }, [initialRender, myId, myRole, setUnreadMessages, unreadMessages]);

  const yanitGonder = (toId, yanitim) => {
    setMessageId(toId);
    setYanit(yanitim);
    setTimeout(() => {
      setOpenModal(true);
    }, 10);
  };
  const hideMessage = (id) => {
    MainService.deleteMessage(id, "to").then((data) => {
      setInitialRender(true);
    });
  };
  return (
    <div>
      <Modal
        closable
        title={t("main.mesaj.mesajYaz")}
        visible={openModal}
        footer={null}
        onCancel={() => setOpenModal(false)}
      >
        <Messages
          ft={myRole === "Teacher" ? getMessageType.OgretmenToAdmin : 7}
          from={myId}
          to={messageId}
          yanit={yanit}
        />
      </Modal>
      <List
        pagination
        className="messagelist"
        grid={{
          gutter: 10,
          xs: 1,
          sm: 1,
          md: 1,
          lg: 2,
          xl: 2,
          xxl: 2,
        }}
        itemLayout="vertical"
        dataSource={mesajlarim}
        header={
          <span
            style={{
              color: "#1890FF",
              fontSize: "15px",
              marginLeft: "10px",
            }}
          >
            {t("main.mesaj.adminden")}
          </span>
        }
        footer={
          <span
            style={{
              color: "#1890FF",
              fontSize: "15px",
              marginLeft: "10px",
            }}
          >
            {t("main.mesaj.adetMesaj", { adet: mesajlarim.length })}
          </span>
        }
        renderItem={(item, index) => (
          <List.Item
            style={{
              border: "1px solid #ddd",
              boxShadow: "1px 1px 5px #eee",
              padding: "10px",
              backgroundColor: "white",
              marginTop: index < 2 ? "10px" : 0,
              marginRight: "10px",
            }}
            actions={[
              <Button
                onClick={() =>
                  yanitGonder(item.mesaj.fromStudentId, item.mesaj.title)
                }
                size="small"
                key="list-loadmore-more"
                type="link"
                disabled={myRole === "Student"}
              >
                <MessageOutlined /> {t("main.mesaj.cevapla")}
              </Button>,
              <Popconfirm
                title={
                  <>
                    <SYP>{t("main.mesaj.mesajSil")}</SYP>
                    <SYP color="red">{t("global.silEminmisin")}</SYP>
                  </>
                }
                onConfirm={() => hideMessage(item.mesaj.id)}
                okText={t("global.evet")}
                cancelText={t("global.iptal")}
              >
                <Button
                  type="link"
                  danger
                  size="small"
                  icon={<DeleteOutlined />}
                >
                  {t("global.sil")}
                </Button>
              </Popconfirm>,
            ]}
          >
            <Badge.Ribbon
              className="badgeribbon"
              style={{ visibility: item.mesaj.read ? "hidden" : "visible" }}
              text={t("main.mesaj.yeni")}
              placement="end"
            >
              <List.Item.Meta
                avatar={
                  <Avatar
                    style={{ marginTop: "9px" }}
                    src={require("../../../assets/images/user.png")}
                  />
                }
                title={<span>{item.gonderen}</span>}
                description={
                  <small>
                    {format(
                      new Date(item.mesaj.createdOn),
                      "DD MMM, ddd, HH:mm"
                    )}
                  </small>
                }
              />
            </Badge.Ribbon>
            <p style={{ marginTop: "-10px", marginBottom: 0 }}>
              {item.mesaj.title}
            </p>
            <p style={{ color: "#555" }}>{item.mesaj.message}</p>
          </List.Item>
        )}
      />
    </div>
  );
}

export default FromAdminMessages;
