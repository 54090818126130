import { Descriptions, Tag } from "antd";
import React, { useEffect } from "react";
import { useState } from "react";
import { giveLessonType } from "../../../resources/getLessonType";
import { SYDivider, SYSpan } from "../../../resources/styledComponents";
import { StaffService } from "../../../services/StaffService";
import TeacherTasks from "./TeacherTasks";

function TeacherReport({ id }) {
  const [initialRender, setInitialRender] = useState(true);
  const [stats, setStats] = useState(null);
  const [tasks, setTasks] = useState([]);
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      StaffService.getTeacherStats(id).then((data) => {
        console.log(data.data);
        setStats(data.data.stats);
        setTasks(data.data.tasks);
      });
    }
  }, [initialRender, id]);
  return (
    <div>
      <SYDivider renk="mavi">Öğretmen Raporu</SYDivider>
      {stats && (
        <>
          <Descriptions
            className="teacher-stats"
            size="small"
            bordered
            column={{ xxl: 4, xl: 4, lg: 4, md: 1, sm: 1, xs: 1 }}
          >
            <Descriptions.Item label="Ders Sayısı">
              {stats.lessonTasks.length}
            </Descriptions.Item>
            <Descriptions.Item label="Gerçekleşen Ders">
              {stats.lessonReports}
            </Descriptions.Item>
            <Descriptions.Item label="Verdiği Puan Ort.">
              {stats.giveStar !== null ? stats.giveStar : "-"}
            </Descriptions.Item>
            <Descriptions.Item label="Aldığı Puan Ort.">
              {stats.takeStar !== null ? stats.takeStar : "-"}
            </Descriptions.Item>
            {tasks && tasks.length > 0 && (
              <Descriptions.Item label="Ders Türleri" span={3}>
                {tasks.map((task) => (
                  <Tag key={task.key} color="purple">
                    {giveLessonType[task.key]}: <b>{task.sayi}</b>
                  </Tag>
                ))}
              </Descriptions.Item>
            )}
            <Descriptions.Item label="Toplam Bit">
              <SYSpan color="tomato" fontSize="18px">
                {stats.bitTotal}
              </SYSpan>
            </Descriptions.Item>
            <Descriptions.Item label="Bekleyen Talep">
              {stats.lessonReqs}
            </Descriptions.Item>

            <Descriptions.Item label="Randevu Sayısı">
              {stats.appos}
            </Descriptions.Item>
            <Descriptions.Item label="Açık Randevu">
              {stats.schedules}
            </Descriptions.Item>
            <Descriptions.Item label="Tanımladığı Sınav">
              {stats.exams}
            </Descriptions.Item>
            <Descriptions.Item label="Cevapladığı soru">
              {stats.answers}
            </Descriptions.Item>
            <Descriptions.Item label="Yazdığı Mesaj">
              {stats.fromMessages}
            </Descriptions.Item>
            <Descriptions.Item label="Aldığı Mesaj">
              {stats.toMessages}
            </Descriptions.Item>
            <Descriptions.Item label="Zoom Ayarı">
              {stats.zoom ? (
                <span style={{ color: "green" }}>Yapıldı</span>
              ) : (
                <span style={{ color: "red" }}>Yapılmadı</span>
              )}
            </Descriptions.Item>
          </Descriptions>

          <br />
          <TeacherTasks tasks={stats.lessonTasks} sir={setInitialRender} />
        </>
      )}
    </div>
  );
}

export default TeacherReport;
