import React, { useEffect, useState } from "react";
import { Table, Badge, Tag, Rate } from "antd";
import { StaffService } from "../../../services/StaffService";
import moment from "moment";
import { giveLessonType } from "../../../resources/getLessonType";

function LessonReports() {
  const [initialRender, setInitialRender] = useState(true);
  const [dataSource, setDataSource] = useState([]);
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      StaffService.getLessonReports().then((data) => {
        setDataSource(data.data);
      });
    }
  }, [initialRender]);

  const expandedRowRender = (val) => {
    const columns = [
      { title: "Alan Öğrenciler", dataIndex: "name", key: "name" },
      {
        title: "Mesaj",
        dataIndex: "report",
        key: "mesaj",
        render: (report) => report && <span>{report.message}</span>,
      },
      {
        title: "Yıldız",
        dataIndex: "report",
        key: "kalite",
        render: (report) =>
          report && (
            <Rate
              style={{
                fontSize: "14px",
                color:
                  report.star < 3
                    ? "tomato"
                    : report.star > 3
                    ? "#34A856"
                    : null,
              }}
              defaultValue={report.star}
              disabled
            />
          ),
      },
      {
        title: "Yapıldı",
        dataIndex: "report",
        key: "isok",
        render: (report, r) =>
          report && (
            <span>
              {report.isCompleted ? <span>Evet</span> : <span>Hayır</span>}
            </span>
          ),
      },
      {
        title: "Tarih",
        dataIndex: "report",
        key: "tarih",
        render: (report) =>
          report && (
            <Tag>
              {moment(report.createdOn).local().format("DD MMM , ddd HH:mm")}
            </Tag>
          ),
      },
    ];

    return (
      <Table
        className="reporttable"
        size="small"
        bordered
        columns={columns}
        dataSource={val.report.students}
        pagination={false}
      />
    );
  };

  const columns = [
    {
      title: "Öğretmen",
      dataIndex: "report",
      key: "ogretmen",
      fixed: "left",
      render: (report) => <span>{report.teacher}</span>,
    },
    {
      title: "Ders Türü",
      dataIndex: "report",
      width: 145,
      key: "tur",
      render: (report) => <span>{giveLessonType[report.type]}</span>,
    },
    {
      title: "Ders",
      dataIndex: "report",
      key: "ders",
      render: (report) => <span>{report.lesson}</span>,
    },
    {
      title: "Saatler",
      dataIndex: "report",
      width: 130,
      key: "saat",
      render: (report) =>
        report.appos.map((appo) => (
          <Tag key={appo.dateTime}>
            {moment(appo.dateTime).local().format("DD MMM , ddd HH:mm")}
          </Tag>
        )),
    },
    {
      title: "Mesaj",
      dataIndex: "report",
      key: "mesaj",
      render: (report) => <span>{report.teacherReport.message}</span>,
    },
    {
      title: "Yıldız",
      dataIndex: "report",
      key: "kalite",
      width: 125,
      render: (report) => (
        <Rate
          style={{
            fontSize: "14px",
            color:
              report.teacherReport.star < 3
                ? "tomato"
                : report.teacherReport.star > 3
                ? "#34A856"
                : null,
          }}
          defaultValue={report.teacherReport.star}
          disabled
        />
      ),
    },
    {
      title: "Bit",
      dataIndex: "report",
      width: 50,
      key: "bit",
      render: (report) => <span>{report.teacherReport.bitValue}</span>,
    },
    {
      title: "Yapıldı",
      dataIndex: "report",
      width: 65,
      key: "isok",
      render: (report, r) => (
        <span>
          {report.teacherReport.isCompleted ? (
            <Badge status={r.check ? "success" : "warning"}>
              <span>Evet</span>
            </Badge>
          ) : (
            <Badge status={r.check ? "success" : "warning"}>
              <span>Hayır</span>
            </Badge>
          )}
        </span>
      ),
    },
    {
      title: "Tarih",
      dataIndex: "report",
      key: "tarih",
      width: 125,
      render: (report) => (
        <Tag>
          {moment(report.teacherReport.createdOn)
            .local()
            .format("DD MMM , ddd HH:mm")}
        </Tag>
      ),
    },
  ];

  return (
    <div className="reportdiv">
      <Table
        size="small"
        bordered
        className="components-table-demo-nested reporttable"
        columns={columns}
        expandable={{ expandedRowRender }}
        dataSource={dataSource}
        scroll={{ x: 1200 }}
      />
    </div>
  );
}

export default LessonReports;
