import { Button } from "antd";
import React, { useContext, useEffect, useState } from "react";
import TeacherNewExam from "./TeacherNewExam";
import TeacherExamsList from "./TeacherExamsList";
import { GlobalContext } from "../../../context/GlobalContext";
import { useTranslation } from "react-i18next";

function TeacherExams() {
  const { openModal, setOpenModal } = useContext(GlobalContext);
  const [initialRender, setInitialRender] = useState(true);
  const { t } = useTranslation();
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      setOpenModal(false);
    }
  }, [initialRender, setOpenModal]);
  return (
    <div>
      <Button type="primary" onClick={() => setOpenModal(!openModal)}>
        {!openModal ? t("main.exam.yeniSinav") : t("main.exam.kayitliSinavlar")}
      </Button>
      <br />
      <br />
      {openModal && <TeacherNewExam />}
      {!openModal && <TeacherExamsList />}
      <Button type="primary" onClick={() => setOpenModal(!openModal)}>
        {!openModal ? t("main.exam.yeniSinav") : t("main.exam.sinavlaraDon")}
      </Button>
    </div>
  );
}

export default TeacherExams;
