import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  notification,
  Row,
  Space,
} from "antd";
import React from "react";
import { HomeService } from "../../services/HomeService";
import { Trans, useTranslation } from "react-i18next";

function IletisimTalep() {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const onFinish = (values) => {
    HomeService.newContactReq(values).then((data) => {
      form.resetFields();
      notification.success({
        message: t("contact.tesekkur"),
        description: t("contact.talepIletildiMessage"),
      });
    });
  };
  const onReset = () => {
    form.resetFields();
  };
  return (
    <div>
      <div
        className="container contact-bg"
        style={{
          backgroundColor: "#fafafa",
          boxShadow: "1px 1px 10px lightgrey",
          marginBottom: "50px",
        }}
      >
        <Row gutter={[16, 16]} align="middle">
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            style={{
              color: "#555555",
              textAlign: "right",
              paddingRight: "30px",
              borderRight: "1px solid #ddd",
              boxShadow: "15px 0px 10px -13px #ccc",
            }}
          >
            <div>
              <h2>
                <Trans i18nKey="contact.bilgi">
                  İletişim bilgilerinizi bırakın,
                  <br /> sizinle hemen irtibata geçelim
                  <br />
                  merak ettiklerinizi cevaplayalım,
                  <br />
                  son gelişmelerden haberdar olun.
                </Trans>
              </h2>
            </div>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Divider style={{ color: "#1890FF" }}>İletişim Talep Formu</Divider>
            <Form
              form={form}
              onFinish={onFinish}
              style={{ paddingLeft: "30px", paddingRight: "20px" }}
            >
              <Form.Item
                name="fullname"
                rules={[
                  { required: true, message: t("forms.messages.gerekli") },
                ]}
              >
                <Input placeholder={t("contact.adinizSoyadiniz")} />
              </Form.Item>
              <Form.Item
                name="phone"
                rules={[
                  { required: true, message: t("forms.messages.gerekli") },
                ]}
              >
                <Input placeholder={t("contact.telefonNumaraniz")} />
              </Form.Item>
              <Form.Item
                name="email"
                rules={[
                  { required: true, message: t("forms.messages.gerekli") },
                ]}
              >
                <Input placeholder={t("contact.emailAdresiniz")} />
              </Form.Item>
              <Form.Item
                name="subject"
                rules={[
                  { required: true, message: t("forms.messages.gerekli") },
                ]}
              >
                <Input placeholder={t("contact.neBilgisi")} />
              </Form.Item>
              <Form.Item>
                <Space>
                  <Button type="primary" htmlType="submit">
                    {t("forms.gonder")}
                  </Button>
                  <Button ghost type="primary" onClick={onReset}>
                    {t("forms.temizle")}
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default IletisimTalep;
