import { Table } from "antd";
import React from "react";
import { SYDivider } from "../../../resources/styledComponents";
import moment from "moment";

function StudentCardChanges({ cards }) {
  const columns = [
    {
      title: "Kart No",
      dataIndex: "cardNo",
      render: (cardNo) => (
        <span style={{ fontSize: "16px", fontFamily: "monospace" }}>
          {cardNo}
        </span>
      ),
    },
    {
      title: "Bit Değeri",
      dataIndex: "val",
    },
    {
      title: "Yükleme Tarihi",
      dataIndex: "usedDate",
      render: (usedDate) => (
        <span>{moment(usedDate).local().format("DD MMM YYYY, ddd HH:mm")}</span>
      ),
    },
  ];
  return (
    <div>
      <SYDivider renk="mavi">Kart Yüklemeleri</SYDivider>
      <Table dataSource={cards} size="small" bordered columns={columns} />
    </div>
  );
}

export default StudentCardChanges;
