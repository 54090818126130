import { axios } from "../resources/httpClient";

export const HomeService = {
  yeniKayitsizOzelDers: (values) => {
    return axios.post("/lessonreq", { ...values });
  },
  getUpcomingLessons: (sayi) => {
    return axios.get("/lessontask/get/upcoming/" + sayi);
  },
  newContactReq: (values) => {
    return axios.post("/contactreq", { ...values });
  },
  getCompanyData: () => {
    return axios.get("/company/data");
  },
  getAboutUs: () => {
    return axios.get("/aboutus");
  },
};
