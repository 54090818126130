import {
  Button,
  Card,
  Collapse,
  Row,
  Col,
  Result,
  Image,
  Select,
  Popconfirm,
  notification,
  Avatar,
  Space,
  Divider,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { Paperclip } from "react-feather";
import { MainService } from "../../../services/MainService";
import { StaffService } from "../../../services/StaffService";
import AnswerQuestionForm from "./AnswerQuestionForm";
import { UserContext } from "../../../context/UserContext";
import { CheckOutlined, DeleteOutlined } from "@ant-design/icons";
import moment from "moment";
import { useTranslation } from "react-i18next";

function AnswerQuestion() {
  const [initialRender, setInitialRender] = useState(true);
  const [questions, setQuestions] = useState([]);
  const [filteredQuestions, setFilteredQuestions] = useState([]);
  const [lessons, setLessons] = useState([]);
  const [selValue, setSelValue] = useState(-4);
  const [loading, setLoading] = useState(true);
  const { myId } = useContext(UserContext);
  const { t } = useTranslation();
  useEffect(() => {
    setLoading(true);
    if (initialRender) {
      setInitialRender(false);
      MainService.getSorularByCevapsiz().then((data) => {
        setQuestions(data.data);
        setFilteredQuestions(data.data);
        setLoading(false);
      });
      StaffService.getLessons().then((data) => {
        setLessons(data.data);
      });
    }
  }, [initialRender]);

  const kategoriDegisti = (kat) => {
    setSelValue(kat);
    if (kat === undefined || kat === -4) {
      setFilteredQuestions(questions);
    } else if (kat === -3) {
      setFilteredQuestions(
        questions.filter((q) => q.sorular.toTeacherId === parseInt(myId))
      );
    } else {
      setFilteredQuestions(questions.filter((q) => q.sorular.kategori === kat));
    }
  };

  const deleteQuestion = (id) => {
    MainService.DeleteQuestion(id).then((data) => {
      notification.success({
        message: t("global.basarili"),
        description: t("main.soru.silindi"),
      });
      setInitialRender(true);
    });
  };
  return (
    <div>
      <div>
        <span>{t("main.soru.filtrele")}: </span>
        <Select
          value={selValue}
          menuItemSelectedIcon={<CheckOutlined />}
          onChange={kategoriDegisti}
          placeholder={t("main.soru.kategoriFiltre")}
          allowClear
          style={{ minWidth: "200px" }}
        >
          <Select.Option key="tum" value={-4}>
            {t("main.soru.tum")}
          </Select.Option>
          <Select.Option key="bos" value={-3}>
            <span style={{ color: "tomato" }}>{t("main.soru.bana")}</span>
          </Select.Option>
          <Select.Option key="gbt" value={-1}>
            {t("main.soru.genelBilgi")}
          </Select.Option>
          <Select.Option key="rs" value={-2}>
            {t("main.soru.rehberlik")}
          </Select.Option>
          {lessons.map((lesson) => (
            <Select.Option key={lesson.id} value={lesson.id}>
              {t("main.soru.dersSorusu", { ders: lesson.name })}
            </Select.Option>
          ))}
        </Select>
      </div>
      <br />
      <br />
      <Row>
        {filteredQuestions.length === 0 && (
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Result
              status={loading ? "info" : "404"}
              title={loading ? t("global.yukleniyor") : "Ooops!"}
              subTitle={loading ? null : t("main.soru.soruYok")}
              extra={
                !loading && (
                  <Button type="primary" onClick={() => kategoriDegisti(-4)}>
                    {t("main.soru.filtreyiSifirla")}
                  </Button>
                )
              }
            />
          </div>
        )}
        {filteredQuestions.map((q) => (
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={12}
            xxl={12}
            key={q.sorular.id}
          >
            <Card
              key={q.sorular.id}
              type="inner"
              size="small"
              extra={
                <Popconfirm
                  title={t("global.silEminmisin")}
                  onConfirm={() => deleteQuestion(q.sorular.id)}
                  okText={t("global.evet")}
                  cancelText={t("global.iptal")}
                >
                  <Button icon={<DeleteOutlined />} type="default" danger />
                </Popconfirm>
              }
              style={{ margin: "0 20px 20px 0", borderColor: "#ddd" }}
              title={
                <span style={{ color: "#1890FF" }}>
                  {q.dersler === null
                    ? q.sorular.kategori === -1
                      ? t("main.soru.genelBilgi")
                      : t("main.soru.rehberlik")
                    : t("main.soru.dersSorusu", q.dersler.name)}
                </span>
              }
            >
              <Space
                align="top"
                style={{
                  width: "100%",
                  borderBottom: "1px solid #eee",
                  marginBottom: "10px",
                }}
              >
                <Avatar
                  style={{ marginTop: "6px" }}
                  src={require("../../../assets/images/user.png")}
                />
                <div>
                  <span style={{ color: "#34A856" }}>{q.soran}</span>
                  <Divider type="vertical" />
                  <span>Sınıf: {q.sinif.name}</span>
                  <br />
                  <span style={{ color: "grey", fontSize: "12px" }}>
                    {moment(q.sorular.createdOn)
                      .local()
                      .format("DD MMM , ddd HH:mm")}
                  </span>
                </div>
              </Space>

              <p>{q.sorular.soru}</p>
              {q.sorular.soruUrl !== null ? (
                q.sorular.soruUrl.split(".").pop() === "pdf" ? (
                  <a
                    href={
                      process.env.REACT_APP_FILES_BASEURL + q.sorular.soruUrl
                    }
                    target="_blanc"
                  >
                    <Paperclip size={13} />
                    <span style={{ fontSize: "12px" }}>
                      {" "}
                      {q.sorular.soruUrl.split("-").pop()}
                    </span>
                  </a>
                ) : (
                  <Image
                    style={{
                      maxWidth: "100px",
                      maxHeight: "100px",
                      border: "3px solid white",
                      boxShadow: "1px 1px 10px #bbb",
                    }}
                    src={
                      process.env.REACT_APP_FILES_BASEURL + q.sorular.soruUrl
                    }
                    alt=""
                  />
                )
              ) : null}
              <hr style={{ border: "none", borderTop: "1px solid #eee" }} />
              <Collapse
                style={{ backgroundColor: "white", borderColor: "white" }}
              >
                <Collapse.Panel
                  header={
                    // q.sorular.cevap !== null ? (
                    //   <span
                    //     style={{ color: "forestgreen" }}
                    //     onClick={() => buttonClicked(q.sorular.id)}
                    //   >
                    //     {buttons[q.sorular.id]
                    //       ? "Cevabı Gizle"
                    //       : "Cevabı Göster"}
                    //   </span>
                    // ) : (
                    <span style={{ color: "#1890FF" }}>
                      {t("main.soru.cevapla")}
                    </span>
                    //   )
                  }
                  key="1"
                  style={{ backgroundColor: "white", borderColor: "white" }}
                >
                  {q.sorular.cevap !== null ? (
                    q.sorular.cevapUrl !== null ? (
                      <>
                        <p>{q.sorular.cevap}</p>
                        {q.sorular.cevapUrl.split(".").pop() === "pdf" ? (
                          <a
                            href={
                              process.env.REACT_APP_FILES_BASEURL +
                              q.sorular.cevapUrl
                            }
                            target="_blanc"
                          >
                            <Paperclip size={13} />
                            <span style={{ fontSize: "12px" }}>
                              {" "}
                              {q.sorular.cevapUrl.split("-").pop()}
                            </span>
                          </a>
                        ) : (
                          <Image
                            style={{
                              maxWidth: "100px",
                              maxHeight: "100px",
                              border: "3px solid white",
                              boxShadow: "1px 1px 10px #bbb",
                            }}
                            src={
                              process.env.REACT_APP_FILES_BASEURL +
                              q.sorular.cevapUrl
                            }
                            alt=""
                          />
                        )}
                      </>
                    ) : null
                  ) : (
                    <AnswerQuestionForm
                      tid={q.sorular.toTeacherId}
                      id={q.sorular.id}
                      sir={setInitialRender}
                    />
                  )}
                </Collapse.Panel>
              </Collapse>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
}

export default AnswerQuestion;
