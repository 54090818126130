import React, { useState, useContext, useEffect } from "react";
import { MainService } from "../../../services/MainService";
import { Button, Popconfirm, Table } from "antd";
import { UserContext } from "../../../context/UserContext";
import moment from "moment";
import { DeleteOutlined } from "@ant-design/icons";
import { SYP, SYSpan } from "../../../resources/styledComponents";
import { SYColors } from "../../../resources/SYColors";
import { useTranslation } from "react-i18next";

function SentMessages() {
  const [initialRender, setInitialRender] = useState(true);
  const [sentMessages, setSentMessages] = useState([]);
  const { myId, myRole } = useContext(UserContext);
  const { t } = useTranslation();
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      MainService.getMySentMessages(myId, myRole === "Student" ? 1 : 2).then(
        (data) => {
          setSentMessages(data.data);
        }
      );
    }
  }, [initialRender, myId, myRole]);

  const hideMessage = (id) => {
    MainService.deleteMessage(id, "from").then((data) => {
      setInitialRender(true);
    });
  };

  const columns = [
    {
      title: t("main.mesaj.kime"),
      dataIndex: "giden",
      render: (giden) => <span>{giden}</span>,
    },
    {
      title: t("main.mesaj.baslikMesaj"),
      dataIndex: "mesaj",
      render: (mesaj) => (
        <>
          <span>{mesaj.title}</span>
          <br />
          <span>{mesaj.message}</span>
        </>
      ),
    },
    {
      title: t("main.mesaj.gonderildi"),
      dataIndex: "mesaj",
      width: 125,
      render: (mesaj) => (
        <span>
          {moment(mesaj.createdOn).local().format("DD MMM , ddd HH:mm")}
        </span>
      ),
    },
    {
      title: t("main.mesaj.okundu"),
      dataIndex: "mesaj",
      width: 125,
      render: (mesaj) => (
        <span>
          {mesaj.read ? (
            moment(mesaj.readDate).local().format("DD MMM , ddd HH:mm")
          ) : (
            <SYSpan color={SYColors.red}>{t("main.mesaj.okunmadi")}</SYSpan>
          )}
        </span>
      ),
    },
    {
      title: t("main.mesaj.gizle"),
      key: "action",
      width: 42,
      render: (text, record) => (
        <Popconfirm
          title={
            <>
              <SYP>{t("main.mesaj.mesajSil")}</SYP>
              <SYP color="red">{t("global.silEminmisin")}</SYP>
            </>
          }
          onConfirm={() => hideMessage(record.mesaj.id)}
          okText={t("global.evet")}
          cancelText={t("global.iptal")}
        >
          <Button type="ghost" danger size="small" icon={<DeleteOutlined />} />
        </Popconfirm>
      ),
    },
  ];
  return (
    <div>
      <Table
        bordered
        scroll={{ x: 780 }}
        size="small"
        dataSource={sentMessages}
        columns={columns}
      ></Table>
    </div>
  );
}

export default SentMessages;
