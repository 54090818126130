import {
  Button,
  Divider,
  Form,
  Input,
  Modal,
  notification,
  Space,
  Upload,
} from "antd";
import React, { useState } from "react";
import { SYBox, SYDivider } from "../../resources/styledComponents";
import { AdminService } from "../../services/AdminService";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
function Company() {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  const uploadButton = (
    <div>
      <PlusOutlined style={{ fontSize: "26px" }} />
      <div className="ant-upload-text">Foto Yükle</div>
    </div>
  );

  const handleChangeImage = ({ file, fileList }) => {
    setFileList(fileList);
  };

  const handleCancel = () => setPreviewVisible(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };
  const onFinish = (values) => {
    console.log(values);
    values = { ...values, logo: fileList[0].response.url };
    AdminService.newCompany(values).then((data) => {
      notification.success({
        message: "Başarılı",
        description: "Kurum başarı ile eklendi.",
      });
    });
  };
  const onReset = () => {
    form.resetFields();
  };
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };
  const formItemLayoutWithOutLabel = {
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 16, offset: 8 },
    },
  };
  return (
    <SYBox
      maxWidth="800px"
      bg="#f5f5f5"
      border="1px solid #ddd"
      boxShadow="0 0 5px #eee"
      p={20}
    >
      <SYDivider renk="mavi">Yeni Kurum Ekleme Formu</SYDivider>
      <Form form={form} onFinish={onFinish} onReset={onReset} {...layout}>
        <Form.Item
          label="Kurum Adı"
          name="name"
          rules={[{ required: true, message: "Kurum adı girmelisiniz" }]}
        >
          <Input placeholder="Lütfen kurum adını yazınız" />
        </Form.Item>
        <Form.Item
          label="Kurum Şirket Adı"
          name="formalName"
          rules={[{ required: true, message: "Kurum şirket adı girmelisiniz" }]}
        >
          <Input placeholder="Lütfen kurum şirket adını yazınız" />
        </Form.Item>
        <Form.Item label="Site Logosu">
          <div className="clearfix">
            <Upload
              action={process.env.REACT_APP_API_BASEURL + "upload"}
              headers={{
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              }}
              listType="picture-card"
              fileList={fileList}
              onChange={handleChangeImage}
              onPreview={handlePreview}
            >
              {fileList.length >= 1 ? null : uploadButton}
            </Upload>
            <Modal
              visible={previewVisible}
              title={previewTitle}
              footer={null}
              onCancel={handleCancel}
            >
              <img alt="example" style={{ width: "100%" }} src={previewImage} />
            </Modal>
          </div>
          <small>Fotoğraf boyutu 550px x 160px ölçülerinde olmalıdır</small>
        </Form.Item>
        <Form.Item
          label="1.Telefon"
          name="phone1"
          rules={[{ required: true, message: "Telefon girmelisiniz" }]}
        >
          <Input placeholder="Lütfen telefon numarasını yazınız" />
        </Form.Item>
        <Form.Item
          label="2.Telefon"
          name="phone2"
          rules={[{ required: true, message: "Telefon girmelisiniz" }]}
        >
          <Input placeholder="Lütfen telefon numarasını yazınız" />
        </Form.Item>

        <Form.Item
          label="Adres satırı - 1"
          name="address1"
          rules={[{ required: true, message: "Kurum adresi girmelisiniz" }]}
        >
          <Input placeholder="Lütfen kurum adresi yazın" />
        </Form.Item>
        <Form.Item label="Adres satırı - 2" name="address2">
          <Input placeholder="Lütfen kurum adresi yazın" />
        </Form.Item>
        <Form.Item label="Facebook Adresi" name="facebook">
          <Input placeholder="Lütfen kurum facebook adresini yazınız" />
        </Form.Item>
        <Form.Item label="Twitter Adresi" name="twitter">
          <Input placeholder="Lütfen kurum twitter adresini yazınız" />
        </Form.Item>
        <Form.Item label="Instagram" name="instagram">
          <Input placeholder="Lütfen kurum instagram adresini yazınız" />
        </Form.Item>
        <Divider>Kurum Site Adresleri</Divider>
        <Form.List
          name="domains"
          rules={[
            {
              validator: async (_, domains) => {
                if (!domains || domains.length < 2) {
                  return Promise.reject(
                    new Error("En az iki adres girilmeli (www li/siz)")
                  );
                }
              },
            },
          ]}
        >
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map((field, index) => (
                <Form.Item
                  {...(index === 0
                    ? formItemLayout
                    : formItemLayoutWithOutLabel)}
                  label={index === 0 ? "Site Adresi" : ""}
                  required={false}
                  key={field.key}
                >
                  <Form.Item
                    {...field}
                    validateTrigger={["onChange", "onBlur"]}
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: "Adres yazın yada bu satırı silin",
                      },
                    ]}
                    noStyle
                  >
                    <Input
                      placeholder="http://www.hypotenuse.com"
                      style={{ width: "70%" }}
                    />
                  </Form.Item>
                  {fields.length > 1 ? (
                    <MinusCircleOutlined
                      className="dynamic-delete-button"
                      onClick={() => remove(field.name)}
                    />
                  ) : null}
                </Form.Item>
              ))}
              <Form.Item {...tailLayout}>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  style={{ width: "60%" }}
                  icon={<PlusOutlined />}
                >
                  Domain Adresi Ekle
                </Button>

                <Form.ErrorList errors={errors} />
              </Form.Item>
            </>
          )}
        </Form.List>
        <Divider>Kurum Site Yöneticisi (Admin)</Divider>
        <Form.Item
          label="Admin e-posta adresi"
          name="email"
          rules={[{ required: true, message: "e-posta girmelisiniz" }]}
        >
          <Input placeholder="Lütfen yönetici e-posta adresini yazınız" />
        </Form.Item>
        <Form.Item
          label="Admin Adı Soyadı"
          name="fullname"
          rules={[
            { required: true, message: "Admin adını soyadını girmelisiniz" },
          ]}
        >
          <Input placeholder="Lütfen yönetici ad soyadını yazınız" />
        </Form.Item>
        <Form.Item
          label="Admin Cep Tel"
          name="gsm"
          rules={[{ required: true, message: "Admin cep tel girmelisiniz" }]}
        >
          <Input placeholder="Lütfen yönetici cep telefonunu yazınız" />
        </Form.Item>
        <Form.Item
          label="Admin Şifresi"
          name="password"
          rules={[
            {
              required: true,
              message: "Şifre girmelisiniz!",
            },
            {
              pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,25}$/,
              message:
                "Şifreniz 6-25 haneli olmalı , harf ve numara içermelidir",
            },
          ]}
          hasFeedback
        >
          <Input.Password placeholder="Yeni Şifreniz" />
        </Form.Item>

        <Form.Item
          label="Admin şifresi tekrar"
          name="confirm"
          dependencies={["password"]}
          hasFeedback
          rules={[
            {
              required: true,
              message: "Şifreyi doğrulamalısınız!",
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject("Şifreleriniz eşleşmiyor!");
              },
            }),
          ]}
        >
          <Input.Password placeholder="Yeni şifreniz tekrar" />
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Space>
            <Button htmlType="reset">Temizle</Button>
            <Button type="primary" htmlType="submit">
              Kaydet
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </SYBox>
  );
}

export default Company;
