import {
  Button,
  Col,
  Divider,
  Form,
  Grid,
  Input,
  InputNumber,
  List,
  Modal,
  notification,
  Rate,
  Row,
  Select,
  Space,
} from "antd";
import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import { getLessonType } from "../../../resources/getLessonType";
import { StaffService } from "../../../services/StaffService";
import { getMyData } from "../../../resources/getUserData";
import { MainService } from "../../../services/MainService";
import { GlobalContext } from "../../../context/GlobalContext";
import { SYSpan } from "../../../resources/styledComponents";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

function OnlineOzelDers({ tip }) {
  const [form] = Form.useForm();
  const [lessons, setLessons] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [initialRender, setInitialRender] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [secilenler, setSecilenler] = useState([]);
  const [saatler, setSaatler] = useState([]);
  const {
    bitValue,
    setBitValue,
    desc,
    lessonTypes,
    lessonReqTimes,
  } = useContext(GlobalContext);
  const { t } = useTranslation();
  const router = useHistory();
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      StaffService.getLessons().then((data) => {
        setLessons(data.data.filter((l) => l.isActive === true));
      });
    }
  }, [initialRender]);
  const { useBreakpoint } = Grid;

  const layout = useBreakpoint().md
    ? {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
      }
    : null;
  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };
  const onFinish = (values) => {
    var secimler = [];
    var ucret = 0;
    secilenler.forEach((s) => {
      secimler.push(s.id);
      ucret = ucret + s.lessonPrices[`ucret${getLessonType.OnlineOzelDers}`];
    });
    values = {
      ...values,
      tip: tip,
      isteyenId: parseInt(getMyData.getMyId()),
      secilenler: secimler,
      zaman: "hemen",
      nerede: "online",
      ucret,
    };
    MainService.yeniLessonTask(values).then((data) => {
      window.scrollTo(0, 0);
      notification.success({
        message: t("global.basarili"),
        description: t("main.dersal.talepIletildi"),
      });
      setBitValue(bitValue - values.ucret);
      onReset();
    });
  };
  const onReset = () => {
    form.resetFields();
    setSecilenler([]);
  };
  const handleLessonChange = (value) => {
    StaffService.getTeachersbyLesson(value).then((data) => {
      setTeachers(data.data.filter((d) => d.teacher.tip1 === true));
    });
  };
  const modalHandleOk = () => {
    setModalVisible(false);
  };
  const modalHandleCancel = () => {
    setModalVisible(false);
  };
  const ogretmenChanged = (e, item, val) => {
    setSecilenler([]);

    StaffService.getSchedule(e).then((data) => {
      setSaatler(data.data);
      setModalVisible(true);
    });
  };
  const timeSelected = (item, index) => {
    if (secilenler.length + 1 === form.getFieldValue("saatSayisi")) {
      setModalVisible(false);
    }
    setSaatler(saatler.filter((s) => s.id !== item.id));
    setSecilenler([...secilenler, item]);
  };
  const secilenClicked = (item) => {
    setSecilenler(secilenler.filter((a) => a.id !== item.id));
    setSaatler([...saatler, item]);
  };
  return (
    <div>
      <Modal
        className="modaldiv"
        visible={modalVisible}
        title={
          <div style={{ width: "100%", wordWrap: "break-word" }}>
            {secilenler.length === 0 ? (
              t("main.dersal.saatleriSecin")
            ) : (
              <Row gutter={[10, 10]}>
                {secilenler.map((sc) => (
                  <Col key={sc.id}>
                    <Button
                      type="primary"
                      ghost
                      onClick={() => secilenClicked(sc)}
                    >
                      {" "}
                      {moment(sc.dateTime)
                        .local()
                        .format("DD MMM, ddd   HH:mm")}{" "}
                      ❌
                    </Button>
                  </Col>
                ))}
              </Row>
            )}
          </div>
        }
        onOk={modalHandleOk}
        onCancel={modalHandleCancel}
        width="95%"
      >
        <List
          grid={{
            gutter: 10,
            xs: 2,
            sm: 3,
            md: 4,
            lg: 5,
            xl: 6,
            xxl: 7,
          }}
          dataSource={saatler}
          renderItem={(item, index) => (
            <List.Item>
              <Button block onClick={() => timeSelected(item, index)}>
                {moment(item.dateTime).local().format("DD MMM, ddd   HH:mm")}
              </Button>
            </List.Item>
          )}
        />
      </Modal>
      <Divider style={{ color: "#1890FF" }}>
        {t("main.dersal.talepFormu", { ders: lessonTypes[tip - 1].value })}
      </Divider>
      <Form
        initialValues={{ rate: 3 }}
        style={{ maxWidth: "800px", marginBottom: "50px" }}
        form={form}
        {...layout}
        onFinish={onFinish}
        layout={useBreakpoint().md ? "horizontal" : "vertical"}
      >
        <Form.Item
          name="lessonsId"
          label={t("forms.dersseciniz")}
          rules={[{ required: true, message: t("forms.messages.gerekli") }]}
        >
          <Select
            onChange={handleLessonChange}
            placeholder={t("forms.placeholders.derssec")}
            allowClear
          >
            {lessons.map((lesson) => (
              <Select.Option key={lesson.id} value={lesson.id}>
                {lesson.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={t("forms.derssayisi")}
          name="saatSayisi"
          rules={[
            { required: true, message: t("forms.messages.gerekli") },
            {
              type: "number",
              min: 1,
              max: 100,
              message: t("forms.messages.saataralik"),
            },
          ]}
        >
          <InputNumber />
        </Form.Item>
        {teachers.length > 0 && (
          <>
            <Form.Item name="teacherId" label={t("forms.ogretmenSec")}>
              <Select
                placeholder={t("forms.placeholders.ogretmenSec")}
                onSelect={(e, item, val) => ogretmenChanged(e, item, val)}
                allowClear
              >
                {teachers.map((t) => (
                  <Select.Option key={t.teacher.id} value={t.teacher.id}>
                    {t.teacher.fullname}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            {secilenler.length > 0 && (
              <Form.Item label={t("main.dersal.secilenler")}>
                <Space>
                  {secilenler.map((secilen) => (
                    <Button type="primary" ghost key={secilen.id}>
                      {moment(secilen.dateTime)
                        .local()
                        .format("DD MMM, ddd   HH:mm")}
                    </Button>
                  ))}
                </Space>
              </Form.Item>
            )}
          </>
        )}
        <Form.Item
          label={t("forms.konu")}
          name="konu"
          rules={[{ required: true, message: t("forms.messages.gerekli") }]}
        >
          <Input placeholder={t("forms.placeholders.konugir")} />
        </Form.Item>

        <Form.Item name="rate" label={t("forms.derstemelbilgisi")}>
          <Rate tooltips={desc} />
        </Form.Item>
        {secilenler.length === 0 && (
          <Form.Item
            name="nezaman"
            label={t("forms.zaman")}
            rules={[{ required: true, message: t("forms.messages.gerekli") }]}
          >
            <Select placeholder={t("forms.placeholders.zaman")} allowClear>
              {lessonReqTimes.map((lt) => (
                <Select.Option key={lt.key} value={lt.key}>
                  {lt.value}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
        {secilenler.length > 0 && (
          <Form.Item label={t("home.bitmarket.ucret")}>
            {secilenler[0].lessonPrices.ucret1 * secilenler.length} Bit
            {secilenler[0].lessonPrices.ucret1 * secilenler.length >
            bitValue ? (
              <>
                <SYSpan color="red" mx={20}>
                  {t("main.dersal.bitYetersiz")}
                </SYSpan>
                <Button
                  type="primary"
                  size="small"
                  onClick={() => router.push("/main/bit")}
                >
                  {t("main.dersal.bital")}
                </Button>
              </>
            ) : (
              ""
            )}
          </Form.Item>
        )}
        <Form.Item {...tailLayout}>
          <Space>
            <Button
              type="primary"
              htmlType="submit"
              disabled={
                secilenler.length > 0 &&
                secilenler[0].lessonPrices.ucret1 * secilenler.length > bitValue
              }
            >
              {secilenler.length > 0
                ? t("main.dersal.odemeYap")
                : t("forms.talebimigonder")}
            </Button>
            <Button htmlType="button" onClick={onReset}>
              {t("forms.temizle")}
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
}

export default OnlineOzelDers;
