import React, { useContext, useState } from "react";
import "../../App.css";
import { Menu, Space, Button, Row, Col, Dropdown } from "antd";
import {
  MailOutlined,
  HomeOutlined,
  IdcardOutlined,
  DownOutlined,
  ShopOutlined,
  UserOutlined,
  SafetyOutlined,
  MessageOutlined,
  LockOutlined,
} from "@ant-design/icons";
import "./Home.css";
import { useTranslation } from "react-i18next";
import { useHistory, Link, Switch, Route, Redirect } from "react-router-dom";
import HomeFooter from "../../components/home/HomeFooter";
import HomePage from "./HomePage";
import AboutUs from "../../components/home/AboutUs";
import Contact from "../../components/home/Contact";
import { UserContext } from "../../context/UserContext";
import BitMarket from "../../components/main/Bit/BitMarket";
import MainTeacherDetails from "../../components/main/Teachers/MainTeacherDetails";
import { GlobalContext } from "../../context/GlobalContext";
import { BitIcon } from "../../resources/styledComponents";

function HomeLayout() {
  const { t, i18n } = useTranslation();
  const [current, setCurrent] = useState("mail");
  const { myRole, myFullname } = useContext(UserContext);
  const { bitValue, kurum } = useContext(GlobalContext);
  const router = useHistory();
  const handleClick = (e) => {
    setCurrent(e.key);
  };
  const loginTapped = () => {
    router.push("/login");
  };
  const languages = (
    <Menu>
      <Menu.Item onClick={() => i18n.changeLanguage("tr")}>
        <Space>
          <img
            src={require("../../assets/images/turkey.png")}
            alt="turkey"
            className="flag"
          />
          <span>Türkçe</span>
        </Space>
      </Menu.Item>
      <Menu.Item onClick={() => i18n.changeLanguage("en")}>
        <Space>
          <img
            src={require("../../assets/images/uk.png")}
            alt="english"
            className="flag"
          />
          <span>English</span>
        </Space>
      </Menu.Item>
      <Menu.Item onClick={() => i18n.changeLanguage("fl")}>
        <Space>
          <img
            src={require("../../assets/images/belgium.png")}
            alt="Nederlands"
            className="flag"
          />
          <span>Nederlands</span>
        </Space>
      </Menu.Item>
      <Menu.Item onClick={() => i18n.changeLanguage("tr")}>
        <Space>
          <img
            src={require("../../assets/images/germany.png")}
            alt="Deutsche"
            className="flag"
          />
          <span>Deutsche</span>
        </Space>
      </Menu.Item>
    </Menu>
  );
  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    router.push("/login");
  };
  const gostaff = () => {
    router.push("/staff");
  };
  const gomain = () => {
    router.push("/main");
  };
  const usermenu = (
    <Menu>
      <Menu.Item onClick={gomain}>
        <HomeOutlined /> {t("main.menu.anaSayfam")}
      </Menu.Item>
      {myRole === "Admin" && (
        <Menu.Item onClick={gostaff}>
          <SafetyOutlined /> {t("main.menu.adminSayfasi")}
        </Menu.Item>
      )}
      <Menu.Item onClick={() => router.push("/main/mymessages")}>
        <MessageOutlined /> {t("main.menu.mesajlarim")}
      </Menu.Item>
      <Menu.Item danger onClick={logout}>
        <LockOutlined /> {t("main.menu.cikis")}
      </Menu.Item>
    </Menu>
  );
  return (
    <div>
      <div className="logo-center">
        <Row justify="center" align="middle">
          <Col flex="200px">
            {kurum && (
              <img
                className="logo"
                src={process.env.REACT_APP_FILES_BASEURL + kurum.logo}
                alt="logo"
              />
            )}
          </Col>
          <Col flex="auto" style={{ textAlign: "right", marginRight: "20px" }}>
            <Space>
              <Space>
                {bitValue !== null && myRole === "Student" && (
                  <>
                    <BitIcon
                      width="44px"
                      height="44px"
                      style={{ marginTop: "10px" }}
                    />
                    <div>
                      <span
                        style={{
                          marginTop: "-25px",
                          fontSize: "20px",
                          fontWeight: "700",
                          color: "#DD6400",
                        }}
                      >
                        {bitValue.toString()}
                      </span>
                    </div>
                  </>
                )}
              </Space>
              <Dropdown trigger={["click"]} overlay={languages}>
                <Button
                  className="ant-dropdown-link"
                  onClick={(e) => e.preventDefault()}
                >
                  {t("global.dil")} <DownOutlined />
                </Button>
              </Dropdown>
              {!!myFullname ? (
                <Dropdown trigger={["click"]} overlay={usermenu}>
                  <span
                    style={{
                      cursor: "pointer",
                      color: "#1890FF",
                      border: "1px solid #ddd",
                      margin: 0,
                      padding: "8px",
                      borderRadius: "3px",
                      backgroundColor: "#ffffff",
                      boxShadow: "0 0 5px #eee",
                    }}
                  >
                    <UserOutlined /> {myFullname} <DownOutlined />
                  </span>
                </Dropdown>
              ) : (
                <Button type="primary" onClick={loginTapped}>
                  {t("global.giris")}
                </Button>
              )}
            </Space>
          </Col>
        </Row>
      </div>
      <Menu
        className="main-menu"
        onClick={handleClick}
        selectedKeys={[current]}
        mode="horizontal"
      >
        <Menu.Item key="home" icon={<HomeOutlined />}>
          <Link to="/home">{t("anamenu.anasayfa")}</Link>
        </Menu.Item>
        <Menu.Item key="bitmarket" icon={<ShopOutlined />}>
          <Link to="/home/bitmarket"> {t("anamenu.bitmarket")}</Link>
        </Menu.Item>
        <Menu.Item key="about" icon={<IdcardOutlined />}>
          <Link to="/home/aboutus">{t("anamenu.hakkimizda")}</Link>
        </Menu.Item>

        <Menu.Item key="contact" icon={<MailOutlined />}>
          <Link to="/home/contact"> {t("anamenu.iletisim")}</Link>
        </Menu.Item>
      </Menu>
      <Switch>
        <Route exact path="/home">
          <Redirect to="/home/homepage" />
        </Route>
        <Route path="/home/homepage" component={HomePage} />
        <Route path="/home/aboutus" component={AboutUs} />
        <Route path="/home/bitmarket" component={BitMarket} />
        <Route path="/home/contact" component={Contact} />
        <Route path={"/home/teachers/:id"}>
          <MainTeacherDetails />
        </Route>
        <Route>
          <Redirect to="/home" />
        </Route>
      </Switch>
      <HomeFooter />
    </div>
  );
}

export default HomeLayout;
