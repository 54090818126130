import React, { useContext, useCallback, useState } from "react";
import { Divider, Form, Input, Button, Select } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import ValidationService from "../../services/ValidationService";
import { useHistory, useLocation } from "react-router-dom";
import { getMyData } from "../../resources/getUserData";
import { UserContext } from "../../context/UserContext";
import { GlobalContext } from "../../context/GlobalContext";
import { useTranslation } from "react-i18next";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

function LoginForm() {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [bloading, setBloading] = useState(false);
  const router = useHistory();
  const { setUserValues } = useContext(UserContext);
  const { setBitValue, kurum } = useContext(GlobalContext);
  const location = useLocation();
  const { t } = useTranslation();

  const onFinish = (values) => {
    console.log(values);
    console.log(process.env.RECT_APP_reCaptchaKey);
    setBloading(true);
    const ctoken = executeRecaptcha("login_page");
    ctoken.then((datam) => {
      values = { ...values, ctoken: datam };
      console.log("giden:", values);

      ValidationService.checkLogin(values)
        .then((data) => {
          console.log("login:", data.data);
          localStorage.setItem("bitValue", data.data.user.bitValue);
          localStorage.setItem("token", data.data.token);
          localStorage.setItem("user", JSON.stringify(data.data.user));

          const userValues = {
            myId: getMyData.getMyId(),
            myEmail: getMyData.getMyEmail(),
            myRole: getMyData.getMyRole(),
            myFullname: getMyData.getMyFullname(),
          };
          setUserValues(userValues);
          location.state && location.state.redirect !== undefined
            ? router.push(location.state.redirect)
            : router.push("/main");
          setBitValue(data.data.user.bitValue);
        })
        .finally(() => {
          setBloading(false);
        });
    });
  };
  return (
    <div className="login-form-container">
      {kurum && (
        <img
          onClick={() => router.goBack()}
          className="login-logo"
          src={process.env.REACT_APP_FILES_BASEURL + kurum.logo}
          alt="logo"
          style={{ cursor: "pointer" }}
        />
      )}
      <Divider>{t("global.loginol")}</Divider>
      <Form
        name="normal_login"
        className="login-form"
        initialValues={{ iam: "ogrenci" }}
        onFinish={onFinish}
      >
        <Form.Item
          name="email"
          rules={[{ required: true, message: t("login.email") }]}
        >
          <Input
            autoComplete="nope"
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder={t("login.email")}
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: t("login.sifre") }]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder={t("login.sifre")}
          />
        </Form.Item>
        <Form.Item name="iam">
          <Select>
            <Select.Option key="ogrenci" value="ogrenci">
              {t("login.ogrenci")}
            </Select.Option>
            <Select.Option key="ogretmen" value="ogretmen">
              {t("login.ogretmen")}
            </Select.Option>
          </Select>
        </Form.Item>
        <Form.Item>
          <Button
            loading={bloading}
            block
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            {t("login.girisYap")}
          </Button>
        </Form.Item>
        <Form.Item style={{ marginTop: "-15px" }}>
          <span>{t("login.uyeDegilmisin")} </span>
          <Button
            onClick={() => router.push("/login/signuppage")}
            type="link"
            size="small"
          >
            {t("login.uyeOl")}
          </Button>
          <Divider type="vertical" />{" "}
          <Button
            onClick={() => router.push("/login/forgetpassword")}
            type="link"
            size="small"
          >
            {t("login.sifremiUnuttum")}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default LoginForm;
