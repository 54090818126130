import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../context/UserContext";
import { MainService } from "../../../services/MainService";
import { Table, Space, Button, Divider } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { useTranslation } from "react-i18next";

function StudentExams() {
  const { myId } = useContext(UserContext);
  const router = useHistory();
  const [exams, setExams] = useState([]);
  const [initialRender, setInitialRender] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);

      MainService.getStudentExams(myId).then((data) => {
        setExams(data.data);
      });
    }
  }, [initialRender, myId]);

  const goDetails = (veri) => {
    router.push("/main/exams/student/" + veri.exam.id, { veri });
  };
  const columns = [
    {
      title: t("main.exam.ders"),
      dataIndex: "lesson",
      render: (lesson) => <span>{lesson.name}</span>,
    },
    {
      title: t("main.exam.sinavAdi"),
      dataIndex: "exam",
      render: (exam) => <span>{exam.title}</span>,
    },
    {
      title: t("main.exam.detay"),
      dataIndex: "exam",
      render: (exam) => <span>{exam.description}</span>,
    },
    {
      title: t("forms.tarih"),
      dataIndex: "res",
      render: (res) => (
        <span>
          {moment(res.createdOn).local().format("DD MMM, ddd   HH:mm")}
        </span>
      ),
    },
    {
      title: "İşlem",
      dataIndex: "res",
      render: (res) => (
        <span>
          {" "}
          {res.isOk ? (
            <span style={{ color: "#34A856" }}>{t("main.exam.yapildi")}</span>
          ) : (
            <span style={{ color: "tomato" }}>{t("main.exam.bekleniyor")}</span>
          )}
        </span>
      ),
    },
    {
      title: t("main.exam.sonuc"),
      dataIndex: "res",
      render: (res) => (
        <span>
          {" "}
          {res.isOk ? (
            <span style={{ color: "#34A856" }}>
              {res.net.toFixed(2) + "/" + res.answers.length}
            </span>
          ) : (
            <span style={{ color: "tomato" }}>{"-/-"}</span>
          )}
        </span>
      ),
    },
    {
      title: "İncele",
      render: (text, record) => (
        <Space>
          <Button
            onClick={() => goDetails(record)}
            type="primary"
            shape="circle"
            icon={<SearchOutlined />}
          />
          {/* <Tooltip title="Düzenle">
            <Button type="primary" shape="circle" icon={<EditOutlined />} />
          </Tooltip> */}
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Divider>{t("main.exam.tanimliSinavlar")}</Divider>

      {exams && (
        <Table
          scroll={{ x: 1000 }}
          size="small"
          columns={columns}
          dataSource={exams}
          bordered
        />
      )}
    </div>
  );
}

export default StudentExams;
