import React, { useState, useEffect } from "react";
import { StaffService } from "../../services/StaffService";
import {
  List,
  Switch,
  Button,
  Divider,
  Form,
  Input,
  Space,
  notification,
  Row,
  Col,
} from "antd";

function Groups() {
  const [form] = Form.useForm();
  const [initialRender, setInitialRender] = useState(true);
  const [groups, setGroups] = useState([]);
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      StaffService.getGropus().then((data) => {
        setGroups(data.data);
      });
    }
  }, [initialRender]);
  const finish = (values) => {
    StaffService.newGropus(values).then((data) => {
      setGroups([...groups, data.data]);
      notification.success({
        message: "Başarılı",
        description: "Yeni grup eklendi!",
      });
      form.resetFields();
    });
  };
  const onReset = () => {
    form.resetFields();
  };
  const onChange = (index, item) => {
    StaffService.changeGroupActive(item.id, !item.isActive).then((data) => {
      notification.success({
        message: "Başarılı...",
        description: "İşlem başarı ile yapıldı...",
      });
    });
  };

  return (
    <div>
      <Divider>Eğitim Gruplarını Tanımlama ve Listeleme Ekranı</Divider>
      <Row gutter={[24, 24]}>
        <Col sm={24} xs={24} md={24} lg={12} xl={12}>
          <div style={{ backgroundColor: "white" }}>
            <List
              header={
                <div style={{ color: "#1890FF", fontSize: "16px" }}>
                  Kayıtlı Gruplar Listesi
                </div>
              }
              footer={
                <div style={{ color: "#1890FF" }}>
                  {groups.length} adet kayıtlı grubunuz bulunuyor
                </div>
              }
              bordered
              dataSource={groups}
              renderItem={(item, index) => (
                <List.Item>
                  {item.name}
                  <div style={{ float: "right" }}>
                    <Switch
                      defaultChecked={item.isActive}
                      onChange={() => onChange(index, item)}
                    />
                  </div>
                </List.Item>
              )}
            />
          </div>
        </Col>
        <Col sm={24} xs={24} md={24} lg={12} xl={12}>
          <div
            style={{
              border: "1px solid #ddd",
              padding: "20px",
              backgroundColor: "#fff",
            }}
          >
            <Divider>Yeni Grup Ekle</Divider>
            <Form form={form} onFinish={finish}>
              <Form.Item
                name="name"
                label="Grup Adı"
                rules={[{ required: true, message: "Grup Adı gereklidir" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item name="isActive" label="Aktif mi ?" initialValue={true}>
                <Switch defaultChecked={true}></Switch>
              </Form.Item>
              <Form.Item>
                <Space style={{ marginLeft: "80px" }}>
                  <Button type="primary" htmlType="submit">
                    Kaydet
                  </Button>
                  <Button htmlType="button" onClick={onReset}>
                    Sıfırla
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Groups;
