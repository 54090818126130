import {
  Button,
  Col,
  DatePicker,
  Descriptions,
  Form,
  InputNumber,
  notification,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
} from "antd";
import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  BitIcon,
  SYBox,
  SYCard,
  SYDivider,
} from "../../resources/styledComponents";
import locale from "antd/es/date-picker/locale/tr_TR";
import { StaffService } from "../../services/StaffService";
import { SYColors } from "../../resources/SYColors";

function GetBitCards() {
  const [form] = Form.useForm();
  const [cardlar, setCardlar] = useState([]);
  const [initialRender, setInitialRender] = useState(true);
  const [stats, setStats] = useState(null);
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      StaffService.getBitStats().then((data) => {
        setStats(data.data);
      });
    }
  }, [initialRender]);
  const onFinish = (values) => {
    setCardlar([]);
    values = {
      ...values,
      sonTarih: moment(values.sonTarih).format("YYYY-MM-DDTHH:mm:ss"),
    };
    if (values.sonuc === "excel") {
      StaffService.getBitCardsToExcel(values).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "BitCardList.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
        notification.success({
          message: "Başarılı",
          description:
            "Bit Kart Listeniz Başaılı şekilde oluşturuldu ve indirildi.",
        });
        setInitialRender(true);
      });
    } else {
      StaffService.getBitCardsToJson(values).then((data) => {
        setCardlar(data.data);
        notification.success({
          message: "Başarılı",
          description: "Bit Kart Listeniz Başaılı şekilde oluşturuldu.",
        });
        setInitialRender(true);
      });
    }
    form.resetFields();
  };
  const onReset = () => {
    form.resetFields();
    setCardlar([]);
  };
  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  };
  const tailLayout = {
    wrapperCol: { offset: 6, span: 18 },
  };
  const columns = [
    {
      title: "Kart no",
      dataIndex: "cardNo",
      render: (cardNo) => (
        <span style={{ fontFamily: "monospace", fontSize: "16px" }}>
          {cardNo}
        </span>
      ),
    },
    {
      title: "Bit",
      dataIndex: "val",
      render: (val) => <span>{val}</span>,
    },
    {
      title: "Son Tarih",
      dataIndex: "endDate",
      render: (endDate) => (
        <span>{moment(endDate).local().format("DD-MM-YYYY")}</span>
      ),
    },
  ];
  const deleteOutDates = () => {
    StaffService.deleteBitOutdates().then((data) => {
      notification.success({
        message: "Tarihi geçmiş kartlar Başarı ile silindi.",
      });
      setInitialRender(true);
    });
  };
  return (
    <Row gutter={16}>
      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
        <SYBox p={30} bg={SYColors.f5} border="1px solid #ccc" maxWidth="600px">
          <SYDivider renk="mavi">
            <Space>
              <BitIcon width="44px" height="44px" />
              <span>Bit Kart Oluşturma Ekranı</span>
            </Space>
          </SYDivider>
          <Form form={form} onFinish={onFinish} onReset={onReset} {...layout}>
            <Form.Item
              label="Kaç adet?"
              name="adet"
              rules={[
                { required: true, message: "Adet girmelisiniz" },
                {
                  type: "number",
                  min: 1,
                  max: 1000,
                  message: "Sayı 1-1000 aralığında olabilir",
                },
              ]}
            >
              <InputNumber />
            </Form.Item>

            <Form.Item
              label="Kaç bit?"
              name="sayi"
              rules={[
                { required: true, message: "Bit sayısı girmelisiniz" },
                {
                  type: "number",
                  min: 1,
                  max: 1000,
                  message: "Sayı 1-1000 aralığında olabilir",
                },
              ]}
            >
              <InputNumber />
            </Form.Item>
            <Form.Item
              label="Son yükleme Tarihi?"
              name="sonTarih"
              rules={[{ required: true, message: "Tarih girmelisiniz" }]}
            >
              <DatePicker locale={locale} format="DD-MM-YYYY" inputReadOnly />
            </Form.Item>
            <Form.Item
              label="Sonuç şekli"
              name="sonuc"
              rules={[{ required: true, message: "Liste türü seçmelisiniz" }]}
            >
              <Select placeholder="Lütfen seçiminizi yapınız">
                <Select.Option key="1" value="ekran">
                  Bu ekranda Listele
                </Select.Option>
                <Select.Option key="2" value="excel">
                  Excel dosyası olarak ver
                </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item {...tailLayout}>
              <Space>
                <Button htmlType="reset">Temizle</Button>
                <Button type="primary" htmlType="submit">
                  Oluştur
                </Button>
              </Space>
            </Form.Item>
          </Form>
          {cardlar.length > 0 && (
            <>
              <SYDivider renk="mavi">Oluşturan Kart numaraları</SYDivider>

              <Table
                dataSource={cardlar}
                size="small"
                bordered
                columns={columns}
              />
            </>
          )}
        </SYBox>
      </Col>
      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
        <SYCard
          header="Kart Raporu"
          footer={
            <Popconfirm
              title="Silmek istediğinize emin misiniz?"
              onConfirm={() => deleteOutDates()}
              okText="Evet"
              cancelText="İptal"
            >
              <Button>Tarihi geçmişleri sil</Button>
            </Popconfirm>
          }
        >
          {stats !== null && (
            <Descriptions
              style={{ backgroundColor: "white" }}
              bordered
              size="small"
              column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
            >
              <Descriptions.Item label="Toplam Oluşturulan">
                {stats.total}
              </Descriptions.Item>
              <Descriptions.Item label="Kullanılan">
                {stats.used}
              </Descriptions.Item>
              <Descriptions.Item label="Kullanılmayan">
                {parseInt(stats.total - stats.used)}
              </Descriptions.Item>
              <Descriptions.Item label="Kulanılmayıp tarihi geçen">
                {stats.outDate}
              </Descriptions.Item>
            </Descriptions>
          )}
        </SYCard>
      </Col>
    </Row>
  );
}

export default GetBitCards;
