import { Space } from "antd";
import React, { useContext } from "react";
import { SYBox, SYDivider } from "../../resources/styledComponents";
import { GlobalContext } from "../../context/GlobalContext";
import { useTranslation } from "react-i18next";

function Agreement() {
  const { kurum } = useContext(GlobalContext);
  const { t } = useTranslation();
  return (
    <SYBox
      bg="#f5f5f5"
      width="100%"
      height="100vh"
      className="login-page2"
      alignItems="center"
      display="flex"
    >
      <br />
      <br />
      <SYBox
        style={{ margin: "30px 10px" }}
        p="30px"
        border="1px solid #ddd"
        bg="white"
        maxWidth="800px"
        maxHeight="600px"
        overflow="scroll"
      >
        <SYDivider renk="mavi" orientation="left">
          <Space size="large">
            {kurum && (
              <img
                src={process.env.REACT_APP_FILES_BASEURL + kurum.logo}
                alt="logo"
                width="200px"
              />
            )}
            <span>{t("login.sozlesme")}</span>
          </Space>
        </SYDivider>
        What is Lorem Ipsum?
        <br /> Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the 1500s, when an unknown printer took a galley of type and
        scrambled it to make a type specimen book.
        <br /> It has survived not only five centuries, but also the leap into
        electronic typesetting, remaining essentially unchanged. <br /> <br />{" "}
        It was popularised in the 1960s with the release of Letraset sheets
        containing Lorem Ipsum passages, and more recently with desktop
        publishing software like Aldus PageMaker including versions of Lorem
        Ipsum. Why do we use it? It is a long established fact that a reader
        will be distracted by the readable content of a page when looking at its
        layout.
        <br />
        <br /> The point of using Lorem Ipsum is that it has a more-or-less
        normal distribution of letters, as opposed to using 'Content here,
        content here', making it look like readable English. Many desktop
        publishing packages and web page editors now use Lorem Ipsum as their
        default model text, and a search for 'lorem ipsum' will uncover many web
        sites still in their infancy. Various versions have evolved over the
        years, sometimes by accident, sometimes on purpose (injected humour and
        the like). <br /> <br /> Where does it come from? Contrary to popular
        belief, Lorem Ipsum is not simply random text. It has roots in a piece
        of classical Latin literature from 45 BC, making it over 2000 years old.
        Richard McClintock, a Latin professor at Hampden-Sydney College in
        Virginia, looked up one of the more obscure Latin words, consectetur,
        from a Lorem Ipsum passage, and going through the cites of the word in
        classical literature, discovered the undoubtable source. Lorem Ipsum
        comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et
        Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC.
        This book is a treatise on the theory of ethics, very popular during the
        Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit
        amet..", comes from a line in section 1.10.32. The standard chunk of
        Lorem Ipsum used since the 1500s is reproduced below for those
        interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et
        Malorum" by Cicero are also reproduced in their exact original form,
        accompanied by English versions from the 1914 translation by H. Rackham.
        What is Lorem Ipsum?
        <br /> Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the 1500s, when an unknown printer took a galley of type and
        scrambled it to make a type specimen book.
        <br /> It has survived not only five centuries, but also the leap into
        electronic typesetting, remaining essentially unchanged. <br /> <br />{" "}
        It was popularised in the 1960s with the release of Letraset sheets
        containing Lorem Ipsum passages, and more recently with desktop
        publishing software like Aldus PageMaker including versions of Lorem
        Ipsum. Why do we use it? It is a long established fact that a reader
        will be distracted by the readable content of a page when looking at its
        layout.
        <br />
        <br /> The point of using Lorem Ipsum is that it has a more-or-less
        normal distribution of letters, as opposed to using 'Content here,
        content here', making it look like readable English. Many desktop
        publishing packages and web page editors now use Lorem Ipsum as their
        default model text, and a search for 'lorem ipsum' will uncover many web
        sites still in their infancy. Various versions have evolved over the
        years, sometimes by accident, sometimes on purpose (injected humour and
        the like). <br /> <br /> Where does it come from? Contrary to popular
        belief, Lorem Ipsum is not simply random text. It has roots in a piece
        of classical Latin literature from 45 BC, making it over 2000 years old.
        Richard McClintock, a Latin professor at Hampden-Sydney College in
        Virginia, looked up one of the more obscure Latin words, consectetur,
        from a Lorem Ipsum passage, and going through the cites of the word in
        classical literature, discovered the undoubtable source. Lorem Ipsum
        comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et
        Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC.
        This book is a treatise on the theory of ethics, very popular during the
        Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit
        amet..", comes from a line in section 1.10.32. The standard chunk of
        Lorem Ipsum used since the 1500s is reproduced below for those
        interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et
        Malorum" by Cicero are also reproduced in their exact original form,
        accompanied by English versions from the 1914 translation by H. Rackham.
      </SYBox>
    </SYBox>
  );
}

export default Agreement;
