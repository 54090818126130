import {
  Button,
  Descriptions,
  Form,
  Input,
  Modal,
  notification,
  Popconfirm,
  Space,
  Table,
} from "antd";
import React, { useEffect, useState } from "react";
import { StaffService } from "../../../services/StaffService";
import { SYBox, SYDivider, SYSpan } from "../../../resources/styledComponents";
import { SYColors } from "../../../resources/SYColors";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

function ZoomSettings() {
  const [initialRender, setInitialRender] = useState(true);
  const [hasZoom, setHasZoom] = useState({ total: 0, upcoming: 0 });
  const [teachers, setTeachers] = useState([]);
  const [editModal, setEditModal] = useState(false);
  const [activeZoom, setActiveZoom] = useState(null);
  const [form] = Form.useForm();
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      StaffService.getZoomReport().then((data) => {
        setHasZoom({
          ...hasZoom,
          total: data.data.total,
          upcoming: data.data.upcoming,
        });
        setTeachers(data.data.teachers);
      });
    }
  }, [initialRender, hasZoom]);

  const onEdit = (record) => {
    console.log("rec", record);
    setActiveZoom(record);
    setEditModal(true);
  };
  const onDelete = (record) => {
    StaffService.deleteTeacherZoom(record.zoomId).then((data) => {
      notification.success({
        message: "Başarılı",
        description: "Zoom kaydı başarı ile silindi",
      });
      setInitialRender(true);
    });
  };

  const columns = [
    {
      title: "Öğretmen",
      dataIndex: "ogretmen",
    },
    {
      title: "Zoom E-mail",
      dataIndex: "zoomEmail",
      render: (zoomEmail) =>
        zoomEmail === "" ? (
          <SYSpan color={SYColors.red}>Girilmedi</SYSpan>
        ) : (
          zoomEmail
        ),
    },
    {
      title: "Zoom Api Token",
      dataIndex: "zoomToken",
      render: (zoomToken) =>
        zoomToken === "" ? (
          <SYSpan color={SYColors.red}>Girilmedi</SYSpan>
        ) : (
          zoomToken
        ),
    },
    {
      title: "İşlem",
      render: (text, record) => (
        <Space>
          <Button
            type="primary"
            size="small"
            onClick={() => onEdit(record)}
            icon={<EditOutlined />}
          />
          {record.hasZoom && (
            <Popconfirm
              title={
                <span>
                  {record.ogretmen}'in Zoom kaydını
                  <br />
                  silmek istediğinize emin misiniz?
                </span>
              }
              onConfirm={() => onDelete(record)}
              okText="Evet"
              cancelText="İptal"
            >
              <Button
                type="primary"
                size="small"
                danger
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];
  const onReset = () => {
    form.resetFields();
  };
  const onFinish = (values) => {
    values = { ...values, id: activeZoom.id, zoomId: activeZoom.zoomId };
    console.log("giden", values);
    StaffService.editTeacherZoom(activeZoom.zoomId, values).then((data) => {
      notification.success({
        message: "Başarılı",
        description: "Zoom kaydı başarı ile güncellendi",
      });
      setInitialRender(true);
    });
    onReset();
    setEditModal(false);
  };

  return (
    <SYBox border="1px solid #ddd" bg="white" p="10px">
      <Modal
        style={{ padding: "10px" }}
        visible={editModal}
        width="600px"
        title="Zoom bilgilerini giriniz"
        closable={false}
        footer={null}
      >
        <Form
          layout="vertical"
          form={form}
          size="small"
          onFinish={onFinish}
          onReset={onReset}
        >
          <Form.Item
            label="Zoom E-mail"
            name="zoomEmail"
            rules={[
              { required: true, message: "Email girmelisiniz" },
              { type: "email", message: "Geçerli bir email adresi giriniz" },
            ]}
          >
            <Input
              type="email"
              placeholder="Zoom'a bağlı email adresi"
              autoFocus
            />
          </Form.Item>
          <Form.Item
            label="JWT Token"
            name="zoomToken"
            rules={[{ required: true, message: "Token girmelisiniz" }]}
          >
            <Input.TextArea
              autoSize={{ minRows: 3, maxRows: 6 }}
              placeholder="Zoom'dan alınan JWT token i giriniz"
            />
          </Form.Item>
          <Form.Item style={{ float: "right" }}>
            <Space>
              <Button
                onClick={() => {
                  setEditModal(false);
                  onReset();
                }}
                danger
                ghost
              >
                İptal
              </Button>
              <Button htmlType="reset">Temizle</Button>
              <Button type="primary" htmlType="submit">
                Kaydet
              </Button>
            </Space>
          </Form.Item>
        </Form>
        <br />
      </Modal>
      <SYDivider renk="mavi" orientation="left">
        Oluşturulan zoom dersleri sayıları
      </SYDivider>
      <Descriptions bordered size="small">
        <Descriptions.Item label="Oluşturulan toplam zoom ders sayısı">
          <b>{hasZoom.total}</b>
        </Descriptions.Item>
        <Descriptions.Item label="Gerçekleşecek zoom ders sayısı">
          <b>{hasZoom.upcoming}</b>
        </Descriptions.Item>
        <Descriptions.Item label="Geçmiş zoom ders sayısı">
          <b>{hasZoom.total - hasZoom.upcoming}</b>
        </Descriptions.Item>
      </Descriptions>
      <br />
      <SYDivider renk="mavi" orientation="left">
        Öğretmenler zoom ayarları raporu
      </SYDivider>
      <Table
        dataSource={teachers}
        columns={columns}
        size="small"
        bordered
      ></Table>
    </SYBox>
  );
}

export default ZoomSettings;
