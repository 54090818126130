import React from "react";
import {
  Divider,
  Form,
  Input,
  Button,
  Space,
  Select,
  notification,
} from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import ValidationService from "../../services/ValidationService";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

function ForgetPassword() {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const onFinish = (values) => {
    const ctoken = executeRecaptcha("login_page");
    ctoken.then((datam) => {
      values = { ...values, cdata: datam };
      ValidationService.getForgetPasswordLink(values).then((data) => {
        notification.success({
          message: "Başarılı",
          description: "Şifre yenileme linkiniz mailize gönderilmiştir.",
        });
      });
    });
  };
  const router = useHistory();
  return (
    <div className="login-page">
      <div className="login-form-container">
        <img
          className="login-logo"
          src={require("../../assets/images/logo.png")}
          alt="logo"
        />
        <Divider>Şifre Hatırlatma</Divider>
        <Form
          name="normal_login"
          className="login-form"
          onFinish={onFinish}
          initialValues={{ iam: "student" }}
        >
          <Form.Item
            name="email"
            rules={[
              { required: true, message: "Email adresinizi yazmalısınız!" },
            ]}
          >
            <Input
              autoFocus
              autoComplete="nope"
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Email adresinizi yazınız"
            />
          </Form.Item>
          <Form.Item name="iam">
            <Select>
              <Select.Option key="ogrenci" value="student">
                Ben bir öğrenciyim
              </Select.Option>
              <Select.Option key="ogretmen" value="teacher">
                Ben bir öğretmenim
              </Select.Option>
            </Select>
          </Form.Item>
          <p>Şifre yenileme linki e-posta adresinize gelecektir.</p>

          <Form.Item>
            <Space style={{ float: "right" }}>
              <Button
                onClick={() => router.goBack()}
                className="login-form-button"
              >
                İptal
              </Button>
              <Button
                block
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                Gönder
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default ForgetPassword;
