import { notification } from "antd";
import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { UserContext } from "../context/UserContext";
const SuperAdminRoute = ({ component: Component, ...rest }) => {
  const { myRole } = useContext(UserContext);
  if (myRole !== "SuperAdmin") {
    notification.warn({
      message: "Yetkiniz yok!",
      description: "Bu sayfayı görme yetkiniz yok.",
    });
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        myRole === "SuperAdmin" ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};
const AdminRoute = ({ component: Component, ...rest }) => {
  const { myRole } = useContext(UserContext);
  if (myRole !== "Admin" && myRole !== "SuperAdmin") {
    notification.warn({
      message: "Yetkiniz yok!",
      description: "Bu sayfayı görme yetkiniz yok.",
    });
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        myRole === "Admin" || myRole === "SuperAdmin" ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};
const TeacherRoute = ({ component: Component, ...rest }) => {
  const { myRole } = useContext(UserContext);
  if (myRole === "Student") {
    notification.warn({
      message: "Yetkiniz yok!",
      description: "Bu sayfayı görme yetkiniz yok.",
    });
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        myRole === "Admin" || myRole === "Teacher" ? (
          <Component {...props} />
        ) : null
      }
    />
  );
};
const StudentRoute = ({ component: Component, ...rest }) => {
  const { myRole } = useContext(UserContext);
  return (
    <Route
      {...rest}
      render={(props) =>
        myRole === "Admin" || myRole === "Student" ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};
const PrivateRoute = ({ component: Component, ...rest }) => {
  const { myRole } = useContext(UserContext);
  if (myRole === null || myRole === undefined) {
    notification.warn({
      message: "Yetkiniz yok!",
      description: "Bu sayfayı görme yetkiniz yok. Login olmalısınız",
    });
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        myRole === "SuperAdmin" ||
        myRole === "Admin" ||
        myRole === "Student" ||
        myRole === "Teacher" ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export {
  AdminRoute,
  TeacherRoute,
  PrivateRoute,
  StudentRoute,
  SuperAdminRoute,
};
