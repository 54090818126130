import {
  Card,
  List,
  Row,
  Col,
  Space,
  Empty,
  Button,
  Rate,
  Divider,
  Popconfirm,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { getMyData } from "../../../resources/getUserData";
import { MainService } from "../../../services/MainService";
import { DeleteOutlined } from "@ant-design/icons";
import { StaffService } from "../../../services/StaffService";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { giveLessonReqTime } from "../../../resources/getLessonReqTime";
import { SYDivider, SYSpan } from "../../../resources/styledComponents";
import { SYColors } from "../../../resources/SYColors";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "../../../context/GlobalContext";
function MyRequests() {
  const [initialRender, setInitialRender] = useState(true);
  const [derslerim, setDerslerim] = useState([]);
  const { t } = useTranslation();
  const { lessonTypes } = useContext(GlobalContext);
  const router = useHistory();
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      MainService.getMyRequests(
        getMyData.getMyId(),
        getMyData.getMyRole() === "Student" ? 0 : 1
      ).then((data) => {
        setDerslerim(data.data);
        console.log("derslerim", data.data);
      });
    }
  }, [initialRender]);
  const deleteReq = (id) => {
    StaffService.deleteLessonReq(id).then((data) => {
      setInitialRender(true);
    });
  };
  const dersAlClicked = () => {
    router.push("/main/getlesson");
  };
  return (
    <div>
      <Divider style={{ color: "#1890FF" }}>
        Onay Bekleyen Ders Taleplerim
      </Divider>
      {derslerim.length === 0 && (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={t("main.dersal.talepYok")}
          style={{
            backgroundColor: "white",
            border: "1px solid #ddd",
            padding: "20px",
          }}
        >
          <Button type="primary" onClick={dersAlClicked}>
            {t("main.dersal.talepEt")}
          </Button>
        </Empty>
      )}
      {derslerim.length > 0 && (
        <List
          grid={{
            gutter: 16,
            xs: 1,
            sm: 1,
            md: 1,
            lg: 2,
            xl: 2,
            xxl: 3,
          }}
          dataSource={derslerim}
          renderItem={(item) => (
            <List.Item>
              <Card
                headStyle={{
                  color: "white",
                  backgroundColor: [1, 4, 6].includes(item.detay.netur)
                    ? "#4285F6"
                    : item.detay.netur === 3
                    ? "#ED4235"
                    : "#34A856",
                }}
                style={{ borderColor: "#ddd" }}
                size="small"
                type="inner"
                //   actions={[
                //     item.detay.pdfUrl !== null && (
                //       <img
                //         style={{ marginTop: "5px" }}
                //         width={110}
                //         src={require("../../../assets/images/pdf-indir.png")}
                //         alt="pdf"
                //       />
                //     ),
                //     <>
                //       {item.detay.zoomLink !== null && (
                //         <img
                //           width={100}
                //           src={require("../../../assets/images/zoom-logo.png")}
                //           alt="zoom"
                //         />
                //       )}
                //     </>,
                //   ]}
                title={
                  <Row justify="space-between">
                    <Col>
                      <span>
                        {lessonTypes[item.detay.netur - 1].value.toUpperCase()}
                      </span>
                    </Col>
                    <Col>
                      <Space>
                        <span>
                          {t("main.dersal.saatSayisi", {
                            saat: item.detay.saatSayisi,
                          })}
                        </span>
                        <Popconfirm
                          title={t("global.silEminmisin")}
                          onConfirm={() => deleteReq(item.detay.id)}
                          okText={t("global.evet")}
                          cancelText={t("global.iptal")}
                        >
                          <Button
                            size="small"
                            icon={<DeleteOutlined style={{ color: "red" }} />}
                          ></Button>
                        </Popconfirm>
                      </Space>
                    </Col>
                  </Row>
                }
              >
                <Space>
                  {/* <Avatar
                  size={64}
                  src={
                    process.env.REACT_APP_FILES_BASEURL +
                    item.detay.teacher.photo
                  }
                  style={{ border: "1px solid #eee" }}
                /> */}
                  <Space direction="vertical">
                    <span>
                      <b>{t("forms.ogretmen")} : </b>
                      <SYSpan color={SYColors.blue}>
                        {item.ogretmen !== null
                          ? item.ogretmen.toUpperCase()
                          : t("forms.ogretmenFarketmez")}
                      </SYSpan>
                    </span>
                    <span>
                      <b>{t("forms.derskonu")}: </b>
                      {item.ders + " / " + item.detay.konu}
                    </span>
                  </Space>
                </Space>
                <br />

                <Space>
                  <b>{t("main.dersal.talepZamani")}:</b>
                  <span>
                    {moment(item.detay.createdOn)
                      .local()
                      .format("DD MMM YY, ddd HH:mm")}
                  </span>
                </Space>
                <br />
                <Space>
                  <b>{t("main.dersal.istenenZaman")}:</b>
                  <span>{giveLessonReqTime[item.detay.nezaman]}</span>
                </Space>
                <br />
                <Space>
                  <b>{t("forms.seviye")} : </b>
                  <Rate value={item.detay.rate} />
                </Space>
                <SYDivider renk="mavi" yazi="kucuk">
                  {t("main.dersal.onayBekleniyor")}
                </SYDivider>
              </Card>
            </List.Item>
          )}
        />
      )}
    </div>
  );
}

export default MyRequests;
