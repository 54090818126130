import {
  Button,
  Divider,
  Form,
  Grid,
  Input,
  InputNumber,
  notification,
  Rate,
  Select,
  Space,
  Switch,
} from "antd";
import React, { useState, useEffect, useContext } from "react";
import { StaffService } from "../../../services/StaffService";
import { getMyData } from "../../../resources/getUserData";
import { MainService } from "../../../services/MainService";
import { UserContext } from "../../../context/UserContext";
import { GlobalContext } from "../../../context/GlobalContext";
import { useTranslation } from "react-i18next";

function OfisteGrupDersi({ tip }) {
  const [form] = Form.useForm();
  const [lessons, setLessons] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [initialRender, setInitialRender] = useState(true);
  const [grupHazir, setGrupHazir] = useState(false);
  const { myFullname } = useContext(UserContext);
  const { lessonTypes, desc, lessonReqTimes } = useContext(GlobalContext);
  const { t } = useTranslation();
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      StaffService.getLessons().then((data) => {
        setLessons(data.data.filter((l) => l.isActive === true));
      });
    }
  }, [initialRender]);
  const { useBreakpoint } = Grid;

  const layout = useBreakpoint().md
    ? {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
      }
    : null;
  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };
  const onFinish = (values) => {
    values = {
      ...values,
      netur: tip,
      isteyenId: parseInt(getMyData.getMyId()),
      isPersonal: grupHazir,
    };

    MainService.yeniDersTalebi(values).then((data) => {
      notification.success({
        message: t("global.basarili"),
        description: t("main.dersal.talepIletildi"),
      });
      onReset();
    });
  };
  const onReset = () => {
    form.resetFields();
  };
  const handleLessonChange = (value) => {
    StaffService.getTeachersbyLesson(value).then((data) => {
      setTeachers(data.data.filter((t) => t.teacher.tip5 === true));
    });
  };

  return (
    <div>
      <Divider style={{ color: "#1890FF" }}>
        {t("main.dersal.talepFormu", { ders: lessonTypes[tip - 1].value })}
      </Divider>
      <Form
        initialValues={{ rate: 3, kisi1: myFullname }}
        style={{ maxWidth: "800px", marginBottom: "50px" }}
        form={form}
        {...layout}
        onFinish={onFinish}
        layout={useBreakpoint().md ? "horizontal" : "vertical"}
      >
        <Form.Item
          name="lessonsId"
          label={t("forms.dersseciniz")}
          rules={[{ required: true, message: t("forms.messages.gerekli") }]}
        >
          <Select
            onChange={handleLessonChange}
            placeholder={t("forms.placeholders.derssec")}
            allowClear
          >
            {lessons.map((lesson) => (
              <Select.Option key={lesson.id} value={lesson.id}>
                {lesson.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={t("forms.derssayisi")}
          name="saatSayisi"
          rules={[
            { required: true, message: t("forms.messages.gerekli") },
            {
              type: "number",
              min: 1,
              max: 100,
              message: t("forms.messages.saataralik"),
            },
          ]}
        >
          <InputNumber />
        </Form.Item>
        {teachers.length > 0 && (
          <>
            <Form.Item name="teacherId" label={t("forms.ogretmenSec")}>
              <Select
                placeholder={t("forms.placeholders.ogretmenSec")}
                allowClear
              >
                <Select.Option key={0} value={0}>
                  {t("forms.farketmez")}
                </Select.Option>
                {teachers.map((t) => (
                  <Select.Option key={t.teacher.id} value={t.teacher.id}>
                    {t.teacher.fullname}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </>
        )}
        <Form.Item
          label={t("forms.konu")}
          name="konu"
          rules={[{ required: true, message: t("forms.messages.gerekli") }]}
        >
          <Input placeholder={t("forms.placeholders.konugir")} />
        </Form.Item>
        <Form.Item
          name="nezaman"
          label={t("forms.zaman")}
          rules={[{ required: true, message: t("forms.messages.gerekli") }]}
        >
          <Select placeholder={t("forms.placeholders.zaman")} allowClear>
            {lessonReqTimes.map((lt) => (
              <Select.Option key={lt.key} value={lt.key}>
                {lt.value}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="rate" label={t("forms.derstemelbilgisi")}>
          <Rate tooltips={desc} />
        </Form.Item>

        <Form.Item label={t("forms.grubumHazir")}>
          <Switch onChange={() => setGrupHazir(!grupHazir)}></Switch>
        </Form.Item>
        {grupHazir && (
          <>
            <Form.Item label={t("forms.1kisi")} required>
              <Input.Group compact>
                <Form.Item
                  name="kisi1"
                  rules={[
                    { required: true, message: t("forms.messages.gerekli") },
                  ]}
                >
                  <Input disabled placeholder={t("forms.adsoyad")} />
                </Form.Item>
                <Form.Item name="tel1">
                  <Input placeholder={t("forms.telefon")} />
                </Form.Item>
              </Input.Group>
            </Form.Item>
            <Form.Item label={t("forms.2kisi")} required>
              <Input.Group compact>
                <Form.Item
                  name="kisi2"
                  rules={[
                    { required: true, message: t("forms.messages.gerekli") },
                  ]}
                >
                  <Input disabled placeholder={t("forms.adsoyad")} />
                </Form.Item>
                <Form.Item name="tel2">
                  <Input placeholder={t("forms.telefon")} />
                </Form.Item>
              </Input.Group>
            </Form.Item>
            <Form.Item label={t("forms.3kisi")} required>
              <Input.Group compact>
                <Form.Item
                  name="kisi3"
                  rules={[
                    { required: true, message: t("forms.messages.gerekli") },
                  ]}
                >
                  <Input disabled placeholder={t("forms.adsoyad")} />
                </Form.Item>
                <Form.Item name="tel3">
                  <Input placeholder={t("forms.telefon")} />
                </Form.Item>
              </Input.Group>
            </Form.Item>
            <Form.Item label={t("forms.4kisi")} required>
              <Input.Group compact>
                <Form.Item
                  name="kisi4"
                  rules={[
                    { required: true, message: t("forms.messages.gerekli") },
                  ]}
                >
                  <Input disabled placeholder={t("forms.adsoyad")} />
                </Form.Item>
                <Form.Item name="tel4">
                  <Input placeholder={t("forms.telefon")} />
                </Form.Item>
              </Input.Group>
            </Form.Item>
            <Form.Item label={t("forms.5kisi")} required>
              <Input.Group compact>
                <Form.Item
                  name="kisi5"
                  rules={[
                    { required: true, message: t("forms.messages.gerekli") },
                  ]}
                >
                  <Input disabled placeholder={t("forms.adsoyad")} />
                </Form.Item>
                <Form.Item name="tel5">
                  <Input placeholder={t("forms.telefon")} />
                </Form.Item>
              </Input.Group>
            </Form.Item>
          </>
        )}
        <Form.Item {...tailLayout}>
          <Space>
            <Button type="primary" htmlType="submit">
              {t("forms.talebimigonder")}
            </Button>
            <Button htmlType="button" onClick={onReset}>
              {t("forms.temizle")}
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
}

export default OfisteGrupDersi;
