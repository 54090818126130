import {
  message,
  Space,
  Upload,
  Row,
  Col,
  Form,
  Input,
  Button,
  notification,
} from "antd";
import React, { useState, useEffect } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { SYBox, SYDivider } from "../../resources/styledComponents";
import ReactQuill from "react-quill";
import { AdminService } from "../../services/AdminService";
import { HomeService } from "../../services/HomeService";

const SetAboutUs = () => {
  const [fileList, setFileList] = useState([]);
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [quillSolValue, setQuillSolValue] = useState(null);
  const [quillSagValue, setQuillSagValue] = useState(null);
  const [solBaslik, setSolBaslik] = useState(null);
  const [sagBaslik, setSagBaslik] = useState(null);
  const [initialRender, setInitialRender] = useState(true);
  const [aboutus, setAboutus] = useState({});
  const { leftTitle, rightTitle, leftDetails, rightDetails, banner } = aboutus;
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      HomeService.getAboutUs().then(
        (data) => {
          setAboutus(data.data);
          console.log(data.data);
        },
        [initialRender]
      );
    }
  }, [initialRender]);

  const quillChangeSol = (e) => {
    setQuillSolValue(e);
  };
  const quillChangeSag = (e) => {
    setQuillSagValue(e);
  };
  const changeSolBaslik = (e) => {
    setSolBaslik(e.target.value);
  };
  const changeSagBaslik = (e) => {
    setSagBaslik(e.target.value);
  };
  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["clean"],
      [{ align: [] }],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };
  const beforeUpload = (file) => {
    const isJPG = file.type === "image/jpeg";
    const isPNG = file.type === "image/png";
    if (!isJPG && !isPNG) {
      message.error("Sadece resim dosyası yükleyebilirsiniz!");
    }
    const isLt1M = file.size / 1024 / 1024 < 1;
    if (!isLt1M) {
      message.error("Dosya boyutu 1MB den büyük olamaz!");
    }
    return (isJPG || isPNG) && isLt1M;
  };

  const uploadButton = (
    <div>
      <PlusOutlined style={{ fontSize: "26px" }} />
      <div className="ant-upload-text">Foto Yükle</div>
    </div>
  );

  const handleChangeImage = ({ file, fileList }) => {
    setFileList(fileList);
  };
  const gonder = () => {
    const values = {
      leftTitle: solBaslik,
      rightTitle: sagBaslik,
      leftDetails: quillSolValue,
      rightDetails: quillSagValue,
      banner: fileList[0].response.url,
    };

    AdminService.newAboutUs(values).then((data) => {
      console.log(data.data);
      notification.success({
        message: "Başarılı",
        description: "Detaylar Başarı ile kaydedildi.",
      });
      setFileList([]);
      setQuillSagValue(null);
      setQuillSolValue(null);
      setSolBaslik(null);
      setSagBaslik(null);
      setInitialRender(true);
    });
  };
  function createMarkup(gelenDetay) {
    return {
      __html: gelenDetay,
    };
  }

  return (
    <SYBox>
      <SYBox border="1px solid #ddd" p={20} bg="white" mb={30}>
        <SYDivider renk="mavi">Yeni Slider Ekle</SYDivider>
        <SYBox className="clearfix">
          <Space>
            <Upload
              action={process.env.REACT_APP_API_BASEURL + "upload"}
              headers={{
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              }}
              listType="picture-card"
              fileList={fileList}
              beforeUpload={(file) => beforeUpload(file)}
              onChange={handleChangeImage}
            >
              {fileList.length >= 1 ? null : uploadButton}
            </Upload>
            <label>Ölçüler 1920 x 466 pixel olmalı</label>
          </Space>
        </SYBox>
      </SYBox>
      <SYBox border="1px solid #ddd" p={20} bg="white" mb={30}>
        <SYDivider renk="mavi">Sol ve Sağ alanlar</SYDivider>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Form form={form}>
              <Form.Item name="title1">
                <Input
                  placeholder="Sol başlığı yazınız"
                  onChange={changeSolBaslik}
                />
              </Form.Item>
              <Form.Item>
                <ReactQuill
                  modules={modules}
                  theme="snow"
                  onChange={(e) => quillChangeSol(e)}
                />
              </Form.Item>
            </Form>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Form form={form2}>
              <Form.Item name="title2">
                <Input
                  placeholder="Sağ başlığı yazınız"
                  onChange={changeSagBaslik}
                />
              </Form.Item>
              <Form.Item>
                <ReactQuill
                  modules={modules}
                  theme="snow"
                  onChange={(e) => quillChangeSag(e)}
                />
              </Form.Item>
            </Form>
          </Col>
        </Row>
        <Button
          type="primary"
          block
          onClick={() => gonder()}
          disabled={
            !solBaslik ||
            !sagBaslik ||
            !quillSolValue ||
            !quillSagValue ||
            fileList.length === 0
          }
        >
          Gönder
        </Button>
      </SYBox>
      {aboutus.banner && (
        <div>
          <SYDivider renk="mavi">Mevcut Kayıt</SYDivider>
          <img
            src={process.env.REACT_APP_FILES_BASEURL + banner}
            alt="banner"
            width="100%"
          />
          <Row gutter={[16, 16]} style={{ margin: "20px 5px" }}>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={12}
              xl={12}
              xxl={12}
              style={{
                padding: "20px",
                border: "1px solid #ddd",
                backgroundColor: "white",
              }}
            >
              <h2>{leftTitle}</h2>
              <div dangerouslySetInnerHTML={createMarkup(leftDetails)} />
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={12}
              xl={12}
              xxl={12}
              style={{
                padding: "20px",
                border: "1px solid #ddd",
                backgroundColor: "white",
              }}
            >
              <h2>{rightTitle}</h2>
              <div dangerouslySetInnerHTML={createMarkup(rightDetails)} />
            </Col>
          </Row>
        </div>
      )}
    </SYBox>
  );
};

export default SetAboutUs;
