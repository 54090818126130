import React from "react";
import { useLocation } from "react-router-dom";

function CompanyDetails() {
  const location = useLocation();
  const { veri } = location.state || { veri: null };

  return <div>Company Details yapılacak {JSON.stringify(veri)}</div>;
}

export default CompanyDetails;
