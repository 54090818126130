import React, { useEffect, useState } from "react";
import { Form, Input, Space, Button, Select, List, Divider } from "antd";
import { StaffService } from "../../services/StaffService";

function Classes() {
  const [form] = Form.useForm();
  const [initialState, setInitialState] = useState(true);
  const [years, setYears] = useState([]);
  const [classes, setClasses] = useState([]);
  useEffect(() => {
    if (initialState) {
      setInitialState(false);
      StaffService.getYears().then((data) => {
        setYears(data.data.years);
      });
      StaffService.getKlass().then((data) => {
        setClasses(data.data);
      });
    }
  }, [initialState]);

  const onFinish = (values) => {
    StaffService.newKlass(values).then((data) => {
      setClasses([...classes, data.data]);
    });
  };
  const onReset = () => {
    form.resetFields();
  };
  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  };
  const tailLayout = {
    wrapperCol: { offset: 6, span: 18 },
  };

  return (
    <div style={{ maxWidth: "800px" }}>
      <List
        header={
          <div style={{ color: "#1890FF", fontSize: "16px" }}>
            Kayıtlı Sınıflar Listesi
          </div>
        }
        footer={
          <div style={{ color: "#1890FF" }}>
            {classes.length} adet kayıtlı sınıfınız bulunuyor
          </div>
        }
        bordered
        dataSource={classes}
        renderItem={(item, index) => (
          <List.Item>
            {item.name}.Sınıf - {item.sube} - {item.detay}
            {/* <div style={{ float: "right" }}>
              <Switch
                defaultChecked={item.isActive}
                onChange={() => onChange(index, item)}
              />
            </div> */}
          </List.Item>
        )}
      />
      <div style={{ marginTop: "50px" }}>
        <Divider>Yeni Sınıf tnaımla</Divider>
        <Form {...layout} form={form} onFinish={onFinish}>
          <Form.Item
            label="Şube Kodu"
            name="sube"
            rules={[{ required: true, message: "Şube kodu gereklidir" }]}
          >
            <Input placeholder="örn. A / TM-1" />
          </Form.Item>
          <Form.Item
            name="years"
            label="Yıl"
            rules={[{ required: true, message: "Grup seçmek zorunludur." }]}
          >
            <Select placeholder="Yıl Seçiniz" allowClear>
              {years.map((years) => (
                <Select.Option key={years.id} value={years.id}>
                  {years.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Kısa Detay"
            name="detay"
            rules={[{ required: true, message: "Detay gereklidir" }]}
          >
            <Input placeholder="örn. Türkçe Matematik" />
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Space>
              <Button type="primary" htmlType="submit">
                Kaydet
              </Button>
              <Button htmlType="button" onClick={onReset}>
                Sıfırla
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default Classes;
