import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { StaffService } from "../../services/StaffService";
import {
  Col,
  Row,
  Image,
  Descriptions,
  Space,
  Switch,
  Tag,
  Divider,
  notification,
  Button,
  Popconfirm,
  Upload,
} from "antd";
import Title from "antd/lib/typography/Title";
import { getLocation } from "../../resources/il-ilce";
import { lessonTypeOptions } from "../../resources/getLessonType";
import TeacherReport from "../../components/staff/reports/TeacherReport";
import { SYDivider } from "../../resources/styledComponents";
import {
  ArrowLeftOutlined,
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons";

function TeacherDetails() {
  const { id } = useParams();
  const [initialRender, setInitialRender] = useState(true);
  const [teacher, setTeacher] = useState(null);
  const router = useHistory();
  const [fileList, setFileList] = useState([]);

  const uploadButton = (
    <div>
      <PlusOutlined style={{ fontSize: "26px" }} />
      <div className="ant-upload-text">Foto Yükle</div>
    </div>
  );

  const handleChangeImage = ({ file, fileList }) => {
    setFileList(fileList);
    if (file.status === "done") {
      const photo = fileList[0].response.url;
      StaffService.changeTeacherImage(teacher.ogretmen.id, photo).then(
        (data) => {
          notification.success({ message: "Fotograf başarı ile değiştirildi" });
          setFileList([]);
          setInitialRender(true);
        }
      );
    }
  };

  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      StaffService.getTeacher(id).then((data) => {
        setTeacher(data.data);
      });
    }
  }, [initialRender, id]);
  function createMarkup(gelenDetay) {
    return {
      __html: gelenDetay,
    };
  }

  const deleteImage = () => {
    StaffService.deleteImage(teacher.ogretmen.id).then((data) => {
      notification.success({ message: "Resim başarı ile silindi" });
      setTeacher({
        ...teacher,
        ogretmen: { ...teacher.ogretmen, photo: null },
      });
    });
  };
  const activeChanged = (a) => {
    StaffService.changeTeacherIsActive(teacher.ogretmen.id, a).then((data) => {
      setTeacher({
        ...teacher,
        ogretmen: { ...teacher.ogretmen, isActive: !teacher.ogretmen.isActive },
      });
      notification.success({ message: "Aktiflik başarı ile değiştirildi" });
    });
  };
  const yetkiChanged = (b) => {
    var key = `tip${b}`;
    var value = !teacher.ogretmen[key];
    setTeacher({ ...teacher, ogretmen: { ...teacher.ogretmen, [key]: value } });

    var body = { [key]: value };
    StaffService.changeTip(teacher.ogretmen.id, body)
      .then(() => {
        notification.success({
          message: "Başarılı!",
          description: "İşlem başarı ile gerçekleşti.",
        });
      })
      .catch((error) => {
        setTeacher({
          ...teacher,
          ogretmen: { ...teacher.ogretmen, [key]: !value },
        });
      });
  };
  return (
    teacher && (
      <Row gutter={[16, 16]}>
        <Col
          xs={24}
          sm={6}
          md={6}
          lg={6}
          xl={6}
          style={{ textAlign: "center" }}
        >
          <Button
            style={{ float: "left" }}
            type="primary"
            onClick={() => router.goBack()}
            icon={<ArrowLeftOutlined />}
          >
            Geri
          </Button>
          <br />
          <Divider />
          <Space align="baseline">
            <Title level={2} style={{ fontWeight: 700 }}>
              {teacher.ogretmen.fullname}
            </Title>
            <Switch
              checked={teacher.ogretmen.isActive}
              onChange={activeChanged}
            ></Switch>
          </Space>

          {teacher.ogretmen.photo !== null && (
            <>
              <Image
                style={{
                  maxWidth: "200px",
                  border: "1px solid #ccc",
                  boxShadow: "0px 0px 10px #ddd",
                }}
                src={
                  process.env.REACT_APP_FILES_BASEURL + teacher.ogretmen.photo
                }
                alt=""
              />
              <br />
              <Popconfirm
                title="Resmi silmek istediğinize emin misiniz?"
                onConfirm={deleteImage}
                okText="Evet"
                cancelText="İptal"
              >
                <Button type="link" danger icon={<DeleteOutlined />} />
              </Popconfirm>
            </>
          )}
          {teacher.ogretmen.photo === null && (
            <>
              {" "}
              <div className="clearfix">
                <Upload
                  action={process.env.REACT_APP_API_BASEURL + "upload"}
                  headers={{
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                  }}
                  listType="picture-card"
                  fileList={fileList}
                  onChange={handleChangeImage}
                >
                  {fileList.length >= 1 ? null : uploadButton}
                </Upload>
              </div>
              <small>Fotoğraf ölçüleri 1:1 oranında olmalıdır</small>
            </>
          )}
          <br />
        </Col>
        <Col
          xs={24}
          sm={18}
          md={18}
          lg={18}
          xl={18}
          style={{ backgroundColor: "white", border: "1px solid #ddd" }}
        >
          <div>
            <TeacherReport id={teacher.ogretmen.id} />
            <SYDivider renk="mavi">Öğretmen Detayı</SYDivider>
            <Descriptions
              size="small"
              bordered
              column={{ xxl: 2, xl: 2, lg: 1, md: 1, sm: 1, xs: 1 }}
            >
              <Descriptions.Item label="Email">
                {teacher.ogretmen.email}
              </Descriptions.Item>
              <Descriptions.Item label="Konum">
                {getLocation(teacher.ogretmen.il, teacher.ogretmen.ilce)}
              </Descriptions.Item>
              {lessonTypeOptions.map((lt, index) => (
                <Descriptions.Item label={lt.value + " Ücreti"} key={index}>
                  {teacher.ucretler[`ucret${lt.key}`]} Bit
                </Descriptions.Item>
              ))}
              {/* <Descriptions.Item label="Durum">
                {teacher.ogretmen.isActive ? "Aktif" : "Pasif"}
              </Descriptions.Item> */}
              <Descriptions.Item label="Ders Grupları">
                {teacher.gruplar.map((grup) => (
                  <Tag color="blue" key={grup.id}>
                    {grup.name}
                  </Tag>
                ))}
              </Descriptions.Item>
              <Descriptions.Item label="Dersler">
                {teacher.dersler.map((ders) => (
                  <Tag color="magenta" key={ders.id}>
                    {ders.name}
                  </Tag>
                ))}
              </Descriptions.Item>

              {lessonTypeOptions.map((a, index) => (
                <Descriptions.Item key={index} label={a.value}>
                  <Switch
                    checked={teacher.ogretmen[`tip${index + 1}`]}
                    onChange={() => yetkiChanged(index + 1)}
                  />
                </Descriptions.Item>
              ))}
            </Descriptions>
            <Divider>Detaylar</Divider>
            <div
              dangerouslySetInnerHTML={createMarkup(teacher.ogretmen.details)}
            />
          </div>
        </Col>
      </Row>
    )
  );
}

export default TeacherDetails;
