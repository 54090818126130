import { Card, List, Space, Row, Col, Button, notification } from "antd";
import React, { useContext, useEffect, useState } from "react";
import {
  BitIcon,
  SYBox,
  SYDivider,
  SYSpan,
} from "../../../resources/styledComponents";
import { SYColors } from "../../../resources/SYColors";
import { StaffService } from "../../../services/StaffService";
import moment from "moment";
import { ShoppingOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { GlobalContext } from "../../../context/GlobalContext";
import { UserContext } from "../../../context/UserContext";
import { MainService } from "../../../services/MainService";
import MyBitChanges from "./MyBitChanges";
import { Trans, useTranslation } from "react-i18next";

function BitMarket() {
  const [initialRender, setInitialRender] = useState(true);
  const [bits, setBits] = useState([]);
  const { t } = useTranslation();
  const { setBitValue, bitValue } = useContext(GlobalContext);
  const { myId, myRole } = useContext(UserContext);
  const [bitChanges, setBitChanges] = useState([]);

  const router = useHistory();
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      StaffService.getHomeBitPackages().then((data) => {
        setBits(data.data);
      });
      if (myId !== undefined) {
        MainService.getMyBitChanges(myId).then((data) => {
          setBitChanges(data.data);
        });
      }
    }
  }, [initialRender, myId]);

  const bitShop = (item) => {
    console.log(item);
    if (myId === undefined) {
      router.push("/login/loginpage", { redirect: "/main/bit" });
    } else {
      const val = {
        packetId: item.price === 0 ? item.id : null,
        val: item.pieces,
        used: false,
        saved: true,
        studentId: myId,
        message: t("home.bitmarket.bitmesaj", { paket: item.name }),
      };
      MainService.saveBitChanges(val).then((data) => {
        setBitValue(bitValue + item.pieces);

        localStorage.setItem("bitValue", bitValue + item.pieces);
        notification.success({
          message: t("global.basarili"),
          description: t("home.bitmarket.bitalindi", { adet: item.pieces }),
        });
        setInitialRender(true);
      });
    }
  };
  return (
    <SYBox p={20}>
      <SYBox p={30} alignItems="center" display="flex">
        <Row gutter={26} align="middle" justify="center">
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            xxl={12}
            style={{
              textAlign: "center",
              boxShadow: "15px 0px 10px -13px #ccc",
              borderRight: "1px solid #ddd",
            }}
          >
            <BitIcon width={128} height={128} />
          </Col>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            xxl={12}
            style={{ paddingLeft: "30px" }}
          >
            <Space direction="vertical" size="small">
              <SYSpan color={SYColors.orange} fontSize={24} fontWeight={700}>
                {t("home.bitmarket.bitBilgi1")}
              </SYSpan>
              <SYSpan color={SYColors.greytext} fontSize={18}>
                <Trans i18nKey="home.bitmarket.bitBilgi2">
                  <b>bit ,</b> bizim için <b>"Bilgi Talebi"</b> nin
                  kısaltmasıdır.
                </Trans>
              </SYSpan>
              <SYSpan color={SYColors.greytext} fontSize={16}>
                <Trans i18nKey="home.bitmarket.bitBilgi3">
                  Belirli bir ücret karşılığında <b> bit hakkı </b> yani
                  <b> "Bilgi Talep Hakkı "</b>
                  alarak sitemizin size sunduğu olanaklardan
                  yararlanabilirsiniz.
                </Trans>
              </SYSpan>
              <SYSpan color={SYColors.greytext} fontSize={16}>
                <Trans i18nKey="home.bitmarket.bitBilgi4">
                  Sitemizde sunulan olanakların farklı <b> bit </b> değerleri
                  vardır. Kullandığınız kadar <b> bit </b> değeriniz
                  azalacaktır.
                </Trans>
              </SYSpan>
              <SYSpan color={SYColors.greytext} fontSize={16}>
                <Trans i18nKey="home.bitmarket.bitBilgi5">
                  <b> bit</b>'iniz tükendikçe bu sayfadan <b> bit </b> yükleme
                  işlemi yapabilirsiniz.
                </Trans>
              </SYSpan>
            </Space>
          </Col>
        </Row>
      </SYBox>

      <SYBox padding="30px" bg={SYColors.f5} border="1px solid #ddd">
        <SYDivider renk="mavi">{t("home.bitmarket.paketler")}</SYDivider>
        <List
          grid={{
            gutter: 16,
            xs: 1,
            sm: 2,
            md: 2,
            lg: 3,
            xl: 4,
            xxl: 4,
          }}
          dataSource={bits}
          renderItem={(item) => (
            <List.Item>
              <Card
                title={item.bit.name}
                className={
                  item.bit.isFavorite ? "isFavoriteCard" : "isNormalCard"
                }
              >
                <Row align="middle">
                  <Col
                    span={12}
                    style={{ textAlign: "right", paddingRight: "15px" }}
                  >
                    {t("home.bitmarket.bitSayisi")}:
                  </Col>
                  <Col
                    span={12}
                    style={{ fontWeight: "500", fontSize: "16px" }}
                  >
                    {item.bit.pieces}
                  </Col>
                </Row>
                <Row align="middle">
                  <Col
                    span={12}
                    style={{ textAlign: "right", paddingRight: "15px" }}
                  >
                    {t("home.bitmarket.ucret")}:
                  </Col>
                  <Col
                    span={12}
                    style={{ fontWeight: "500", fontSize: "16px" }}
                  >
                    {item.bit.price} {item.bit.money}
                  </Col>
                </Row>
                <Row align="middle">
                  <Col
                    span={12}
                    style={{ textAlign: "right", paddingRight: "15px" }}
                  >
                    {t("home.bitmarket.sonTarih")}:
                  </Col>
                  <Col
                    span={12}
                    style={{ fontWeight: "500", fontSize: "16px" }}
                  >
                    {item.bit.endDate !== null
                      ? moment(item.bit.endDate).local().format("DD MMM, ddd ")
                      : "Yok"}
                  </Col>
                </Row>
                <br />
                <Button
                  onClick={() => bitShop(item.bit)}
                  type="primary"
                  block
                  size="large"
                  ghost={!item.bit.isFavorite}
                  disabled={myRole !== "Student"}
                >
                  <ShoppingOutlined /> {t("home.bitmarket.satinAl")}
                </Button>
              </Card>
            </List.Item>
          )}
        />
      </SYBox>
      {myId !== undefined && <MyBitChanges bitChanges={bitChanges} />}
    </SYBox>
  );
}

export default BitMarket;
