import {
  Row,
  Col,
  Divider,
  Card,
  Space,
  Button,
  Avatar,
  notification,
  List,
  Rate,
  Popconfirm,
} from "antd";
import React, { useEffect, useState, useContext } from "react";
import { MainService } from "../../../services/MainService";
import { getLessonType } from "../../../resources/getLessonType";
import { UserContext } from "../../../context/UserContext";
import { PlusOutlined } from "@ant-design/icons";
import moment from "moment";
import { GlobalContext } from "../../../context/GlobalContext";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

function JoinGroupLesson({ tip }) {
  const [initialRender, setInitialRender] = useState(true);
  const [groupLessons, setGroupLessons] = useState([]);
  const { myId } = useContext(UserContext);
  const { bitValue, setBitValue, lessonTypes } = useContext(GlobalContext);
  const { t } = useTranslation();
  const router = useHistory();
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      if (tip === getLessonType.OnlineGrupDersi) {
        MainService.getOpenedGroupLessons(getLessonType.OnlineGrupDersi).then(
          (data) => {
            setGroupLessons(data.data);
          }
        );
      } else if (tip === getLessonType.OfisteGrupDersi) {
        MainService.getOpenedGroupLessons(getLessonType.OfisteGrupDersi).then(
          (data) => {
            setGroupLessons(data.data);
          }
        );
      } else if (tip === getLessonType.OnlineAmfiDersi) {
        MainService.getOpenedGroupLessons(getLessonType.OnlineAmfiDersi).then(
          (data) => {
            setGroupLessons(data.data);
          }
        );
      }
    }
  }, [initialRender, tip]);
  const joinTask = (task) => {
    if (task.students.findIndex((s) => s.id === myId) === -1) {
      MainService.joinLessonTask(task.task.id, myId).then((data) => {
        notification.success({
          message: t("global.basarili"),
          description: t("main.dersal.dersAlindi"),
        });
        localStorage.setItem("bitValue", bitValue - task.task.ucret);
        setBitValue(bitValue - task.task.ucret);
        setInitialRender(true);
      });
    } else {
      notification.error({
        message: t("global.hata"),
        description: t("main.dersal.dersAlinmis"),
      });
    }
  };

  return (
    <div>
      {groupLessons.length > 0 && (
        <div>
          <Divider style={{ color: "#1890FF" }}>
            {tip === getLessonType.OnlineGrupDersi
              ? t("main.dersal.hazirOnlineGrup")
              : tip === getLessonType.OfisteGrupDersi
              ? t("main.dersal.hazirOfisteGrup")
              : t("main.dersal.hazirOnlineAmfi")}
          </Divider>

          <List
            grid={{
              gutter: 16,
              xs: 1,
              sm: 1,
              md: 1,
              lg: 2,
              xl: 2,
              xxl: 3,
            }}
            dataSource={groupLessons}
            renderItem={(item) => (
              <List.Item>
                <Card
                  headStyle={{
                    color: "white",
                    backgroundColor: [1, 4, 6].includes(item.task.tip)
                      ? "#4285F6"
                      : item.task.tip === 3
                      ? "#ED4235"
                      : "#34A856",
                  }}
                  style={{ borderColor: "#ddd" }}
                  size="small"
                  type="inner"
                  title={
                    <Row justify="space-between">
                      <Col>
                        <span>
                          {lessonTypes[item.task.tip - 1].value.toUpperCase()}
                        </span>
                      </Col>
                      <Col>
                        <Space>
                          <span>
                            {t("main.dersal.saatSayisi", {
                              saat: item.task.saatSayisi,
                            })}
                          </span>
                          <span>
                            (
                            {t("main.dersal.bitSayisi", {
                              bit: item.task.ucret,
                            })}
                            )
                          </span>
                          {(item.task.tip < 6 && item.students.length < 5) ||
                          (item.task.tip === 6 &&
                            item.students.length < 100) ? (
                            <Popconfirm
                              title={
                                bitValue < item.task.ucret
                                  ? t("main.dersal.bitYetersiz")
                                  : t("main.dersal.eminmisin")
                              }
                              onConfirm={() =>
                                bitValue > item.task.ucret
                                  ? joinTask(item)
                                  : router.push("/main/bit")
                              }
                              okText={
                                bitValue > item.task.ucret
                                  ? t("global.evet")
                                  : t("main.dersal.bital")
                              }
                              cancelText={t("global.iptal")}
                            >
                              <Button
                                size="small"
                                type="default"
                                icon={<PlusOutlined />}
                              />
                            </Popconfirm>
                          ) : (
                            <Button size="small" type="primary" danger>
                              {t("main.dersal.dolu")}
                            </Button>
                          )}
                        </Space>
                      </Col>
                    </Row>
                  }
                >
                  <Space>
                    <Avatar
                      size={64}
                      src={
                        process.env.REACT_APP_FILES_BASEURL +
                        item.task.teacher.photo
                      }
                      style={{ border: "1px solid #eee" }}
                    />
                    <Space direction="vertical">
                      <span>{item.task.teacher.fullname.toUpperCase()}</span>
                      <span>
                        {item.task.lessons.name + " / " + item.task.konu}
                      </span>
                    </Space>
                  </Space>
                  <div style={{ width: "100%", margin: "15px 0 0 0" }}>
                    {item.task.appos.map((appo) => (
                      <Button
                        key={appo.id}
                        size="small"
                        type="primary"
                        ghost
                        style={{ margin: "0 10px 10px 0" }}
                      >
                        {moment(appo.dateTime)
                          .local()
                          .format("DD MMM, ddd   HH:mm")}
                      </Button>
                    ))}
                  </div>
                  <Space>
                    <span>{t("forms.seviye")} : </span>
                    <Rate value={item.task.rate} />
                  </Space>
                  <Divider
                    className="mdivider"
                    plain
                    orientation="left"
                    style={{ color: "#4285F6" }}
                  >
                    {t("main.dersal.katilimcilar")}
                  </Divider>

                  <ol>
                    {item.students.map((student, index) =>
                      index < 5 ? (
                        <li key={student.id}>{student.fullname}</li>
                      ) : (
                        index === 5 && "..."
                      )
                    )}
                    {/* {item.task.tip === 4 && (
                      <>
                        <li>{item.task.kisi2}</li>
                        <li>{item.task.kisi3}</li>
                        <li>{item.task.kisi4}</li>
                        <li>{item.task.kisi5}</li>
                      </>
                    )} */}
                    <p style={{ color: "teal", fontSize: "13px" }}>
                      (
                      {tip === 6
                        ? 100 - item.students.length
                        : 5 - item.students.length}{" "}
                      {t("main.dersal.kisiKaldi")})
                    </p>
                  </ol>
                </Card>
              </List.Item>
            )}
          />
        </div>
      )}
    </div>
  );
}

export default JoinGroupLesson;
