import { UnorderedListOutlined } from "@ant-design/icons";
import { Button, Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AdminService } from "../../services/AdminService";
function Companies() {
  const [initialRender, setInitialRender] = useState(true);
  const [companies, setCompanies] = useState([]);
  const router = useHistory();
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getCompanies().then((data) => {
        console.log(data.data);
        setCompanies(data.data);
      });
    }
  }, [initialRender]);
  const getDetails = (veri) => {
    console.log(veri);
    router.push("/admin/company/details", { veri });
  };
  var columns = [
    {
      title: "Kurum Adı",
      dataIndex: "data",
      render: (data) => data.name,
    },
    {
      title: "Domainler",
      dataIndex: "data",
      render: (data) =>
        data.domains === null
          ? "Tanımlanmamış"
          : data.domains.map((d) => <Tag key={d.id}>{d.domain}</Tag>),
    },
    {
      title: "Admin Ad",
      dataIndex: "admin",
    },
    {
      title: "Admin Email",
      dataIndex: "email",
    },
    {
      title: "Admin Tel",
      dataIndex: "phone",
    },
    {
      title: "Detay",
      render: (text, record) => (
        <Button
          size="small"
          icon={<UnorderedListOutlined />}
          type="primary"
          onClick={() => getDetails(record)}
        />
      ),
    },
  ];
  return (
    <div>
      <Table dataSource={companies} bordered size="small" columns={columns} />
    </div>
  );
}

export default Companies;
