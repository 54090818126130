import { Button, notification, Popconfirm, Table, Tag } from "antd";
import React from "react";
import { SYDivider } from "../../../resources/styledComponents";
import moment from "moment";
import { DeleteOutlined } from "@ant-design/icons";
import { StaffService } from "../../../services/StaffService";

function TeacherTasks({ tasks, sir }) {
  const deleteTask = (id) => {
    StaffService.deleteLessonTaskById(id).then((data) => {
      notification.success({ message: "Ders başarı ile silindi" });
      sir(true);
    });
  };

  const columns = [
    {
      title: "Ders Tipi",
      dataIndex: "tip",
    },
    {
      title: "Konu",
      dataIndex: "konu",
    },
    {
      title: "Ücret",
      dataIndex: "ucret",
    },
    {
      title: "Saatler",
      dataIndex: "appos",
      render: (appos) =>
        appos.map((appo) => (
          <Tag key={appo.id} color="blue">
            {moment(appo.dateTime).local().format("DD MMM, ddd HH:mm")}
          </Tag>
        )),
    },
    {
      title: "İşlem",
      render: (text, record) => (
        <Popconfirm
          title={
            <span>
              Dersi silmek istediğinize emin misiniz?
              <br />
              Randevu kaydı, Mesajlar vs. hepsi silinecektir.
            </span>
          }
          onConfirm={() => deleteTask(record.id)}
          okText="Evet"
          cancelText="İptal"
        >
          <Button type="link" danger icon={<DeleteOutlined />} />
        </Popconfirm>
      ),
    },
  ];
  return (
    <div>
      <SYDivider renk="mavi">Gerçekleşecek Onaylanmış Dersler</SYDivider>
      <Table dataSource={tasks} bordered size="small" columns={columns} />
    </div>
  );
}

export default TeacherTasks;
