import {
  Button,
  Col,
  Divider,
  Form,
  Grid,
  Input,
  InputNumber,
  List,
  Modal,
  notification,
  Rate,
  Row,
  Select,
  Space,
} from "antd";
import React, { useState, useEffect } from "react";
import moment from "moment";
import { lessonTypeOptions } from "../../../resources/getLessonType";
import { StaffService } from "../../../services/StaffService";
import { MainService } from "../../../services/MainService";
import LessonTaskList from "./LessonTaskList";

function SetLessonTask() {
  const [form] = Form.useForm();
  const [lessons, setLessons] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [initialRender, setInitialRender] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [secilenler, setSecilenler] = useState([]);
  const [saatler, setSaatler] = useState([]);
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      StaffService.getLessons().then((data) => {
        setLessons(data.data.filter((l) => l.isActive === true));
      });
      StaffService.getAllGroupLessonsTask().then((data) => {
        setDataSource(data.data);
      });
    }
  }, [initialRender]);
  const { useBreakpoint } = Grid;
  const desc = ["Çok Kötü", "Kötü", "Normal", "İyi", "Çok İyi"];

  const layout = useBreakpoint().md
    ? {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
      }
    : null;
  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };
  const onFinish = (values) => {
    var secimler = [];
    secilenler.forEach((s) => {
      secimler.push(s.id);
    });
    values = {
      ...values,
      secilenler: secimler,
      isPersonal: false,
    };
    MainService.yeniLessonTask(values).then((data) => {
      window.scrollTo(0, 0);

      notification.success({
        message: "Başarılı",
        description: "Ders başarı ile oluşturuldu",
      });
      if ([1, 4, 6].includes(data.data.task.tip)) {
        if (data.data.task.appos[0].join_url !== null) {
          notification.success({
            message: "Zoom Linki başarı ile oluşturuldu",
          });
        } else {
          notification.warning({
            message: "Ders oluşturuldu fakat Zoom linki oluşturulamadı!",
          });
        }
      }
      onReset();
      setTeachers([]);
      setInitialRender(true);
    });
  };
  const onReset = () => {
    form.resetFields();
    setSecilenler([]);
  };
  const handleLessonChange = (value) => {
    StaffService.getTeachersbyLesson(value).then((data) => {
      setTeachers(data.data.filter((d) => d.teacher.tip1 === true));
      if (data.data.length === 0) {
        notification.info({
          message: "Uyarı!",
          description:
            "Bu derse ait tanımlı öğretmen bulunmadığından bu dersi açamazsınız",
        });
      }
    });
  };

  const modalHandleOk = () => {
    setModalVisible(false);
  };
  const modalHandleCancel = () => {
    setModalVisible(false);
  };
  const ogretmenChanged = (e, item, val) => {
    StaffService.getSchedule(e).then((data) => {
      setSaatler(data.data);
      setModalVisible(true);
    });
  };
  const timeSelected = (item, index) => {
    if (secilenler.length + 1 === form.getFieldValue("saatSayisi")) {
      setModalVisible(false);
    }
    setSaatler(saatler.filter((s) => s.id !== item.id));
    setSecilenler([...secilenler, item]);
  };
  const secilenClicked = (item) => {
    setSecilenler(secilenler.filter((a) => a.id !== item.id));
    setSaatler([...saatler, item]);
  };
  return (
    <div style={{ paddingRight: "30px" }}>
      <Modal
        className="modaldiv"
        visible={modalVisible}
        title={
          <div style={{ width: "100%", wordWrap: "break-word" }}>
            {secilenler.length === 0 ? (
              "Saatleri seçin"
            ) : (
              <Row gutter={[10, 10]}>
                {secilenler.map((sc) => (
                  <Col key={sc.id}>
                    <Button
                      type="primary"
                      ghost
                      onClick={() => secilenClicked(sc)}
                    >
                      {" "}
                      {moment(sc.dateTime)
                        .local()
                        .format("DD MMM, ddd   HH:mm")}{" "}
                      ❌
                    </Button>
                  </Col>
                ))}
              </Row>
            )}
          </div>
        }
        onOk={modalHandleOk}
        onCancel={modalHandleCancel}
        width="95%"
      >
        <List
          grid={{
            gutter: 10,
            xs: 2,
            sm: 3,
            md: 4,
            lg: 5,
            xl: 6,
            xxl: 7,
          }}
          dataSource={saatler}
          renderItem={(item, index) => (
            <List.Item>
              <Button block onClick={() => timeSelected(item, index)}>
                {moment(item.dateTime).local().format("DD MMM, ddd   HH:mm")}
              </Button>
            </List.Item>
          )}
        />
      </Modal>
      <Divider orientation="left" style={{ color: "#1890FF" }}>
        Grup / Amfi dersi tanımlama formu
      </Divider>
      <Form
        initialValues={{ rate: 3 }}
        style={{ maxWidth: "800px", marginBottom: "50px" }}
        form={form}
        {...layout}
        onFinish={onFinish}
        layout={useBreakpoint().md ? "horizontal" : "vertical"}
      >
        <Form.Item
          name="tip"
          label="Ders Tipi Seçiniz"
          rules={[{ required: true, message: "Ders tipi seçmek zorunludur." }]}
        >
          <Select
            //  onChange={handleLessonTypeChange}
            placeholder="Talep edilen dersi seçiniz"
            allowClear
          >
            {lessonTypeOptions.map(
              (lesson) =>
                lesson.key > 3 && (
                  <Select.Option key={lesson.key} value={lesson.key}>
                    {lesson.value}
                  </Select.Option>
                )
            )}
          </Select>
        </Form.Item>
        <Form.Item
          name="lessonsId"
          label="Ders Seçiniz"
          rules={[{ required: true, message: "Ders seçmek zorunludur." }]}
        >
          <Select
            onChange={handleLessonChange}
            placeholder="Talep edilen dersi seçiniz"
            allowClear
          >
            {lessons.map((lesson) => (
              <Select.Option key={lesson.id} value={lesson.id}>
                {lesson.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="İstediğin Ders Sayısı"
          name="saatSayisi"
          rules={[
            { required: true, message: "Saat sayısı seçiniz." },
            {
              type: "number",
              min: 1,
              max: 100,
              message: "Saat 1-100 aralığında olmalıdır",
            },
          ]}
        >
          <InputNumber />
        </Form.Item>

        <Form.Item
          name="teacherId"
          label="Öğretmen Seçiniz"
          rules={[
            { required: true, message: "Öğretmen tanımlanmış olmalıdır." },
          ]}
        >
          <Select
            placeholder="Talep edilen öğretmeni seçiniz"
            onSelect={(e, item, val) => ogretmenChanged(e, item, val)}
            allowClear
          >
            {teachers.map((t) => (
              <Select.Option key={t.teacher.id} value={t.teacher.id}>
                {t.teacher.fullname}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        {secilenler.length > 0 && (
          <Form.Item label="Seçilenler">
            <Space>
              {secilenler.map((secilen) => (
                <Button type="primary" ghost key={secilen.id}>
                  {moment(secilen.dateTime)
                    .local()
                    .format("DD MMM, ddd   HH:mm")}
                </Button>
              ))}
            </Space>
          </Form.Item>
        )}
        <Form.Item
          label="Konu Giriniz"
          name="konu"
          rules={[{ required: true, message: "Konu yazmak zorunludur." }]}
        >
          <Input placeholder="Talep ettiğiniz dersin konusunu yazınız" />
        </Form.Item>

        <Form.Item name="rate" label="Ders Temel Bilgisi">
          <Rate tooltips={desc} />
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Space>
            <Button type="primary" htmlType="submit">
              Dersi Aç
            </Button>
            <Button htmlType="button" onClick={onReset}>
              Sıfırla
            </Button>
          </Space>
        </Form.Item>
      </Form>
      <LessonTaskList dataSource={dataSource} sir={setInitialRender} />
    </div>
  );
}

export default SetLessonTask;
