import React, { useContext, useState } from "react";
import { Layout, Menu, Row, Col, Dropdown } from "antd";
import { Switch, Route, Redirect, Link, useHistory } from "react-router-dom";
import {
  DashboardTwoTone,
  DownOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { UserContext } from "../../context/UserContext";
import AdminPage from "./AdminPage";
import Company from "./Company";
import Companies from "./Companies";
import CompanyDetails from "./CompanyDetails";
import { GlobalContext } from "../../context/GlobalContext";
const { Header, Content, Footer, Sider } = Layout;

function AdminLayout() {
  const [collapsed, setCollapsed] = useState(false);
  const [bp, setBp] = useState(null);
  const router = useHistory();
  const { myFullname } = useContext(UserContext);
  const { kurum } = useContext(GlobalContext);
  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    router.push("/login");
  };
  const gohome = () => {
    router.push("/");
  };
  const toggle = () => {
    setCollapsed(!collapsed);
  };
  const menuSelected = (item) => {
    bp && setCollapsed(true);
    console.log(item);
    // router.push(item.item.props.link);
  };
  const usermenu = (
    <Menu>
      <Menu.Item onClick={gohome}>Home Page</Menu.Item>
      <Menu.Item onClick={() => router.push("/main")}>Main Page</Menu.Item>
      <Menu.Item danger onClick={logout}>
        ÇIKIŞ
      </Menu.Item>
    </Menu>
  );
  return (
    <Layout>
      <Sider
        style={
          bp
            ? {
                position: "absolute",
                minHeight: "100vh",
                zIndex: "1",
                marginTop: "65px",
                borderRight: "1px solid #ddd",
              }
            : {
                backgroundColor: "#ffffff",
                borderRight: "1px solid #ddd",
              }
        }
        breakpoint="xl"
        collapsedWidth="0"
        onBreakpoint={(broken) => {
          console.log(broken);
          setBp(broken);
          setCollapsed(broken);
        }}
        onCollapse={() => {
          // setCollapsed(true);
        }}
        theme="light"
        trigger={null}
        collapsible
        collapsed={collapsed}
      >
        {kurum && (
          <img
            className="logo"
            src={process.env.REACT_APP_FILES_BASEURL + kurum.logo}
            alt="logo"
          />
        )}
        <Menu
          mode="inline"
          defaultSelectedKeys={[]}
          style={{
            borderRight: "none",
            borderTop: "1px solid #ddd",
            marginBottom: "50px",
          }}
          onSelect={(item) => menuSelected(item)}
        >
          <Menu.Item key="1" icon={<DashboardTwoTone />}>
            <Link to="/admin/dashboard">Kontrol Paneli</Link>
          </Menu.Item>
          <Menu.Item key="2" icon={<DashboardTwoTone />}>
            <Link to="/admin/company">Kurum Ekle</Link>
          </Menu.Item>
          <Menu.Item key="3" icon={<DashboardTwoTone />}>
            <Link to="/admin/companies">Kurumlar</Link>
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout>
        <Header
          className="site-layout-sub-header-background"
          style={{ padding: 0, borderBottom: "1px solid #ddd" }}
        >
          <Row
            justify="space-between"
            align="middle"
            style={{ marginRight: "15px" }}
          >
            <Col>
              {collapsed ? (
                <MenuUnfoldOutlined
                  className="trigger"
                  onClick={() => toggle()}
                />
              ) : (
                <MenuFoldOutlined
                  className="trigger"
                  onClick={() => toggle()}
                />
              )}
            </Col>
            <Col>.</Col>
            <Col>
              <Dropdown trigger={["click"]} overlay={usermenu}>
                <span
                  style={{
                    cursor: "pointer",
                    color: "#1890FF",
                    border: "1px solid #ddd",
                    padding: "8px",
                    borderRadius: "3px",
                    backgroundColor: "#fafafa",
                  }}
                >
                  <UserOutlined />
                  <span
                    className="ant-dropdown-link"
                    onClick={(e) => e.preventDefault()}
                  >
                    {myFullname || "Login"} <DownOutlined />
                  </span>
                </span>
              </Dropdown>
            </Col>
          </Row>
        </Header>
        <Content
          style={{ margin: "16px 16px 0", minHeight: "calc(100vh - 202px)" }}
        >
          <Switch>
            <Route exact path={"/admin"}>
              <Redirect to={"/admin/dashboard"} />
            </Route>
            <Route path={"/admin/dashboard"} component={AdminPage} />
            <Route path={"/admin/company/details"} component={CompanyDetails} />
            <Route path={"/admin/company"} component={Company} />
            <Route path={"/admin/companies"} component={Companies} />
          </Switch>
        </Content>
        <Footer style={{ textAlign: "center", marginTop: "50px" }}>
          {kurum && kurum.name} ©2021 Created by Hypotenuse
        </Footer>
      </Layout>
    </Layout>
  );
}

export default AdminLayout;
