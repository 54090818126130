import React, { useContext, useEffect, useState } from "react";
import { MainService } from "../../services/MainService";
import { UserContext } from "../../context/UserContext";
import { Alert, Modal } from "antd";
import AdsImages from "../../components/home/AdsImages";
import TeacherDash from "../../components/main/Graphs/TeacherDash";
import { SYDivider } from "../../resources/styledComponents";
import StudentDash from "../../components/main/Graphs/StudentDash";
import { useTranslation } from "react-i18next";

function MainPage() {
  const [initialRender, setInitialRender] = useState(true);
  const [alerts, setAlerts] = useState([]);
  const [ads, setAds] = useState(null);
  const { myRole } = useContext(UserContext);
  const { t } = useTranslation();
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      MainService.getAlerts(myRole === "Student" ? 1 : 2).then((data) => {
        setAlerts(data.data.alerts);
        setAds(data.data.ads);
        data.data.alerts.forEach((alarm) => {
          if (alarm.isModal) {
            Modal[alarm.type]({
              title: alarm.title,
              content: (
                <div>
                  <p>{alarm.description}</p>
                </div>
              ),
              onOk() {},
            });
          }
        });
      });
    }
  }, [initialRender, myRole]);

  const modalClose = (gid) => {
    console.log(alerts);
    setAlerts(alerts.filter((a) => a.id !== gid));
  };
  return (
    <div>
      {alerts.length > 0 && (
        <>
          <SYDivider renk="mavi">{t("main.mainpage.genelBildirim")}</SYDivider>
          {alerts.map((alert) => (
            <Alert
              key={alert.id}
              className="mainalert"
              message={alert.title.toUpperCase()}
              description={alert.description}
              type={alert.type}
              showIcon
              closable
              onClose={() => modalClose(alert.id)}
            />
          ))}
        </>
      )}
      <br />
      {myRole === "Teacher" && <TeacherDash />}
      {myRole === "Student" && <StudentDash />}
      {ads !== null && <AdsImages ads={ads} />}
    </div>
  );
}

export default MainPage;
