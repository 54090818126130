import React from "react";
import NewQuestionForm from "./NewQuestionForm";

function GetQuestion() {
  return (
    <div>
      <NewQuestionForm />
    </div>
  );
}

export default GetQuestion;
