import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import LoginPage from "./LoginPage";
import SignupPage from "./SignupPage";
import Agreement from "./Agreement";
import ForgetPassword from "./ForgetPassword";
import NewPassword from "./NewPassword";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

function LoginLayout() {
  return (
    <Switch>
      <Route exact path={"/login"}>
        <Redirect to={"/login/loginpage"} />
      </Route>
      <Route path={"/login/loginpage"}>
        <LoginPage />
      </Route>
      <Route path={"/login/signuppage"}>
        <GoogleReCaptchaProvider
          reCaptchaKey={process.env.REACT_APP_reCaptchaKey}
        >
          <SignupPage />
        </GoogleReCaptchaProvider>
      </Route>
      <Route path={"/login/agreement"}>
        <Agreement />
      </Route>
      <Route path={"/login/forgetpassword"}>
        <GoogleReCaptchaProvider
          reCaptchaKey={process.env.REACT_APP_reCaptchaKey}
        >
          <ForgetPassword />
        </GoogleReCaptchaProvider>
      </Route>
      <Route path={"/login/newpassword"}>
        <GoogleReCaptchaProvider
          reCaptchaKey={process.env.REACT_APP_reCaptchaKey}
        >
          <NewPassword />
        </GoogleReCaptchaProvider>
      </Route>
      <Route>
        <Redirect to="/login" />
      </Route>
    </Switch>
  );
}

export default LoginLayout;
