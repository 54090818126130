import {
  Button,
  Card,
  Col,
  Collapse,
  Empty,
  Image,
  notification,
  Row,
  Select,
  Space,
  Switch,
} from "antd";
import React, { useEffect, useState } from "react";
import { Paperclip } from "react-feather";
import { MainService } from "../../../services/MainService";
import { StaffService } from "../../../services/StaffService";
import { getMyData } from "../../../resources/getUserData";
import { Content } from "antd/lib/layout/layout";
import { useTranslation } from "react-i18next";

function MyQuestions() {
  const [initialRender, setInitialRender] = useState(true);
  const [questions, setQuestions] = useState([]);
  const [filteredQuestions, setFilteredQuestions] = useState([]);
  const [lessons, setLessons] = useState([]);
  const [buttons, setButtons] = useState({});
  const [defChecked, setDefChecked] = useState(true);
  const [selectValue, setSelectValue] = useState(undefined);
  const { t } = useTranslation();
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      MainService.getSorularById(getMyData.getMyId()).then((data) => {
        setQuestions(data.data);
        setFilteredQuestions(
          data.data.filter((d) => d.sorular.aktifmi === defChecked)
        );
      });
      StaffService.getLessons().then((data) => {
        setLessons(data.data);
      });
    }
  }, [initialRender, defChecked]);
  const buttonClicked = (id) => {
    setButtons({ ...buttons, [id]: !buttons[id] });
  };
  const activeChange = (a, b) => {
    MainService.ChangeActiveQuestion(b, a).then((data) => {
      notification.success({
        message: t("global.basarili"),
        description: t("main.soru.soruAktiflik"),
      });
      setInitialRender(true);
    });
  };
  const kategoriDegisti = (kat) => {
    setSelectValue(kat);
    if (kat === undefined || kat === -3) {
      setFilteredQuestions(
        questions.filter((a) => a.sorular.aktifmi === defChecked)
      );
    } else {
      setFilteredQuestions(
        questions.filter(
          (q) => q.sorular.kategori === kat && q.sorular.aktifmi === defChecked
        )
      );
    }
  };
  const activeFilterChange = (a) => {
    setDefChecked(a);
    kategoriDegisti(-3);
    setFilteredQuestions(questions.filter((f) => f.sorular.aktifmi === a));
  };

  return (
    <div>
      <Space size="large">
        <div>
          <span>{t("main.soru.filtrele")}: </span>
          <Select
            value={selectValue}
            onChange={kategoriDegisti}
            placeholder={t("main.soru.kategoriFiltre")}
            allowClear
          >
            <Select.Option key="tum" value={-3}>
              {t("main.soru.tum")}
            </Select.Option>
            <Select.Option key="gbt" value={-1}>
              {t("main.soru.genelBilgi")}
            </Select.Option>
            <Select.Option key="rs" value={-2}>
              {t("main.soru.rehberlik")}
            </Select.Option>
            {lessons.map((lesson) => (
              <Select.Option key={lesson.id} value={lesson.id}>
                {t("main.soru.dersSorusu", { ders: lesson.name })}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div>
          <span>{t("main.soru.aktifpasif")}: </span>
          <Switch
            onChange={activeFilterChange}
            checked={defChecked}
            checkedChildren={t("main.soru.aktif")}
            unCheckedChildren={t("main.soru.pasif")}
          ></Switch>
        </div>
      </Space>
      <br />
      <br />
      <Row>
        {filteredQuestions.length === 0 && (
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              marginRight: "15px",
            }}
          >
            <Content
              style={{
                backgroundColor: "white",
                padding: "20px",
                border: "1px solid #ddd",
                boxShadow: "1px 1px 7px #eee",
              }}
            >
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={t("main.soru.kayitYok")}
              >
                <Button type="primary" onClick={() => kategoriDegisti(-3)}>
                  {t("main.soru.filtreReset")}
                </Button>
              </Empty>
            </Content>
            {/* <Result
              status="404"
              title="Ooops!"
              subTitle="Aradığınız kriterlere uygun kayıt bulunamadı."
              extra={
                <Button type="primary" onClick={() => kategoriDegisti(-3)}>
                  Filtreyi Sıfırla
                </Button>
              }
            /> */}
          </div>
        )}

        {filteredQuestions.map((q) => (
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={12}
            xxl={12}
            key={q.sorular.id}
          >
            <Card
              type="inner"
              size="small"
              extra={
                <Switch
                  onChange={(a) => activeChange(a, q.sorular.id)}
                  defaultChecked={q.sorular.aktifmi}
                  checkedChildren={t("main.soru.aktif")}
                  unCheckedChildren={t("main.soru.pasif")}
                ></Switch>
              }
              style={{ margin: "0 20px 20px 0", borderColor: "#ddd" }}
              title={
                <span style={{ color: "#1890FF" }}>
                  {q.dersler === null
                    ? q.sorular.kategori === -1
                      ? t("main.soru.genelBilgi")
                      : t("main.soru.rehberlik")
                    : t("main.soru.dersSorusu", { ders: q.dersler.name })}
                </span>
              }
            >
              <p>{q.sorular.soru}</p>
              {q.sorular.soruUrl !== null ? (
                q.sorular.soruUrl.split(".").pop() === "pdf" ? (
                  <a
                    href={
                      process.env.REACT_APP_FILES_BASEURL + q.sorular.soruUrl
                    }
                    target="_blanc"
                  >
                    <Paperclip size={13} />
                    <span style={{ fontSize: "12px" }}>
                      {" "}
                      {q.sorular.soruUrl.split("-").pop()}
                    </span>
                  </a>
                ) : (
                  <Image
                    style={{
                      maxWidth: "100px",
                      cursor: "pointer",
                      //  border: "3px solid white",
                      //    boxShadow: "1px 1px 10px #bbb",
                    }}
                    src={
                      process.env.REACT_APP_FILES_BASEURL + q.sorular.soruUrl
                    }
                    alt=""
                  />
                )
              ) : null}
              <hr style={{ border: "none", borderTop: "1px solid #eee" }} />
              <Collapse
                style={{ backgroundColor: "white", borderColor: "white" }}
              >
                <Collapse.Panel
                  header={
                    q.sorular.cevap !== null ? (
                      <span
                        style={{ color: "forestgreen" }}
                        onClick={() => buttonClicked(q.sorular.id)}
                      >
                        {buttons[q.sorular.id]
                          ? t("main.soru.cevapGizle")
                          : t("main.soru.cevapGoster")}
                      </span>
                    ) : (
                      <span style={{ color: "coral" }}>
                        {t("main.soru.cevaplanmadi")}
                      </span>
                    )
                  }
                  key="1"
                  style={{ backgroundColor: "white", borderColor: "white" }}
                >
                  {q.sorular.cevap !== null ? (
                    q.sorular.cevapUrl !== null ? (
                      <>
                        <p>{q.sorular.cevap}</p>
                        {q.sorular.cevapUrl.split(".").pop() === "pdf" ? (
                          <a
                            href={
                              process.env.REACT_APP_FILES_BASEURL +
                              q.sorular.cevapUrl
                            }
                            target="_blanc"
                          >
                            <Paperclip size={13} />
                            <span style={{ fontSize: "12px" }}>
                              {" "}
                              {q.sorular.cevapUrl.split("-").pop()}
                            </span>
                          </a>
                        ) : (
                          <Image
                            style={{
                              maxWidth: "100px",
                              cursor: "pointer",
                              // border: "3px solid white",
                              // boxShadow: "1px 1px 10px #bbb",
                            }}
                            src={
                              process.env.REACT_APP_FILES_BASEURL +
                              q.sorular.cevapUrl
                            }
                            alt=""
                          />
                        )}
                      </>
                    ) : (
                      q.sorular.cevap
                    )
                  ) : (
                    <p>{t("main.soru.cevapAciklama")}</p>
                  )}
                </Collapse.Panel>
              </Collapse>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
}

export default MyQuestions;
