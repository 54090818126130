import { Table } from "antd";
import React from "react";
import { SYDivider } from "../../../resources/styledComponents";
import moment from "moment";

function StudentExamsList({ exams }) {
  const columns = [
    {
      title: "Öğretmen",
      dataIndex: "fullname",
    },
    {
      title: "Ders/Konu",
      dataIndex: "lesson",
    },
    {
      title: "Gönderilen T.",
      dataIndex: "createdOn",
      render: (createdOn) => (
        <span>{moment(createdOn).local().format("DD MMM YY")}</span>
      ),
    },
    {
      title: "Çözüm T.",
      dataIndex: "updatedOn",
      render: (updatedOn, record) => (
        <span>
          {record.isOk ? (
            moment(updatedOn).local().format("DD MMM YY")
          ) : (
            <span style={{ color: "red" }}>Girmedi</span>
          )}
        </span>
      ),
    },
    {
      title: "Dogru",
      dataIndex: "dogru",
      render: (dogru, record) => <span>{record.isOk ? dogru : "-"}</span>,
    },
    {
      title: "Yanlış",
      dataIndex: "yanlis",
      render: (yanlis, record) => <span>{record.isOk ? yanlis : "-"}</span>,
    },
    {
      title: "Boş",
      dataIndex: "bos",
      render: (bos, record) => <span>{record.isOk ? bos : "-"}</span>,
    },
    {
      title: "Net",
      dataIndex: "net",
      render: (net, record) => <span>{record.isOk ? net : "-"}</span>,
    },
  ];
  return (
    <div>
      <SYDivider renk="mavi">Sınav Sonuçları</SYDivider>
      <Table dataSource={exams} bordered size="small" columns={columns} />
    </div>
  );
}

export default StudentExamsList;
