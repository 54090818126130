import { Col, Row, Divider } from "antd";
import React, { useState, useEffect } from "react";
import { SYBox } from "../../resources/styledComponents";
import { HomeService } from "../../services/HomeService";

function AboutUs({ props }) {
  const [initialRender, setInitialRender] = useState(true);
  const [aboutus, setAboutus] = useState({});
  const { leftTitle, rightTitle, leftDetails, rightDetails, banner } = aboutus;
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      HomeService.getAboutUs().then(
        (data) => {
          setAboutus(data.data);
        },
        [initialRender]
      );
    }
  }, [initialRender]);
  const createMarkup = (gelenDetay) => {
    return {
      __html: gelenDetay,
    };
  };

  return (
    <SYBox bg="#f5f5f5">
      {aboutus.banner && (
        <>
          <img
            src={process.env.REACT_APP_FILES_BASEURL + banner}
            alt="banner"
            width="100%"
          />
          <SYBox>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                <SYBox
                  border="1px solid #ddd"
                  m="16px 0"
                  bg="white"
                  boxShadow="0 0 5px #eee"
                  p="16px"
                >
                  <h2>{leftTitle}</h2>
                  <div dangerouslySetInnerHTML={createMarkup(leftDetails)} />
                </SYBox>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                <SYBox
                  border="1px solid #ddd"
                  m="16px 0"
                  bg="white"
                  boxShadow="0 0 5px #eee"
                  p="16px"
                >
                  <h2>{rightTitle}</h2>
                  <div dangerouslySetInnerHTML={createMarkup(rightDetails)} />
                </SYBox>
              </Col>
            </Row>
          </SYBox>
        </>
      )}
    </SYBox>
  );
}

export default AboutUs;
