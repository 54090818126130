import React, { useEffect, useState } from "react";
import { StaffService } from "../../services/StaffService";
import { Space, Button, Divider, Table } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { giveLessonType } from "../../resources/getLessonType";

function LessonReqs() {
  const [initialRender, setInitialRender] = useState(true);
  const [lessonreqs, setLessonreqs] = useState([]);
  const router = useHistory();

  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      StaffService.getAllLessonRequests().then((data) => {
        setLessonreqs(data.data);
      });
    }
  }, [initialRender]);
  const goDetails = (id) => {
    router.push("/staff/requests/" + id);
  };
  const columns = [
    {
      title: "Ad Soyad",
      dataIndex: "isteyen",
      fixed: "left",
    },
    {
      title: "Ders",
      dataIndex: "ders",
    },
    {
      title: "Konu",
      dataIndex: "detay",
      render: (detay) => <span>{detay.konu}</span>,
    },
    {
      title: "Tür",
      dataIndex: "detay",
      render: (detay) => <span>{giveLessonType[detay.netur]}</span>,
    },
    {
      title: "Saat",
      dataIndex: "detay",
      render: (detay) => <span>{detay.saatSayisi}</span>,
    },
    {
      title: "Talep Tarihi",
      dataIndex: "detay",
      render: (detay) => (
        <span>
          {moment(detay.createdOn).local().format("DD MMM, ddd   HH:mm")}
        </span>
      ),
    },
    {
      title: "Ne Zaman",
      dataIndex: "detay",
      render: (detay) => (
        <span>
          {detay.nezaman === 1
            ? "Hemen"
            : detay.nezaman === 2
            ? "1 hafta"
            : "1 ay"}
        </span>
      ),
    },
    {
      title: "İşlem",
      key: "action",
      fixed: "right",
      render: (text, record) => (
        <Space>
          <Button
            onClick={() => goDetails(record.detay.id)}
            type="primary"
            shape="circle"
            icon={<SearchOutlined />}
          />
        </Space>
      ),
    },
  ];
  return (
    <div>
      <Divider>Ders Talepleri</Divider>
      <Table
        columns={columns}
        dataSource={lessonreqs}
        bordered
        scroll={{ x: 1200 }}
      />
    </div>
  );
}

export default LessonReqs;
