import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Carousel,
  Col,
  DatePicker,
  Empty,
  message,
  notification,
  Popconfirm,
  Row,
  Select,
  Space,
  Switch,
  Table,
  Upload,
} from "antd";
import React, { useEffect, useState } from "react";
import { SYBox, SYDivider } from "../../resources/styledComponents";
import { StaffService } from "../../services/StaffService";
import moment from "moment";
import locale from "antd/es/date-picker/locale/tr_TR";
import AdsImages from "../../components/home/AdsImages";

function BannerImages() {
  const [col, setCol] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [fileList2, setFileList2] = useState([]);
  const [fileList3, setFileList3] = useState([]);
  const [sliderDate, setSliderDate] = useState(null);
  const [initialRender, setInitialRender] = useState(true);
  const [sliders, setSliders] = useState([]);
  const [ads, setAds] = useState(null);
  const [hasActiveSlide, setHasActiveSlide] = useState(false);

  const beforeUpload = async (file, w, h) => {
    return new Promise((resolve, reject) => {
      const isJPG = file.type === "image/jpeg";
      const isPNG = file.type === "image/png";
      if (!isJPG && !isPNG) {
        message.error("Sadece resim dosyası yükleyebilirsiniz!");
      }
      const isLt1M = file.size / 1024 / 1024 < 1;
      if (!isLt1M) {
        message.error("Dosya boyutu 1MB den büyük olamaz!");
      }

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const img = document.createElement("img");
        img.src = reader.result;
        img.onload = () => {
          if (
            typeof w === "number" &&
            typeof h === "number" &&
            (img.naturalWidth !== w || img.naturalHeight !== h)
          ) {
            message.error("Banner boyutu 1920x466 olmalı!");
            reject(false);
          } else {
            (isJPG || isPNG) && isLt1M && resolve(true);
          }
        };
      };
    });
  };
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      StaffService.getSliders().then((data) => {
        setHasActiveSlide(
          data.data.findIndex((f) => f.isActive) === -1 ? true : false
        );
        setSliders(data.data);
      });
      StaffService.getAds().then((data) => {
        setAds(data.data);
      });
    }
  }, [initialRender]);

  const uploadButton = (
    <div>
      <PlusOutlined style={{ fontSize: "26px" }} />
      <div className="ant-upload-text">Foto Yükle</div>
    </div>
  );

  const handleChangeImage = ({ file, fileList }) => {
    setFileList(fileList);
  };
  const handleChangeImage2 = ({ file, fileList }) => {
    setFileList2(fileList);
  };
  const handleChangeImage3 = ({ file, fileList }) => {
    setFileList3(fileList);
  };
  const selectCol = (a) => {
    setCol(a);
  };

  const onDelete = () => {
    StaffService.deleteAds().then((data) => {
      notification.success({ message: "Reklam resimleri silindi" });
      setInitialRender(true);
    });
  };
  const onCancel = () => {
    setFileList([]);
    setFileList2([]);
    setCol(null);
  };
  const onOk = async () => {
    const values = {
      col: col,
      image1: fileList[0].response.url,
      image2: col === 1 ? null : fileList2[0].response.url,
    };
    StaffService.changeAds(values).then((data) => {
      notification.success({ message: "Reklam resimleri eklendi" });
      setInitialRender(true);
      onCancel();
    });
  };
  const dateChange = (date, dateString) => {
    setSliderDate(date);
  };
  const addSlider = () => {
    const values = {
      url: fileList3[0].response.url,
      endDate: sliderDate,
      isActive: true,
    };
    StaffService.createNewSlider(values).then((data) => {
      notification.success({ message: "Slider başarı ile eklendi" });
      setFileList3([]);
      setSliderDate(null);
      setInitialRender(true);
    });
  };

  const sliderActiveChanged = (id, val) => {
    StaffService.changeSliderActive(id, val).then((data) => {
      notification.success({ message: "Aktivasyon başarı ile değiştirildi" });
      setInitialRender(true);
    });
  };

  const deleteSlider = (id) => {
    StaffService.deleteSlider(id).then((data) => {
      notification.success({ message: "Slider başarı ile silindi" });
      setInitialRender(true);
    });
  };
  const columns = [
    {
      title: "Resim (Açmak için linke tıkla)",
      dataIndex: "url",
      render: (url) => (
        <Button
          onClick={() =>
            window.open(process.env.REACT_APP_FILES_BASEURL + url, "_blank")
          }
        >
          {url}
        </Button>
      ),
    },
    {
      title: "Son Tarih",
      dataIndex: "endDate",
      render: (endDate) => (
        <span>{moment(endDate).local().format("DD MMM YY, ddd HH:mm")}</span>
      ),
    },
    {
      title: "Aktif mi?",
      dataIndex: "isActive",
      render: (isActive, record) => (
        <Switch
          defaultChecked={isActive}
          onChange={() => sliderActiveChanged(record.id, !isActive)}
        />
      ),
    },
    {
      title: "İşlem",
      render: (text, record) => (
        <Popconfirm
          title="Silmek istediğinize emin misiniz?"
          onConfirm={() => deleteSlider(record.id)}
          okText="Evet"
          cancelText="İptal"
        >
          <Button type="primary" icon={<DeleteOutlined />} danger />
        </Popconfirm>
      ),
    },
  ];
  return (
    <SYBox>
      <SYBox border="1px solid #ddd" p={20} bg="white" mb={30}>
        <SYDivider renk="mavi">Yeni Slider Ekle</SYDivider>
        <SYBox className="clearfix">
          <Space>
            <Upload
              action={process.env.REACT_APP_API_BASEURL + "upload"}
              headers={{
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              }}
              listType="picture-card"
              fileList={fileList3}
              beforeUpload={(file) => beforeUpload(file, 1920, 466)}
              onChange={handleChangeImage3}
            >
              {fileList3.length >= 1 ? null : uploadButton}
            </Upload>
            <DatePicker
              value={sliderDate}
              onChange={dateChange}
              format="DD MMM YYYY HH:mm"
              locale={locale}
              showTime
            />
            <Button
              type="primary"
              onClick={addSlider}
              disabled={!fileList3[0] || sliderDate === null}
            >
              Ekle
            </Button>
          </Space>
          <br />
          <small>Fotoğraf ölçüleri aynı olmalıdır</small>
          <SYDivider renk="mavi">Kayıtlı Slider Resimleri</SYDivider>
          <Table dataSource={sliders} bordered size="small" columns={columns} />
        </SYBox>
        {sliders.length > 0 && (
          <Carousel autoplay>
            {sliders.map(
              (slide) =>
                slide.isActive && (
                  <img
                    key={slide.url}
                    src={process.env.REACT_APP_FILES_BASEURL + slide.url}
                    alt={slide.url}
                  />
                )
            )}
          </Carousel>
        )}
        {hasActiveSlide && (
          <Carousel autoplay>
            <img
              src={require("../../assets/images/slide-1.jpg")}
              alt="slide-1"
            />
          </Carousel>
        )}
      </SYBox>
      <SYBox border="1px solid #ddd" p={20} bg="white">
        <SYDivider renk="mavi">Reklam resimleri ekleme ekranı</SYDivider>
        <p>
          Burada yapacağınız işlem aşağıdaki mevcut kaydı silecek yeni kayıt
          oluşturacaktır. Hiç resim gözükmesin istiyorsanız mevcut kaydı kırmızı
          butonla silebilirsiniz.
        </p>
        <Select
          placeholder="Ekran kaça bölünecek?"
          onSelect={selectCol}
          value={col}
        >
          <Select.Option key="tek" value={1}>
            Tek Sütun Reklam imajı
          </Select.Option>
          <Select.Option key="iki" value={2}>
            İki Sütun Reklam imajı
          </Select.Option>
        </Select>
        <br />
        <br />
        <Row gutter={[16, 16]}>
          {col !== null && (
            <Col
              style={{ border: "1px dashed #ccc", textAlign: "center" }}
              xs={24}
              sm={24}
              md={24}
              lg={col === 1 ? 24 : 12}
              xl={col === 1 ? 24 : 12}
              xxl={col === 1 ? 24 : 12}
            >
              <>
                {" "}
                <SYBox className="clearfix">
                  <Upload
                    action={process.env.REACT_APP_API_BASEURL + "upload"}
                    headers={{
                      Authorization: `Bearer ${localStorage.getItem("token")}`,
                    }}
                    listType="picture-card"
                    fileList={fileList}
                    beforeUpload={beforeUpload}
                    onChange={handleChangeImage}
                  >
                    {fileList.length >= 1 ? null : uploadButton}
                  </Upload>
                </SYBox>
                <small>
                  Fotoğraf ölçüleri yandaki ile aynı oranda olmalıdır
                </small>
              </>
            </Col>
          )}
          {col === 2 && (
            <Col
              style={{ border: "1px dashed #ccc", textAlign: "center" }}
              xs={24}
              sm={24}
              md={24}
              lg={12}
              xl={12}
              xxl={12}
            >
              <>
                {" "}
                <SYBox className="clearfix">
                  <Upload
                    action={process.env.REACT_APP_API_BASEURL + "upload"}
                    headers={{
                      Authorization: `Bearer ${localStorage.getItem("token")}`,
                    }}
                    listType="picture-card"
                    fileList={fileList2}
                    beforeUpload={beforeUpload}
                    onChange={handleChangeImage2}
                  >
                    {fileList2.length >= 1 ? null : uploadButton}
                  </Upload>
                </SYBox>
                <small>
                  Fotoğraf ölçüleri yandaki ile aynı oranda olmalıdır
                </small>
              </>
            </Col>
          )}
        </Row>
        <Space>
          <Button
            onClick={onOk}
            type="primary"
            disabled={
              col === 1
                ? fileList.length === 0
                : fileList.length === 0 || fileList2.length === 0
            }
          >
            Kaydet
          </Button>
          <Button onClick={onCancel}>İptal</Button>
          <Popconfirm
            title="Silmek istediğinize emin misiniz?"
            onConfirm={onDelete}
            okText="Evet"
            cancelText="İptal"
          >
            <Button type="primary" danger>
              Resim Kayıtlarını sil
            </Button>
          </Popconfirm>
        </Space>
      </SYBox>
      {ads && <AdsImages ads={ads} />}
      {!ads && (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="Reklam imajı ayarlanmamış."
          style={{
            backgroundColor: "white",
            border: "1px solid #ddd",
            padding: "20px",
          }}
        ></Empty>
      )}
    </SYBox>
  );
}

export default BannerImages;
