import { axios } from "../resources/httpClient";

export const StaffService = {
  // GRUPLAR SERVISLERI #######################################
  getGropus: () => {
    return axios.get("/groups");
  },
  newGropus: (values) => {
    return axios.post("/groups", values);
  },
  changeGroupActive: (id, isActive) => {
    return axios.put("/groups/" + id, { id, isActive });
  },
  getAllGroupLessonsTask: () => {
    return axios.get("/lessontask/openAllGroupLessons");
  },
  deleteLessonTaskById: (id) => {
    return axios.delete("/lessontask/" + id);
  },
  // YEARS SERVISLERI #######################################
  getYears: () => {
    return axios.get("/years");
  },
  changeYearsActive: (id, isActive) => {
    return axios.put("/years/" + id, { id, isActive });
  },
  newYears: (values) => {
    return axios.post("/years", values);
  },
  // DERSLER SERVISLERI #######################################
  getLessons: () => {
    return axios.get("/lessons");
  },
  newLesson: (values) => {
    return axios.post("/lessons", values);
  },
  changeLessonActive: (id, isActive) => {
    return axios.put("/lessons/" + id, { id, isActive });
  },
  // ÖĞRETMEN SERVISLERI #######################################
  getTeachers: (isActive = null, take = null) => {
    return axios.get("/teachers?isActive=" + isActive + "&take=" + take);
  },
  getTeachersbyLesson: (id) => {
    return axios.get("/teachers/getteachersbylesson/" + id);
  },
  getTeacher: (id) => {
    return axios.get("/teachers/" + id);
  },
  newTeacher: (values) => {
    return axios.post("/teachers", values);
  },
  changeTip: (id, body) => {
    return axios.put("/teachers/" + id + "/tip", body);
  },
  changeTeacherIsActive: (id, deger) => {
    return axios.put("/teachers/" + id + "/active?deger=" + deger);
  },
  getTeacherStats: (id) => {
    return axios.get("/teachers/stats/" + id);
  },
  deleteImage: (id) => {
    return axios.delete("/teachers/" + id + "/image");
  },
  changeTeacherImage: (id, photo) => {
    return axios.put("/teachers/" + id + "/image?photo=" + photo);
  },
  // ÖĞRENCi SERVISLERI #######################################
  getStudents: () => {
    return axios.get("/students");
  },
  getStudent: (id) => {
    return axios.get("/students/" + id);
  },
  newStudent: (values) => {
    return axios.post("/students", values);
  },
  getStudentStats: (id) => {
    return axios.get("/students/stats/" + id);
  },
  // SINAVLAR SERVISLERI #######################################
  newExam: (values, keys, pdflink) => {
    return axios.post("/exams", { ...values, keys, pdflink });
  },
  // SCHEDULE SERVISLERI #######################################
  newSchedule: (values) => {
    return axios.post("/schedule", { dateTimes: values });
  },
  getSchedule: (id) => {
    return axios.get("/schedule/" + id);
  },

  //SINIFLAR SERVISELERI #######################################
  newKlass: (values) => {
    return axios.post("/klass", values);
  },
  getKlass: () => {
    return axios.get("/klass");
  },
  //DERS TALEPLER SERVISELERI #######################################
  getAllLessonRequests: () => {
    return axios.get("/lessonreq");
  },
  getLessonReqById: (id) => {
    return axios.get("/lessonreq/" + id);
  },
  deleteLessonReq: (id) => {
    return axios.delete("/lessonreq/" + id);
  },
  //ILETISIM TALEPLERI SERVISELERI #######################################
  getContactReqs: () => {
    return axios.get("/contactreq");
  },
  updateContactReqs: (values) => {
    return axios.put("/contactreq/" + values.id, { ...values });
  },
  //REPORTSERVISELERI #######################################
  getLessonReports: () => {
    return axios.get("/lessonreport");
  },
  //BIT SERVISLERI ############################
  newBitPackage: (values) => {
    return axios.post("/bit", { ...values });
  },
  getAllBitPackages: () => {
    return axios.get("/bit");
  },
  getHomeBitPackages: () => {
    return axios.get("/bit/home");
  },
  deleteBitPackage: (id) => {
    return axios.delete("/bit/" + id);
  },
  updateBitPackage: (id, values) => {
    return axios.put("/bit/" + id, { ...values });
  },
  getBitCardsToExcel: (values) => {
    return axios.post(
      "/bit/getcards/excel",
      { ...values },
      { responseType: "blob" }
    );
  },
  getBitCardsToJson: (values) => {
    return axios.post("/bit/getcards", { ...values });
  },
  getBitStats: () => {
    return axios.get("/bit/stats");
  },
  deleteBitOutdates: () => {
    return axios.delete("/bit/deloutdates");
  },
  //ZOOM APIS ############################
  getZoomReport: () => {
    return axios.get("/zoom/report");
  },
  deleteTeacherZoom: (id) => {
    return axios.delete("/zoom/" + id);
  },
  editTeacherZoom: (id, values) => {
    return axios.put("/zoom/" + id, { ...values });
  },
  //ADS APIS ############################

  deleteAds: () => {
    return axios.delete("/mainads");
  },
  changeAds: (values) => {
    return axios.post("/mainads", { ...values });
  },
  getAds: () => {
    return axios.get("/mainads");
  },
  createNewSlider: (values) => {
    return axios.post("/slider", { ...values });
  },
  getSliders: () => {
    return axios.get("/slider");
  },
  getHomeSliders: () => {
    return axios.get("/slider?active=true");
  },
  deleteSlider: (id) => {
    return axios.delete("/slider/" + id);
  },
  changeSliderActive: (id, value) => {
    return axios.put("/slider/" + id + "/active?value=" + value);
  },
  getStarReport: () => {
    return axios.get("/lessonreport/stats");
  },
};
