import { Button, Form, Input, notification, Space } from "antd";
import React, { useContext } from "react";
import { GlobalContext } from "../../../context/GlobalContext";
import { MainService } from "../../../services/MainService";
import { useTranslation } from "react-i18next";

function Messages({ ft, from, to, yanit }) {
  const { setOpenModal } = useContext(GlobalContext);
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const modalClose = () => {
    form.resetFields();
    setOpenModal(false);
  };

  const messageValueVer = (tip, title, message) => {
    var val;
    switch (tip) {
      case 1:
        val = {
          title,
          message,
          tip: 1,
          fromStudentId: parseInt(from),
          toTeacherId: to,
          visible: true,
          read: false,
        };
        break;
      case 2:
        val = {
          title,
          message,
          tip: 2,
          fromTeacherId: parseInt(from),
          toStudentId: to,
          visible: true,
          read: false,
        };
        break;
      case 3:
        val = {
          title,
          message,
          tip: 3,
          fromTeacherId: parseInt(from),
          toTeacherId: to,
          visible: true,
          read: false,
        };
        break;
      case 5:
        val = {
          title,
          message,
          tip: 5,
          fromTeacherId: parseInt(from),
          fromAdmin: true,
          toStudentId: to,
          visible: true,
          read: false,
        };
        break;
      case 6:
        val = {
          title,
          message,
          tip: 6,
          fromTeacherId: parseInt(from),
          fromAdmin: true,
          toTeacherId: to,
          visible: true,
          read: false,
        };
        break;
      default:
        break;
    }
    return val;
  };
  const resetForm = () => {
    form.resetFields();
  };
  const saveMessage = (value) => {
    MainService.yeniMesajKaydet(
      messageValueVer(ft, value.title, value.message)
    ).then((data) => {
      notification.success({ message: t("main.mesaj.iletildi") });
      modalClose();
    });
  };
  return (
    <div>
      <Form
        form={form}
        onFinish={saveMessage}
        onReset={resetForm}
        layout="vertical"
        initialValues={{
          title: yanit !== undefined ? t("main.mesaj.yanit") + yanit : "",
        }}
      >
        <Form.Item
          label={t("main.mesaj.baslik")}
          name="title"
          style={{ width: "100%" }}
          rules={[{ required: true, message: t("forms.messages.gerekli") }]}
        >
          <Input
            autoFocus
            placeholder={t("main.mesaj.baslikPlaceholder")}
            disabled={yanit !== undefined}
          />
        </Form.Item>
        <Form.Item
          label={t("main.dersal.mesajiniz")}
          name="message"
          rules={[{ required: true, message: t("forms.messages.gerekli") }]}
        >
          <Input.TextArea
            autoFocus={yanit !== undefined}
            style={{ width: "100%" }}
            placeholder={t("main.dersal.mesajYaz")}
            bordered
            autoSize={{ minRows: 2, maxRows: 6 }}
          />
        </Form.Item>
        <Form.Item>
          <Space>
            <Button onClick={modalClose}>{t("global.iptal")}</Button>
            <Button htmlType="reset">{t("forms.temizle")}</Button>
            <Button type="primary" htmlType="submit">
              {t("forms.gonder")}
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
}

export default Messages;
