import { Divider, Row, Col, Card, Tag, Button, Modal } from "antd";
import React, { useEffect, useState, useContext } from "react";
import { StaffService } from "../../../services/StaffService";
import { useHistory } from "react-router-dom";
import { CommentOutlined, UnorderedListOutlined } from "@ant-design/icons";
import { GlobalContext } from "../../../context/GlobalContext";
import Messages from "../Messages/Messages";
import { getMessageType } from "../../../resources/getMessageType";
import { UserContext } from "../../../context/UserContext";
import { useTranslation } from "react-i18next";

function MainTeachers() {
  const [initialRender, setInitialRender] = useState(true);
  const [teachers, setTeachers] = useState([]);
  const [messageId, setMessageId] = useState(null);
  const router = useHistory();
  const { myId, myRole } = useContext(UserContext);
  const { t } = useTranslation();

  const { openModal, setOpenModal } = useContext(GlobalContext);
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      StaffService.getTeachers().then((data) => {
        setTeachers(
          data.data.filter((data, index) => {
            return data.ogretmen.id !== 1;
          })
        );
      });
    }
  }, [initialRender]);
  const teacherSelected = (id) => {
    router.push("/main/teachers/" + id);
  };
  const messageClicked = (tid) => {
    setOpenModal(true);
    setMessageId(tid);
  };

  return (
    <div>
      <Divider style={{ color: "#1890FF" }}>
        {t("home.teachers.ogretmenListemiz")}
      </Divider>
      <Modal
        closable
        title={t("main.dersal.mesajYaz")}
        visible={openModal}
        footer={null}
        onCancel={() => setOpenModal(false)}
      >
        <Messages
          ft={
            myRole === "Student"
              ? getMessageType.OgrenciToOgretmen
              : myRole === "Admin"
              ? getMessageType.AdminToOgretmen
              : getMessageType.OgretmenToOgretmen
          }
          from={myId}
          to={messageId}
        />
      </Modal>
      <Row gutter={[16, 16]}>
        {teachers.map((teacher) => (
          <Col xs={24} sm={12} md={8} lg={6} xl={6} key={teacher.key}>
            <Card
              style={{ border: "1px solid #ddd" }}
              hoverable
              actions={[
                <Button
                  type="link"
                  size="small"
                  onClick={() => messageClicked(teacher.ogretmen.id)}
                >
                  <CommentOutlined /> {t("home.teachers.mesaj")}
                </Button>,
                <Button
                  type="link"
                  size="small"
                  onClick={() => teacherSelected(teacher.ogretmen.id)}
                >
                  <UnorderedListOutlined /> {t("main.exam.detay")}
                </Button>,
              ]}
              cover={
                <img
                  alt="example"
                  src={
                    process.env.REACT_APP_FILES_BASEURL + teacher.ogretmen.photo
                  }
                  style={{ border: "1px solid #eee" }}
                />
              }
            >
              <Card.Meta
                title={
                  <div onClick={() => teacherSelected(teacher.ogretmen.id)}>
                    {teacher.ogretmen.fullname}
                  </div>
                }
              />
              <br />
              {teacher.gruplar.map((grup) => (
                <Tag key={grup.id} color="blue">
                  {grup.name}
                </Tag>
              ))}
              <p></p>
              {teacher.dersler.map((ders) => (
                <Tag key={ders.id} color="magenta">
                  {ders.name}
                </Tag>
              ))}
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
}

export default MainTeachers;
