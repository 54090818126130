import React, { useContext, useEffect, useState } from "react";
import "../../App.css";
import { Button, Row, Col, Carousel, Grid, Space } from "antd";
import {
  FundViewOutlined,
  FilePdfOutlined,
  FileDoneOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import "./Home.css";
import UpcomingLessons from "../../components/home/UpcomingLessons";
import TeachersBar from "../../components/home/TeachersBar";
import IletisimTalep from "../../components/home/IletisimTalep";
import HomeLessonReqForm from "../../components/home/HomeLessonReqForm";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import AdsImages from "../../components/home/AdsImages";
import { StaffService } from "../../services/StaffService";
import { useTranslation } from "react-i18next";

function HomePage() {
  const { useBreakpoint } = Grid;
  const { myId } = useContext(UserContext);
  const router = useHistory();
  const [initialRender, setInitialRender] = useState(true);
  const [ads, setAds] = useState(null);
  const [sliders, setSliders] = useState(null);
  const { t } = useTranslation();
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      StaffService.getAds().then((data) => {
        setAds(data.data);
      });
      StaffService.getHomeSliders().then((data) => {
        setSliders(data.data);
      });
    }
  }, [initialRender]);
  return (
    <div>
      {useBreakpoint().md && sliders && sliders.length > 0 && (
        <Carousel autoplay>
          {sliders.map(
            (slide) =>
              slide.isActive && (
                <img
                  key={slide.url}
                  src={process.env.REACT_APP_FILES_BASEURL + slide.url}
                  alt={slide.url}
                />
              )
          )}
        </Carousel>
      )}
      {useBreakpoint().md && sliders && sliders.length === 0 && (
        <Carousel autoplay>
          <img src={require("../../assets/images/slide-1.jpg")} alt="slide-1" />
        </Carousel>
      )}
      {useBreakpoint().md && !sliders && (
        <Carousel autoplay>
          <img
            src={require("../../assets/images/slider-loading.png")}
            alt="slide-1"
          />
        </Carousel>
      )}
      {!useBreakpoint().md && (
        <Space
          direction="vertical"
          align="center"
          style={{ textAlign: "center", width: "100%" }}
        >
          <img
            src={require("../../assets/images/mobil-banner.jpg")}
            alt="mobil banner"
            width="100%"
          />
          <h1>{t("home.mobilebanner")}</h1>
        </Space>
      )}
      <Row
        justify="center"
        align="middle"
        style={{
          marginTop: !useBreakpoint().md ? "10px " : "-83px",
          marginLeft: "50px",
          marginRight: "50px",
        }}
        gutter={[16, 16]}
      >
        <Col xs={24} sm={12} md={12} lg={6} xl={6}>
          <div className="icon-col">
            <div className="banner-icon-container bir">
              <FundViewOutlined className="banner-icon" />
            </div>
          </div>
          <div
            style={{
              marginTop: "10px",
              textAlign: "center",
              padding: "15px",
            }}
          >
            <h2>
              <b>{t("home.baslik1")}</b>
            </h2>
            <h3>{t("home.detay1")}</h3>
          </div>
        </Col>

        <Col xs={24} sm={12} md={12} lg={6} xl={6}>
          <div className="icon-col">
            <div className="banner-icon-container dort">
              <FilePdfOutlined className="banner-icon" />
            </div>
          </div>
          <div
            style={{
              marginTop: "10px",
              textAlign: "center",
              padding: "15px",
            }}
          >
            <h2>
              <b>{t("home.baslik2")}</b>
            </h2>
            <h3>{t("home.detay2")}</h3>
          </div>
        </Col>
        <Col xs={24} sm={12} md={12} lg={6} xl={6}>
          <div className="icon-col">
            <div className="banner-icon-container uc">
              <TeamOutlined className="banner-icon" />
            </div>
          </div>
          <div
            style={{
              marginTop: "10px",
              textAlign: "center",
              padding: "15px",
            }}
          >
            <h2>
              <b>{t("home.baslik3")}</b>
            </h2>
            <h3>{t("home.detay3")}</h3>
          </div>
        </Col>

        <Col xs={24} sm={12} md={12} lg={6} xl={6}>
          <div className="icon-col">
            <div className="banner-icon-container iki">
              <FileDoneOutlined className="banner-icon" />
            </div>
          </div>
          <div
            style={{
              marginTop: "10px",
              textAlign: "center",
              padding: "15px",
            }}
          >
            <h2>
              <b>{t("home.baslik4")}</b>
            </h2>
            <h3>{t("home.detay4")}</h3>
          </div>
        </Col>
      </Row>
      {ads !== null && ads.id !== undefined && <AdsImages ads={ads} />}
      <br />
      <Row
        gutter={[16, 16]}
        justify="space-between"
        align="center"
        style={{ margin: "0 15px" }}
      >
        <Col xs={24} sm={12} md={12} lg={8} xl={8}>
          <div
            style={{
              width: "100%",
              backgroundColor: "#0082C8",
              borderRadius: "5px",
              border: "1px solid #ccc",
              padding: "15px",
              boxShadow: "1px 1px 15px #ccc",
            }}
          >
            <h1 className="baslik">{t("home.dersler.baslik1")}</h1>
            <p className="dersdetay">{t("home.dersler.detay1")}</p>
            <Button ghost onClick={() => router.push("/main/getlesson")}>
              {t("global.talepet")}
            </Button>
          </div>
        </Col>
        <Col xs={24} sm={12} md={12} lg={8} xl={8}>
          {" "}
          <div
            style={{
              width: "100%",
              padding: "15px",
              backgroundColor: "#6C6D29",
              border: "1px solid #ccc",
              boxShadow: "1px 1px 15px #ccc",
              borderRadius: "5px",
            }}
          >
            {" "}
            <h1 className="baslik">{t("home.dersler.baslik2")}</h1>
            <p className="dersdetay">{t("home.dersler.detay2")}</p>
            <Button ghost onClick={() => router.push("/main/getlesson")}>
              {t("global.talepet")}
            </Button>
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
          {" "}
          <div
            style={{
              padding: "15px",
              width: "100%",
              border: "1px solid #ccc",
              backgroundColor: "#D15A51",
              borderRadius: "5px",
              boxShadow: "1px 1px 15px #ccc",
            }}
          >
            <h1 className="baslik">{t("home.dersler.baslik3")}</h1>
            <p className="dersdetay">{t("home.dersler.detay3")}</p>
            <Button ghost onClick={() => router.push("/main/getlesson")}>
              {t("global.talepet")}
            </Button>
          </div>
        </Col>
      </Row>
      {(myId === null || myId === undefined) && <HomeLessonReqForm />}
      <UpcomingLessons />
      <TeachersBar />
      <IletisimTalep />
    </div>
  );
}

export default HomePage;
