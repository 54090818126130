import { Descriptions } from "antd";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { SYDivider } from "../../../resources/styledComponents";
import { StaffService } from "../../../services/StaffService";
import StudentBitChanges from "./StudentBitChanges";
import StudentCardChanges from "./StudentCardChanges";
import StudentExamsList from "./StudentExamsList";

function StudentReports({ id }) {
  const [initialRender, setInitialRender] = useState(true);
  const [stats, setStats] = useState(null);
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      StaffService.getStudentStats(id).then((data) => {
        setStats(data.data);
      });
    }
  }, [initialRender, id]);
  return (
    <div>
      <SYDivider renk="mavi">Öğrenci İstatistikleri</SYDivider>
      {stats !== null && (
        <>
          <Descriptions
            className="teacher-stats"
            size="small"
            bordered
            column={{ xxl: 4, xl: 4, lg: 4, md: 1, sm: 1, xs: 1 }}
          >
            <Descriptions.Item label="Bit Hesabı">
              {stats.bitValue} Bit
            </Descriptions.Item>
            <Descriptions.Item label="Kart Yüklemesi">
              {stats.cardTotal} Bit
            </Descriptions.Item>
            <Descriptions.Item label="Para Yüklemesi">
              {stats.bitValue - stats.cardTotal} Bit
            </Descriptions.Item>
            <Descriptions.Item label="Harcama">
              {stats.spend} Bit
            </Descriptions.Item>
            <Descriptions.Item label="Tamamlanan Ders">
              {stats.lessonReports} Adet
            </Descriptions.Item>
            <Descriptions.Item label="Verdiği Puan Ort.">
              {stats.giveStar}/5
            </Descriptions.Item>
            <Descriptions.Item label="Bekleyen Ders">
              {stats.lessons} Adet
            </Descriptions.Item>
            <Descriptions.Item label="Bekleyen Talep">
              {stats.lessonReqs} Adet
            </Descriptions.Item>
            <Descriptions.Item label="Sorduğu Soru">
              {stats.questions} Adet
            </Descriptions.Item>
            <Descriptions.Item label="Sınavlar">
              {stats.exams} Adet
            </Descriptions.Item>
            <Descriptions.Item label="Gönderdiği Mesaj">
              {stats.fromMessages} Adet
            </Descriptions.Item>
            <Descriptions.Item label="Gelen Mesaj">
              {stats.toMessages} Adet
            </Descriptions.Item>
          </Descriptions>

          <br />
          <StudentExamsList exams={stats.examResults} />
          <StudentCardChanges cards={stats.cardChanges} />
          <StudentBitChanges bits={stats.bitChanges} />
        </>
      )}
    </div>
  );
}

export default StudentReports;
