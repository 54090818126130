import React, { useState, useEffect, useContext } from "react";
import {
  Divider,
  Form,
  Input,
  Select,
  Button,
  Tooltip,
  Space,
  Switch,
  Table,
  Modal,
  notification,
} from "antd";
import { StaffService } from "../../services/StaffService";
import { iller, ilceler, getLocation } from "../../resources/il-ilce";
import {
  InfoCircleTwoTone,
  MessageOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { GlobalContext } from "../../context/GlobalContext";
import Messages from "../../components/main/Messages/Messages";
import { getMessageType } from "../../resources/getMessageType";
import { UserContext } from "../../context/UserContext";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

function Students() {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [form] = Form.useForm();
  const router = useHistory();
  const [initialRender, setInitialRender] = useState(true);
  const [years, setYears] = useState([]);
  const [students, setStudents] = useState([]);
  const [selectedIl, setSelectedIl] = useState(null);
  const [newPanel, setNewPanel] = useState(false);
  const [klass, setKlass] = useState([]);
  const [isActviveSw, setIsActviveSw] = useState(true);
  const [toId, setToId] = useState();
  const { openModal, setOpenModal } = useContext(GlobalContext);
  const { myId } = useContext(UserContext);
  function compare(a, b) {
    var atitle = a.name;
    var btitle = b.name;
    var alfabe =
      "AaBbCcÇçDdEeFfGgĞğHhIıİiJjKkLlMmNnOoÖöPpQqRrSsŞşTtUuÜüVvWwXxYyZz0123456789";
    if (atitle.length === 0 || btitle.length === 0) {
      return atitle.length - btitle.length;
    }
    for (var i = 0; i < atitle.length && i < btitle.length; i++) {
      var ai = alfabe.indexOf(atitle[i]);
      var bi = alfabe.indexOf(btitle[i]);
      if (ai !== bi) {
        return ai - bi;
      }
    }
  }
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      StaffService.getStudents().then((data) => {
        setStudents(data.data);
      });
      StaffService.getYears().then((data) => {
        setYears(data.data.years);
      });
      StaffService.getKlass().then((data) => {
        setKlass(data.data);
      });
    }
  }, [initialRender]);
  const onFinish = (values) => {
    const ctoken = executeRecaptcha("signup_page");
    ctoken.then((datam) => {
      values = { ...values, ctoken: datam };
      StaffService.newStudent(values).then((data) => {
        setStudents([...students, data.data]);
        setNewPanel(false);
        form.resetFields();
        notification.success({ message: "Öğrenci başarı ile eklendi" });
      });
    });
  };
  const ilSelected = (il) => {
    form.setFieldsValue({ ilce: null });
    setSelectedIl(il);
  };
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };

  const goDetails = (id) => {
    router.push("/staff/students/" + id);
  };
  const goMesaj = (id) => {
    setToId(id);
    setOpenModal(true);
  };
  const columns = [
    {
      title: "Ad Soyad",
      dataIndex: "student",
      render: (student) => <span>{student.fullname}</span>,
    },
    {
      title: "Sınıf",
      dataIndex: "yearname",
      render: (yearname) => <span>{yearname}</span>,
    },
    {
      title: "Konum",
      dataIndex: "student",
      render: (student) => <span>{getLocation(student.il, student.ilce)}</span>,
    },
    {
      title: "Email",
      dataIndex: "student",
      render: (student) => <span>{student.email}</span>,
    },
    {
      title: "İşlem",
      key: "action",
      render: (text, record) => (
        <Space>
          <Button
            onClick={() => goDetails(record.student.id)}
            type="primary"
            shape="circle"
            icon={<SearchOutlined />}
          />
          <Button
            onClick={() => goMesaj(record.student.id)}
            shape="circle"
            icon={<MessageOutlined />}
          />
        </Space>
      ),
    },
  ];

  const newPanelToggle = () => {
    setNewPanel(!newPanel);
  };
  return (
    <div>
      <Modal
        closable
        title="Mesajınızı yazınız"
        visible={openModal}
        footer={null}
        onCancel={() => setOpenModal(false)}
      >
        <Messages ft={getMessageType.AdminToOgrenci} from={myId} to={toId} />
      </Modal>
      <Divider>Öğrenci Tanımlama ve Listeleme Ekranı</Divider>

      <Button
        type={newPanel ? "danger" : "primary"}
        onClick={newPanelToggle}
        style={{ marginBottom: "15px" }}
      >
        {newPanel ? "Vazgeç" : "Öğrenci Ekle"}
      </Button>
      {!newPanel && (
        <div>
          {students.length > 0 && (
            <Table columns={columns} dataSource={students} bordered />
          )}
        </div>
      )}
      {newPanel && (
        <div style={{ maxWidth: "800px" }}>
          <Divider>Yeni Öğrenci Formu</Divider>
          <Form {...layout} form={form} onFinish={onFinish}>
            <Form.Item
              name="fullname"
              label="Ad Soyad"
              rules={[{ required: true, message: "Öğretmen adı gereklidir" }]}
            >
              <Input />
            </Form.Item>
            {years.length > 0 && (
              <Form.Item
                name="year"
                label="Sınıf"
                rules={[{ required: true, message: "Sınıf seçmelisiniz" }]}
              >
                <Select
                  showSearch
                  placeholder="Sınıf seçiniz"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {years.map((year) => (
                    <Select.Option value={year.id} key={year.id}>
                      {year.name}.Sınıf
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            <Form.Item
              name="klass"
              label="Şube"
              //   rules={[{ required: true, message: "Şube seçmelisiniz" }]}
            >
              <Select
                showSearch
                placeholder="Şube seçiniz"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {klass.map((kl) => (
                  <Select.Option value={kl.id} key={kl.id}>
                    {kl.sube} - {kl.detay}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="email"
              label="Email adresi"
              rules={[
                { required: true, message: "Email adresi gereklidir" },
                { type: "email", message: "Geçerli email adresi giriniz." },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="password"
              label={
                <Space>
                  <span>Şifre</span>
                  <Tooltip title="Kullanıcı daha sonra kendi şifresini belirleyebilir">
                    <InfoCircleTwoTone />
                  </Tooltip>
                </Space>
              }
              rules={[
                { required: true, message: "Şifre gereklidir" },
                {
                  min: 6,
                  message: "Şifre en az 6 haneli olmalı",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="phone"
              label={
                <Space>
                  <span>Telefon</span>
                  <Tooltip title="Öğrenciler ile paylaşılmayacaktır">
                    <InfoCircleTwoTone />
                  </Tooltip>
                </Space>
              }
              rules={[{ required: true, message: "Telefon gereklidir" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="il"
              label="İl"
              rules={[{ required: true, message: "İl seçmelisiniz" }]}
            >
              <Select
                showSearch
                onChange={ilSelected}
                placeholder="İl seçiniz"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {iller.sort(compare).map((il) => (
                  <Select.Option value={il.id} key={il.id}>
                    {il.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            {selectedIl && (
              <Form.Item
                name="ilce"
                label="İlce"
                rules={[{ required: true, message: "İlçe seçmelisiniz" }]}
              >
                <Select
                  showSearch
                  placeholder="İlçe seçiniz"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {ilceler
                    .filter((i) => i.il_id === selectedIl)
                    .sort(compare)
                    .map((ilce) => (
                      <Select.Option value={ilce.id} key={ilce.id}>
                        {ilce.name}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            )}
            <Form.Item
              name="school"
              label={
                <Space>
                  <span>Okul</span>
                </Space>
              }
              rules={[{ required: true, message: "Okul adı gereklidir" }]}
            >
              <Input placeholder="Okul adınızı yazınız" />
            </Form.Item>
            <Form.Item name="isActive" label="Aktif mi ?" initialValue={true}>
              <Switch
                checked={isActviveSw}
                onChange={() => setIsActviveSw(!isActviveSw)}
              ></Switch>
            </Form.Item>
            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit">
                Kaydet
              </Button>
            </Form.Item>
          </Form>
        </div>
      )}
    </div>
  );
}

export default Students;
