import React, { useContext, useEffect, useState } from "react";
import { MainService } from "../../../services/MainService";
import { UserContext } from "../../../context/UserContext";
import { Col, Row, Statistic } from "antd";
import { SYDivider } from "../../../resources/styledComponents";
import { Column, Pie } from "@ant-design/charts";
import { useTranslation } from "react-i18next";

function StudentDash() {
  const [initialRender, setInitialRender] = useState(true);
  const [stats, setStats] = useState({});
  const [loading, setLoading] = useState(true);
  const { myId } = useContext(UserContext);
  const { t } = useTranslation();
  const total = t("global.toplam");
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      setLoading(true);
      MainService.getMainStudentStats(myId).then((data) => {
        console.log(data.data);
        setStats(data.data);
        setLoading(false);
      });
    }
  }, [initialRender, myId]);

  var configTasks = {
    appendPadding: 20,
    xField: "type",
    yField: "value",
    columnWidthRatio: 0.8,
    meta: {
      type: { alias: t("forms.dersturu") },
      value: { alias: t("forms.derssayisi") },
    },
  };

  var configStar = (t) => {
    return {
      appendPadding: 20,
      angleField: "value",
      colorField: "type",
      color:
        t === 1
          ? ["#ED4235", "#FBBD05", "#4285F6", "#62DAAB", "#34A856", "#A4549F"]
          : ({ type }) => {
              switch (type.charAt(0)) {
                case "1":
                  return "#ED4235";
                case "2":
                  return "#FBBD05";
                case "3":
                  return "#4285F6";
                case "4":
                  return "#62DAAB";
                case "5":
                  return "#34A856";
                default:
                  break;
              }
            },
      radius: 1,
      innerRadius: 0.64,
      statistic: {
        title: {
          formatter: function formatter(v) {
            return v === undefined ? total : v.type;
          },
          style: {
            fontSize: "14px",
            paddingBottom: "5px",
          },
        },
        value: {
          formatter: function formatter(v) {
            return "".concat(v, t("global.adet"));
          },
        },
      },
      label: {
        type: "inner",
        offset: "-50%",
        style: { textAlign: "center" },
        autoRotate: false,
        content: "{percentage}",
      },
      interactions: [
        { type: "pie-legend-active" },
        { type: "element-active" },
        { type: "pie-statistic-active" },
      ],
    };
  };
  return (
    <div>
      <SYDivider renk="mavi">{t("main.mainpage.genelRapor")}</SYDivider>
      <Row gutter={[16, 16]}>
        <Col xs={12} sm={8} md={6} lg={4} xl={4} xxl={3}>
          <Statistic
            loading={loading}
            title={t("main.mainpage.toplamDers")}
            value={stats.lessonTaskCount}
            className="stats-bar"
          />
        </Col>
        <Col xs={12} sm={8} md={6} lg={4} xl={4} xxl={3}>
          <Statistic
            loading={loading}
            title={t("main.mainpage.alinacakDers")}
            value={stats.lessonTaskCount - stats.lessonReportCount}
            className="stats-bar"
          />
        </Col>
        <Col xs={12} sm={8} md={6} lg={4} xl={4} xxl={3}>
          <Statistic
            loading={loading}
            title={t("main.mainpage.bitmisDers")}
            value={stats.lessonReportCount}
            className="stats-bar"
          />
        </Col>

        <Col xs={12} sm={8} md={6} lg={4} xl={4} xxl={3}>
          <Statistic
            loading={loading}
            title={t("main.mainpage.talepSayisi")}
            value={stats.lessonReqCount}
            className="stats-bar"
          />
        </Col>
        <Col xs={12} sm={8} md={6} lg={4} xl={4} xxl={3}>
          <Statistic
            loading={loading}
            title={t("main.mainpage.soruSayisi")}
            value={stats.questionsCount}
            className="stats-bar"
          />
        </Col>
        <Col xs={12} sm={8} md={6} lg={4} xl={4} xxl={3}>
          <Statistic
            loading={loading}
            title={t("main.mainpage.sinavDurumu")}
            value={stats.examsisOkCount + "/" + stats.examsTotalCount}
            className="stats-bar"
          />
        </Col>
        <Col xs={12} sm={8} md={6} lg={4} xl={4} xxl={3}>
          <Statistic
            loading={loading}
            title={t("main.mainpage.kullanilanKart")}
            value={stats.cardCount}
            className="stats-bar"
          />
        </Col>
        <Col xs={12} sm={8} md={6} lg={4} xl={4} xxl={3}>
          <Statistic
            loading={loading}
            title={t("main.mainpage.kartBit")}
            value={stats.cardTotal}
            className="stats-bar"
          />
        </Col>

        <Col xs={12} sm={8} md={6} lg={4} xl={4} xxl={3}>
          <Statistic
            loading={loading}
            title={t("main.mainpage.gonderilenMesaj")}
            value={stats.fromMessagesCount}
            className="stats-bar"
          />
        </Col>
        <Col xs={12} sm={8} md={6} lg={4} xl={4} xxl={3}>
          <Statistic
            loading={loading}
            title={t("main.mainpage.alinanMesaj")}
            value={stats.toMessagesCount}
            className="stats-bar"
          />
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <div
            style={{
              padding: "20px",
              border: "1px solid #ddd",
              backgroundColor: "white",
              boxShadow: "0 0 5px #eee",
            }}
          >
            <SYDivider renk="mavi">{t("main.mainpage.verRapor")}</SYDivider>

            <div style={{ width: "100%" }}>
              {!loading && (
                <Pie {...configStar(0)} data={stats.lessonReportStars} />
              )}
            </div>
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <div
            style={{
              padding: "20px",
              border: "1px solid #ddd",
              boxShadow: "0 0 5px #eee",
              backgroundColor: "white",
            }}
          >
            <SYDivider renk="mavi">{t("main.mainpage.talepRapor")}</SYDivider>
            {!loading && (
              <Column {...configTasks} data={stats.lessonReqDetails} />
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default StudentDash;
