import React, { useContext, useState } from "react";
import { Layout, Menu, Row, Col, Dropdown } from "antd";
import { Switch, Route, Redirect, Link, useHistory } from "react-router-dom";
import "./Staff.css";
import {
  DashboardTwoTone,
  IdcardTwoTone,
  ContactsTwoTone,
  SettingTwoTone,
  ProfileTwoTone,
  DownOutlined,
  MessageTwoTone,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  InfoCircleTwoTone,
} from "@ant-design/icons";
import StaffDash from "./StaffDash";
import Groups from "./Groups";
import Years from "./Years";
import Lessons from "./Lessons";
import { PrivateRoute } from "../../resources/PrivateRoute";
import Teachers from "./Teachers";
import TeacherDetails from "./TeacherDetails";
import Students from "./Students";
import StudentDetails from "./StudentDetails";
//import GroupLessons from "./GroupLessons";
import Classes from "./Classes";
import LessonReqs from "./LessonReqs";
import LessonReqDetails from "./LessonReqDetails";
import ContactReqList from "./ContactReqList";
import SetLessonTask from "./lessonTask/SetLessonTask";
import MainAlerts from "./MainAlerts";
import LessonReports from "../../components/staff/reports/LessonReports";
import Bits from "../../components/staff/Bits";
import GetBitCards from "../../components/staff/GetBitCards";
import { UserContext } from "../../context/UserContext";
import ZoomSettings from "../../components/staff/zoom/ZoomSettings";
import BannerImages from "./BannerImages";
import { GlobalContext } from "../../context/GlobalContext";
import SetAboutUs from "../../components/staff/SetAboutUs";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
const { Header, Content, Footer, Sider } = Layout;

function StaffLayout() {
  const [collapsed, setCollapsed] = useState(false);
  const [bp, setBp] = useState(null);
  const router = useHistory();
  const { myFullname } = useContext(UserContext);
  const { kurum } = useContext(GlobalContext);
  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    router.push("/login");
  };
  const gohome = () => {
    router.push("/");
  };
  const toggle = () => {
    setCollapsed(!collapsed);
  };
  const menuSelected = (item) => {
    bp && setCollapsed(true);
    console.log(item);
    // router.push(item.item.props.link);
  };
  const usermenu = (
    <Menu>
      <Menu.Item onClick={gohome}>Home Page</Menu.Item>
      <Menu.Item onClick={() => router.push("/main")}>Main Page</Menu.Item>
      <Menu.Item danger onClick={logout}>
        ÇIKIŞ
      </Menu.Item>
    </Menu>
  );
  return (
    <Layout>
      <Sider
        style={
          bp
            ? {
                position: "absolute",
                minHeight: "100vh",
                zIndex: "1",
                marginTop: "65px",
                borderRight: "1px solid #ddd",
              }
            : {
                backgroundColor: "#ffffff",
                borderRight: "1px solid #ddd",
              }
        }
        breakpoint="xl"
        collapsedWidth="0"
        onBreakpoint={(broken) => {
          console.log(broken);
          setBp(broken);
          setCollapsed(broken);
        }}
        onCollapse={() => {
          // setCollapsed(true);
        }}
        theme="light"
        trigger={null}
        collapsible
        collapsed={collapsed}
      >
        {kurum && (
          <img
            className="logo"
            src={process.env.REACT_APP_FILES_BASEURL + kurum.logo}
            alt="logo"
          />
        )}
        <Menu
          mode="inline"
          defaultSelectedKeys={[]}
          style={{
            borderRight: "none",
            borderTop: "1px solid #ddd",
            marginBottom: "50px",
          }}
          onSelect={(item) => menuSelected(item)}
        >
          <Menu.Item key="1" icon={<DashboardTwoTone />}>
            <Link to="/staff/dashboard">Kontrol Paneli</Link>
          </Menu.Item>
          <Menu.SubMenu
            key="sub4"
            title={
              <span>
                <SettingTwoTone />
                <span>Tanımlamalar</span>
              </span>
            }
          >
            <Menu.Item key="2" icon={<ProfileTwoTone />}>
              <Link to="/staff/groups">Gruplar</Link>
            </Menu.Item>
            <Menu.Item key="3" icon={<ProfileTwoTone />}>
              <Link to="/staff/years">Yıllar</Link>
            </Menu.Item>
            <Menu.Item key="11" icon={<ProfileTwoTone />}>
              <Link to="/staff/classes">Sınıflar</Link>
            </Menu.Item>
            <Menu.Item key="4" icon={<ProfileTwoTone />}>
              <Link to="/staff/lessons">Dersler</Link>
            </Menu.Item>
            {/* <Menu.Item key="7" icon={<EditTwoTone />}>
              <Link to="/staff/exams">Sınavlar</Link>
            </Menu.Item> */}
            {/* <Menu.Item key="8" icon={<CalendarTwoTone />}>
              <Link to="/staff/schedule">Ajanda</Link>
            </Menu.Item> */}
          </Menu.SubMenu>
          <Menu.Item key="5" icon={<IdcardTwoTone />}>
            <Link to="/staff/teachers">Öğretmenler</Link>
          </Menu.Item>
          <Menu.Item key="21" icon={<InfoCircleTwoTone />}>
            <Link to="/staff/setaboutus">Hakkımızda Sayfası</Link>
          </Menu.Item>
          <Menu.Item key="20" icon={<IdcardTwoTone />}>
            <Link to="/staff/zoom">Zoom Ayarları</Link>
          </Menu.Item>
          <Menu.Item key="6" icon={<ContactsTwoTone />}>
            <Link to="/staff/students">Öğrenciler</Link>
          </Menu.Item>
          {/* <Menu.Item key="9" icon={<SettingTwoTone />}>
            <Link to="/staff/grouplessons">Grup Dersleri</Link>
          </Menu.Item> */}
          <Menu.Item key="12" icon={<SettingTwoTone />}>
            <Link to="/staff/requests">Ders Talepleri</Link>
          </Menu.Item>
          <Menu.Item key="13" icon={<MessageTwoTone />}>
            <Link to="/staff/contactreqs">İletişim Talepleri</Link>
          </Menu.Item>

          <Menu.Item key="15" icon={<MessageTwoTone />}>
            <Link to="/staff/setlesson">Ders Tanımlama</Link>
          </Menu.Item>
          <Menu.Item key="16" icon={<MessageTwoTone />}>
            <Link to="/staff/mainalerts">Genel Bildirimler</Link>
          </Menu.Item>
          <Menu.Item key="17" icon={<MessageTwoTone />}>
            <Link to="/staff/bannerimages">Banner Resimleri</Link>
          </Menu.Item>
          <Menu.Item key="9" icon={<MessageTwoTone />}>
            <Link to="/staff/lessonreports">Ders Raporları</Link>
          </Menu.Item>
          <Menu.Item key="18" icon={<MessageTwoTone />}>
            <Link to="/staff/bits">Bit Paketleri</Link>
          </Menu.Item>
          <Menu.Item key="19" icon={<MessageTwoTone />}>
            <Link to="/staff/getbitcard">Bit Kart Oluştur</Link>
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout>
        <Header
          className="site-layout-sub-header-background"
          style={{ padding: 0, borderBottom: "1px solid #ddd" }}
        >
          <Row
            justify="space-between"
            align="middle"
            style={{ marginRight: "15px" }}
          >
            <Col>
              {collapsed ? (
                <MenuUnfoldOutlined
                  className="trigger"
                  onClick={() => toggle()}
                />
              ) : (
                <MenuFoldOutlined
                  className="trigger"
                  onClick={() => toggle()}
                />
              )}
            </Col>
            <Col>.</Col>
            <Col>
              <Dropdown trigger={["click"]} overlay={usermenu}>
                <span
                  style={{
                    cursor: "pointer",
                    color: "#1890FF",
                    border: "1px solid #ddd",
                    padding: "8px",
                    borderRadius: "3px",
                    backgroundColor: "#fafafa",
                  }}
                >
                  <UserOutlined />
                  <span
                    className="ant-dropdown-link"
                    onClick={(e) => e.preventDefault()}
                  >
                    {myFullname || "Login"} <DownOutlined />
                  </span>
                </span>
              </Dropdown>
            </Col>
          </Row>
        </Header>
        <Content
          style={{ margin: "16px 16px 0", minHeight: "calc(100vh - 202px)" }}
        >
          <Switch>
            <Route exact path={"/staff"}>
              <Redirect to={"/staff/dashboard"} />
            </Route>
            <Route path={"/staff/dashboard"} component={StaffDash} />
            <Route path={"/staff/groups"}>
              <Groups />
            </Route>
            <PrivateRoute path="/staff/years" component={Years} />
            {/* <Route path={"/staff/years"}>
              <Years />
            </Route> */}
            <Route path={"/staff/lessons"}>
              <Lessons />
            </Route>
            <Route path={"/staff/teachers/:id"}>
              <TeacherDetails />
            </Route>
            <Route path={"/staff/teachers"}>
              <Teachers />
            </Route>
            <Route path={"/staff/zoom"} component={ZoomSettings} />
            <Route path={"/staff/students/:id"}>
              <StudentDetails />
            </Route>
            <Route path={"/staff/students"}>
              {" "}
              <GoogleReCaptchaProvider
                reCaptchaKey={process.env.REACT_APP_reCaptchaKey}
              >
                <Students />
              </GoogleReCaptchaProvider>
            </Route>
            {/* <Route path={"/staff/exams"}>
              <Exams />
            </Route> */}
            {/* <Route path={"/staff/schedule"}>
              <Schedule />
            </Route> */}
            {/* <Route path={"/staff/grouplessons"}>
              <GroupLessons />
            </Route> */}
            <Route path={"/staff/classes"}>
              <Classes />
            </Route>
            <Route path={"/staff/requests/:id"}>
              <LessonReqDetails />
            </Route>
            <Route path={"/staff/requests"}>
              <LessonReqs />
            </Route>
            <Route path={"/staff/contactreqs"}>
              <ContactReqList />
            </Route>

            <Route path={"/staff/setlesson"}>
              <SetLessonTask />
            </Route>
            <Route path={"/staff/mainalerts"}>
              <MainAlerts />
            </Route>
            <Route path={"/staff/bannerimages"}>
              <BannerImages />
            </Route>
            <Route path={"/staff/lessonreports"}>
              <LessonReports />
            </Route>
            <Route path={"/staff/bits"}>
              <Bits />
            </Route>
            <Route path={"/staff/getbitcard"}>
              <GetBitCards />
            </Route>
            <Route path={"/staff/setaboutus"}>
              <SetAboutUs />
            </Route>
            <Route>
              <Redirect to={"/home"} />
            </Route>
          </Switch>
        </Content>
        <Footer style={{ textAlign: "center", marginTop: "50px" }}>
          {kurum && kurum.name} ©2021 Created by Hypotenuse
        </Footer>
      </Layout>
    </Layout>
  );
}

export default StaffLayout;
