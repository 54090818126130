import React, { useContext, useEffect, useState } from "react";
import {
  Form,
  Input,
  Select,
  Space,
  Tooltip,
  Button,
  Checkbox,
  notification,
} from "antd";
import { InfoCircleTwoTone } from "@ant-design/icons";
import { StaffService } from "../../services/StaffService";
import { ilceler, iller } from "../../resources/il-ilce";
import { SYBox, SYDivider } from "../../resources/styledComponents";
import { SYColors } from "../../resources/SYColors";
import { Link, useHistory, useLocation } from "react-router-dom";
import { MainService } from "../../services/MainService";
import { GlobalContext } from "../../context/GlobalContext";
import { Trans, useTranslation } from "react-i18next";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

function SignupPage() {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [form] = Form.useForm();
  const [initialRender, setInitialRender] = useState(true);
  const [years, setYears] = useState([]);
  //const [students, setStudents] = useState([]);
  const [selectedIl, setSelectedIl] = useState(null);
  const [licence, setLicence] = useState(false);
  const location = useLocation();
  const { veri } = location.state || { veri: null };
  const { kurum } = useContext(GlobalContext);
  const { t } = useTranslation();
  const router = useHistory();
  function compare(a, b) {
    var atitle = a.name;
    var btitle = b.name;
    var alfabe =
      "AaBbCcÇçDdEeFfGgĞğHhIıİiJjKkLlMmNnOoÖöPpQqRrSsŞşTtUuÜüVvWwXxYyZz0123456789";
    if (atitle.length === 0 || btitle.length === 0) {
      return atitle.length - btitle.length;
    }
    for (var i = 0; i < atitle.length && i < btitle.length; i++) {
      var ai = alfabe.indexOf(atitle[i]);
      var bi = alfabe.indexOf(btitle[i]);
      if (ai !== bi) {
        return ai - bi;
      }
    }
  }
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);

      StaffService.getYears().then((data) => {
        setYears(data.data.years);
        if (data.data.years.length === 0) {
          notification.error({ message: t("login.yillarYok") });
        }
      });
    }
  }, [initialRender]);
  const onFinish = (values) => {
    const ctoken = executeRecaptcha("signup_page");
    ctoken.then((datam) => {
      values = { ...values, ctoken: datam };
      StaffService.newStudent(values).then((data) => {
        form.resetFields();
        notification.success({
          message: t("global.basarili"),
          description: t("login.kayitYapildi"),
        });
        if (veri !== null) {
          var reqData = { ...veri, isteyenId: data.data.student.id };
          MainService.yeniDersTalebi(reqData).then((data) => {
            notification.success({
              message: t("global.basarili"),
              description: t("login.talep"),
            });
          });
        }
        router.push("/login/loginpage");
      });
    });
  };
  const ilSelected = (il) => {
    form.setFieldsValue({ ilce: null });
    setSelectedIl(il);
  };
  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  };
  const tailLayout = {
    wrapperCol: { offset: 6, span: 18 },
  };
  return (
    <SYBox p="30px 5px" className="login-page2">
      <SYBox
        className="login-form-container"
        style={{ maxWidth: "800px", margin: "0 auto" }}
        p="30px"
        border="1px solid #ddd"
        bg={SYColors.f5}
      >
        <div style={{ textAlign: "center" }}>
          {kurum && (
            <img
              src={process.env.REACT_APP_FILES_BASEURL + kurum.logo}
              alt="logo"
              width="200px"
            />
          )}
        </div>
        <SYDivider renk="mavi">{t("login.yeniKayit")}</SYDivider>
        <Form {...layout} form={form} onFinish={onFinish}>
          <Form.Item
            name="fullname"
            label={t("forms.adsoyad")}
            rules={[{ required: true, message: t("forms.messages.gerekli") }]}
          >
            <Input placeholder={t("login.adsoyadYaz")} />
          </Form.Item>
          {years.length > 0 && (
            <Form.Item
              name="year"
              label={t("forms.sinif")}
              rules={[{ required: true, message: t("forms.messages.gerekli") }]}
            >
              <Select
                showSearch
                placeholder={t("forms.placeholders.sinifSec")}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {years.map((year) => (
                  <Select.Option value={year.id} key={year.id}>
                    {year.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
          {/* <Form.Item
          name="klass"
          label="Şube"
          rules={[{ required: true, message: "Şube seçmelisiniz" }]}
        >
          <Select
            showSearch
            placeholder="Şube seçiniz"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {klass.map((kl) => (
              <Select.Option value={kl.id}>
                {kl.sube} - {kl.detay}
              </Select.Option>
            ))}
          </Select>
        </Form.Item> */}
          <Form.Item
            name="email"
            label={t("login.emailAdres")}
            rules={[
              { required: true, message: t("forms.messages.gerekli") },
              { type: "email", message: t("login.gecerliMail") },
            ]}
          >
            <Input placeholder={t("login.email")} />
          </Form.Item>
          <Form.Item
            label={t("login.sifreniz")}
            name="password"
            rules={[
              {
                required: true,
                message: t("login.sifre"),
              },
              {
                pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,25}$/,
                message: t("login.sifreRule"),
              },
            ]}
            hasFeedback
          >
            <Input.Password placeholder={t("login.sifre")} />
          </Form.Item>

          <Form.Item
            label={t("login.tekrar")}
            name="confirm"
            dependencies={["password"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: t("login.dogrula"),
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(t("login.eslesmiyor"));
                },
              }),
            ]}
          >
            <Input.Password placeholder={t("login.tekrar")} />
          </Form.Item>
          <Form.Item
            name="phone"
            label={
              <Space>
                <span>{t("forms.telefon")}</span>
                <Tooltip title={t("login.paylasilmaz")}>
                  <InfoCircleTwoTone />
                </Tooltip>
              </Space>
            }
            rules={[{ required: true, message: t("forms.messages.gerekli") }]}
          >
            <Input placeholder={t("forms.placeholders.telyaz")} />
          </Form.Item>

          <Form.Item
            name="il"
            label={t("login.il")}
            rules={[{ required: true, message: t("forms.messages.gerekli") }]}
          >
            <Select
              showSearch
              onChange={ilSelected}
              placeholder={t("login.ilSec")}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {iller.sort(compare).map((il) => (
                <Select.Option value={il.id} key={il.id}>
                  {il.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          {selectedIl && (
            <Form.Item
              name="ilce"
              label={t("login.ilce")}
              rules={[{ required: true, message: t("forms.messages.gerekli") }]}
            >
              <Select
                showSearch
                placeholder={t("login.ilceSec")}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {ilceler
                  .filter((i) => i.il_id === selectedIl)
                  .sort(compare)
                  .map((ilce) => (
                    <Select.Option key={ilce.id} value={ilce.id}>
                      {ilce.name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          )}
          <Form.Item
            name="school"
            label={
              <Space>
                <span>{t("login.okul")}</span>
              </Space>
            }
            rules={[{ required: true, message: t("forms.messages.gerekli") }]}
          >
            <Input placeholder={t("login.okulYaz")} />
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Checkbox checked={licence} onChange={() => setLicence(!licence)}>
              <Trans i18nKey="login.sozlesmeOk">
                {" "}
                {/* Bunu Silme */}
                <Link to="/login/agreement" target="_blank">
                  Kullanım Sözleşmesi
                </Link>
                'ni okudum ve kabul ettim.
              </Trans>
            </Checkbox>
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Space>
              <Button type="primary" htmlType="submit" disabled={!licence}>
                {t("global.kaydet")}
              </Button>
              <Button onClick={() => router.goBack()}>
                {t("global.iptal")}
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </SYBox>
    </SYBox>
  );
}

export default SignupPage;
