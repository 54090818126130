import React from "react";
import DashGlobalReport from "../../components/staff/reports/DashGlobalReport";

function StaffDash() {
  return (
    <div>
      <DashGlobalReport />
    </div>
  );
}

export default StaffDash;
