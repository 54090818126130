import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { StaffService } from "../../services/StaffService";
import { Descriptions, Space, Switch } from "antd";
import Title from "antd/lib/typography/Title";
import { getLocation } from "../../resources/il-ilce";
import StudentReports from "../../components/staff/reports/StudentReports";

function StudentDetails() {
  const { id } = useParams();
  const [initialRender, setInitialRender] = useState(true);
  const [student, setStudent] = useState(null);

  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      StaffService.getStudent(id).then((data) => {
        setStudent(data.data);
      });
    }
  }, [initialRender, id]);
  return (
    student && (
      <div
        style={{
          backgroundColor: "white",
          padding: "20px",
          border: "1px solid #ddd",
        }}
      >
        <Descriptions
          size="small"
          title={
            <Space align="">
              <Title level={2} style={{ fontWeight: 700 }}>
                {student.student.fullname}
              </Title>
              <Switch defaultChecked={student.student.isActive}></Switch>
            </Space>
          }
          bordered
          column={{ xxl: 2, xl: 2, lg: 1, md: 1, sm: 1, xs: 1 }}
        >
          <Descriptions.Item label="Email">
            {student.student.email}
          </Descriptions.Item>
          <Descriptions.Item label="Konum">
            {getLocation(student.student.il, student.student.ilce)}
          </Descriptions.Item>
          <Descriptions.Item label="Sınıf">
            {student.yearname}
          </Descriptions.Item>
          <Descriptions.Item label="Telefon">
            {student.student.phone}
          </Descriptions.Item>
          <Descriptions.Item label="Okul">
            {student.student.school}
          </Descriptions.Item>
        </Descriptions>
        <br />
        <StudentReports id={id} />
      </div>
    )
  );
}

export default StudentDetails;
