import React, { useState, useEffect, useContext } from "react";
import { Divider, Button, notification } from "antd";
import ScheduleSelector from "react-schedule-selector";
import { StaffService } from "../../../services/StaffService";
import format from "date-fns/format";
import { SYSpan } from "../../../resources/styledComponents";
import moment from "moment";
import { SYColors } from "../../../resources/SYColors";
import { MainService } from "../../../services/MainService";
import { UserContext } from "../../../context/UserContext";
import { useTranslation } from "react-i18next";

function Schedule() {
  const [schedule, setSchedule] = useState([]);
  const [appos, setAppos] = useState([]);
  const [initialRender, setInitialRender] = useState(true);
  const { myId } = useContext(UserContext);
  const { t } = useTranslation();
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      MainService.getScheduleAppos(myId).then((data) => {
        const tarihler = [];
        data.data.forEach((a) => {
          tarihler.push(a.dateTime);
        });
        setAppos(tarihler);
        StaffService.getSchedule(myId).then((data) => {
          var tmp = [];
          data.data.forEach((s) => {
            if (new Date().getTime() < Date.parse(s.dateTime)) {
              tmp.push(s.dateTime);
            }
          });
          setSchedule(tmp);
        });
      });
    }
  }, [initialRender, myId]);

  const handleChange = (newSchedule) => {
    setSchedule(newSchedule);
  };

  const renderCustomDateCell = (time, selected, innerRef) => (
    <div style={{ textAlign: "center" }} ref={innerRef}>
      <div
        style={{
          backgroundColor:
            appos.findIndex(
              (b) =>
                moment(b).format("YYYY-MM-DDTHH:mm:00") ===
                moment(time).format("YYYY-MM-DDTHH:mm:00")
            ) !== -1
              ? SYColors.red
              : selected
              ? SYColors.successbg
              : SYColors.infobg,
        }}
      >
        {appos.findIndex(
          (b) =>
            moment(b).format("YYYY-MM-DDTHH:mm:00") ===
            moment(time).format("YYYY-MM-DDTHH:mm:00")
        ) !== -1
          ? t("main.ajanda.dolu")
          : selected
          ? t("main.ajanda.acik")
          : "-"}
      </div>
    </div>
  );

  const saveSchedule = () => {
    var tmp1 = appos.map((a) => {
      return moment(a).format("DD-MM-YYYY HH:mm:00");
    });
    var tmp2 = schedule.map((a) => {
      return moment(a).format("DD-MM-YYYY HH:mm:00");
    });
    if (tmp1.some((r) => tmp2.includes(r))) {
      notification.error({
        message: t("main.ajanda.hata"),
      });
    } else {
      var tmp = schedule.map((s) => {
        var rdata = format(new Date(s), "YYYY-MM-DDTHH:mm:ss");
        return rdata;
      });
      StaffService.newSchedule(tmp).then((data) => {
        notification.success({
          message: t("main.ajanda.mesaj"),
        });
      });
    }
  };
  return (
    <div>
      <Divider>{t("main.ajanda.baslik")}</Divider>
      <div className="parent">
        <div className="child">
          {schedule && (
            <ScheduleSelector
              selection={schedule}
              numDays={15}
              minTime={8}
              maxTime={24}
              onChange={handleChange}
              renderDateCell={renderCustomDateCell}
              renderTimeLabel={(date) => (
                <SYSpan color="#1890FF" textAlign="center">
                  {moment(date).format("HH:mm")}
                </SYSpan>
              )}
              renderDateLabel={(date) => (
                <SYSpan color="#1890FF" textAlign="center">
                  {moment(date).local().format("DD MMM ddd")}
                </SYSpan>
              )}
            />
          )}
        </div>
      </div>

      <Button type="primary" onClick={saveSchedule}>
        {t("global.kaydet")}
      </Button>
    </div>
  );
}

export default Schedule;
