export const SYColors = {
  blue: "#1890FF",
  darkblue: "#2E77D0",
  green: "#34A856",
  orange: "#FBBD05",
  red: "#DB2829",
  tomato: "#ED4235",
  purple: "#CA67C5",
  f5: "#f5f5f5",
  fa: "#fafafa",
  dd: "#ddd",
  cc: "#ccc",
  greytext: "#525659",
  darkbg: "#37363A",
  errorbg: "#FFE8E7",
  errorborder: "#DB2829",
  warningbg: "#FFF8DC",
  warningborder: "#B58106",
  successbg: "#E5F9E7",
  successborder: "#1EBC30",
  infobg: "#DFF0FF",
  infoborder: "#2185D1",
};
