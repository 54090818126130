import {
  Form,
  Input,
  Upload,
  Space,
  Button,
  message,
  notification,
} from "antd";
import React, { useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { getMyData } from "../../../resources/getUserData";
import { MainService } from "../../../services/MainService";
import { useContext } from "react";
import { GlobalContext } from "../../../context/GlobalContext";
import { UserContext } from "../../../context/UserContext";
import { useTranslation } from "react-i18next";

function AnswerQuestionForm({ id, sir, tid }) {
  const { unreadQuestions, setUnreadQuestions } = useContext(GlobalContext);
  const { myId } = useContext(UserContext);
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const { t } = useTranslation();
  function beforeUpload(file) {
    const isPDF = file.type === "application/pdf";
    const isJPG = file.type === "image/jpeg";
    const isPNG = file.type === "image/png";
    if (!isPDF && !isJPG && !isPNG) {
      message.error(t("forms.upload.imgPdf"));
    }
    const isLt1M = file.size / 1024 / 1024 < 2;
    if (!isLt1M) {
      message.error(t("forms.upload.boyut"));
    }
    return (isPDF || isJPG || isPNG) && isLt1M;
  }
  const onFileChange = (info) => {
    // file.status is empty when beforeUpload return false
    setFileList(info.fileList.filter((file) => !!file.status));
  };
  const onReset = () => {
    form.resetFields();
    setFileList([]);
  };
  const onFinish = (values) => {
    values = {
      ...values,
      cevapUrl: fileList.length > 0 ? fileList[0].response.url : null,
      teacherId: parseInt(getMyData.getMyId()),
    };

    MainService.yeniCevapGir(values, id).then((data) => {
      form.resetFields();
      setFileList([]);
      if (myId === tid) {
        setUnreadQuestions(unreadQuestions - 1);
      }
      sir(true);
      notification.success({
        message: t("global.basarili"),
        description: t("main.soru.cevapIletildi"),
      });
    });
  };
  return (
    <div>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item
          name="cevap"
          rules={[{ required: true, message: t("main.soru.detayGir") }]}
        >
          <Input.TextArea
            autoSize
            style={{ minHeight: "80px" }}
            placeholder={t("main.soru.detayGir")}
          />
        </Form.Item>

        <Form.Item label={<small>{t("main.soru.dosyaEkle")}</small>}>
          <div>
            <Upload
              action={process.env.REACT_APP_API_BASEURL + "upload"}
              headers={{
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              }}
              fileList={fileList}
              beforeUpload={beforeUpload}
              onChange={onFileChange}
            >
              {fileList.length >= 1 ? null : (
                <Button icon={<UploadOutlined />}>
                  {t("forms.upload.dosyaSec")}{" "}
                </Button>
              )}
            </Upload>
          </div>
        </Form.Item>
        <Form.Item>
          <Space>
            <Button type="primary" htmlType="submit">
              {t("forms.gonder")}
            </Button>
            <Button htmlType="button" onClick={onReset}>
              {t("forms.temizle")}
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
}

export default AnswerQuestionForm;
