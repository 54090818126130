import React, { useState, useEffect } from "react";
import { StaffService } from "../../services/StaffService";
import {
  List,
  Switch,
  Divider,
  Row,
  Col,
  Input,
  Form,
  Button,
  Space,
  Select,
  notification,
} from "antd";

function Years() {
  const [form] = Form.useForm();
  const [initialRender, setInitialRender] = useState(true);
  const [years, setYears] = useState([]);
  const [groups, setGroups] = useState([]);
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      StaffService.getYears().then((data) => {
        setYears(data.data.years);
        setGroups(data.data.groups);
      });
    }
  }, [initialRender]);
  const onActiveChange = (index, item) => {
    StaffService.changeYearsActive(item.id, !item.isActive).then((data) => {
      notification.success({
        message: "Başarılı...",
        description: "İşlem başarı ile yapıldı...",
      });
    });
  };
  const onReset = () => {
    form.resetFields();
  };
  const onFinish = (values) => {
    StaffService.newYears(values).then((data) => {
      const ind = groups.findIndex((g) => g.id === data.data.groupId);
      const itm = data.data;
      itm.grup = groups[ind].name;
      setYears([...years, itm]);
      form.resetFields();
      notification.success({
        message: "Başarılı",
        description: "Sınıf başarı ile eklendi.",
      });
    });
  };
  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  };
  const tailLayout = {
    wrapperCol: { offset: 6, span: 18 },
  };
  return (
    <div>
      <Divider>Sınıf Türlerini Tanımlama ve Listeleme Ekranı</Divider>
      <Row gutter={[24, 24]}>
        <Col sm={24} xs={24} md={24} lg={12} xl={12}>
          <div style={{ backgroundColor: "white" }}>
            <List
              header={
                <div style={{ color: "#1890FF", fontSize: "16px" }}>
                  Kayıtlı Sınıflar Listesi
                </div>
              }
              footer={
                <div style={{ color: "#1890FF" }}>
                  {years.length} adet kayıtlı sınıfınız bulunuyor
                </div>
              }
              bordered
              dataSource={years}
              renderItem={(item, index) => (
                <List.Item>
                  <List.Item.Meta title={item.name} description={item.grup} />
                  <div style={{ float: "right" }}>
                    <Switch
                      onChange={() => onActiveChange(index, item)}
                      defaultChecked={item.isActive}
                    />
                  </div>
                </List.Item>
              )}
            />
          </div>
        </Col>
        <Col sm={24} xs={24} md={24} lg={12} xl={12}>
          {" "}
          <div
            style={{
              border: "1px solid #ddd",
              padding: "20px",
              backgroundColor: "#fff",
            }}
          >
            <Divider>Yeni Sınıf Ekle</Divider>
            <Form {...layout} form={form} onFinish={onFinish}>
              <Form.Item
                name="name"
                label="Sınıf Adı"
                rules={[{ required: true, message: "Sınıf Adı gereklidir" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="groupId"
                label="Grup"
                rules={[{ required: true, message: "Grup seçmek zorunludur." }]}
              >
                <Select placeholder="Grup Seçiniz" allowClear>
                  {groups.map((grup) => (
                    <Select.Option key={grup.id} value={grup.id}>
                      {grup.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="isActive" label="Aktif mi ?" initialValue={true}>
                <Switch defaultChecked={true}></Switch>
              </Form.Item>
              <Form.Item {...tailLayout}>
                <Space>
                  <Button type="primary" htmlType="submit">
                    Kaydet
                  </Button>
                  <Button htmlType="button" onClick={onReset}>
                    Sıfırla
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Years;
