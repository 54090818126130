import {
  Button,
  Descriptions,
  Divider,
  Row,
  Col,
  notification,
  Radio,
  Space,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { MainService } from "../../../services/MainService";
import moment from "moment";
import { UserContext } from "../../../context/UserContext";
import { SYDivider, SYP } from "../../../resources/styledComponents";
import { Pie } from "@ant-design/charts";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { SYColors } from "../../../resources/SYColors";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "../../../context/GlobalContext";

function StudentExamDetails() {
  const location = useLocation();
  const { id } = useParams();
  const { myId } = useContext(UserContext);
  const [cevaplar, setCevaplar] = useState(null);
  const [initialRender, setInitialRender] = useState(true);
  const [numberDisabled, setNumberDisabled] = useState(false);
  const [choiceNumber, setChoiceNumber] = useState(5);
  const [veri, setVeri] = useState(null);
  const [stats, setStats] = useState([]);
  const router = useHistory();
  const { verim } = location.state || { veri: null };
  const { t } = useTranslation();
  const { unreadExams, setUnreadExams } = useContext(GlobalContext);

  const [keys, setKeys] = useState(null);
  useEffect(() => {
    if (initialRender) {
      console.log("veri:", verim);

      setInitialRender(false);
      MainService.getStudentExambyId(
        verim !== undefined ? verim : myId,
        id
      ).then((data) => {
        setVeri(data.data);
        const tmp = data.data;
        setStats([
          {
            type: t("main.exam.dogru"),
            value: tmp.res.dogru,
          },
          {
            type: t("main.exam.yanlis"),
            value: tmp.res.yanlis,
          },
          {
            type: t("main.exam.bos"),
            value: tmp.res.bos,
          },
        ]);
        setChoiceNumber(data.data.exam.choice);
        setKeys(
          data.data.exam.keys !== null ? data.data.exam.keys.split("") : []
        );
        if (!data.data.res.isOk) {
          setCevaplar(
            Array.from({ length: data.data.exam.questions }, (v, k) => "X")
          );
        } else {
          const aa = data.data.res.answers.split("");
          setCevaplar(aa);
        }
      });
    }
  }, [initialRender, veri, id, myId, verim, t]);
  const answerSelected = (a, index) => {
    !numberDisabled && setNumberDisabled(true);
    let temp = cevaplar;
    temp[index] = a.target.value;
    setCevaplar(temp);
  };
  const sendAnswers = () => {
    const values = {
      cevaplar: cevaplar.join(""),
      examId: veri.res.examId,
      studentId: veri.res.studentId,
    };
    MainService.saveExamAnswers(values).then((data) => {
      notification.success({
        message: t("global.basarili"),
        description: t("main.exam.tamamlandi"),
      });
      setUnreadExams(unreadExams > 0 ? unreadExams - 1 : 0);
      setInitialRender(true);
    });
  };
  var configStar = {
    appendPadding: 20,
    angleField: "value",
    colorField: "type",
    color: ({ type }) => {
      return type === t("main.exam.dogru")
        ? "#34A856"
        : type === t("main.exam.yanlis")
        ? "#ED4235"
        : "#FBBD05";
    },
    radius: 1,
    innerRadius: 0.64,

    statistic: {
      title: {
        formatter: function formatter(v) {
          return v === undefined ? t("global.toplam") : v.type;
        },
        style: {
          fontSize: "14px",
          paddingBottom: "5px",
        },
      },
      value: {
        formatter: function formatter(v) {
          return "".concat(v, t("global.adet"));
        },
      },
    },
    label: {
      type: "inner",
      offset: "-50%",
      style: { textAlign: "center" },
      autoRotate: false,
      content: "{value}\n{percentage}",
    },
    interactions: [
      { type: "pie-legend-active" },
      { type: "element-active" },
      { type: "pie-statistic-active" },
    ],
  };

  return (
    <div
      style={{
        border: "1px solid #ddd",
        padding: "15px",
        backgroundColor: "white",
      }}
    >
      <Button
        type="primary"
        onClick={() => router.goBack()}
        icon={<ArrowLeftOutlined />}
      >
        Geri
      </Button>
      <br />
      <br />
      {veri === null && <p>{t("global.yukleniyor")}</p>}
      {veri !== null && (
        <div>
          <SYP fontSize="20px" color={SYColors.blue}>
            {veri.exam.title}
          </SYP>
          <p>{veri.exam.description}</p>
          <p>
            {moment(veri.res.createdOn).local().format("DD MMM, ddd   HH:mm")}
          </p>
          <Descriptions
            size="small"
            bordered
            column={{ xxl: 2, xl: 2, lg: 1, md: 1, sm: 1, xs: 1 }}
            style={{ backgroundColor: "white" }}
          >
            <Descriptions.Item label={t("main.exam.ders")}>
              {veri.lesson.name}
            </Descriptions.Item>
            <Descriptions.Item label={t("main.exam.secSayisi")}>
              {veri.exam.choice}
            </Descriptions.Item>
            <Descriptions.Item label={t("main.exam.soruSayisi")}>
              {veri.exam.questions}
            </Descriptions.Item>
            <Descriptions.Item label={t("main.exam.sinavPdf")}>
              <Button
                type="link"
                onClick={() =>
                  window.open(
                    process.env.REACT_APP_FILES_BASEURL + veri.exam.pdflink,
                    "_blank"
                  )
                }
              >
                {veri.exam.pdflink.split("-").pop()}
              </Button>
            </Descriptions.Item>
          </Descriptions>
          {veri.res.isOk && <Divider>{t("main.exam.sonuclar")}</Divider>}
          {veri.res.isOk && (
            <Descriptions
              size="small"
              bordered
              column={{ xxl: 2, xl: 2, lg: 1, md: 1, sm: 1, xs: 1 }}
              style={{ backgroundColor: "white" }}
            >
              <Descriptions.Item label={t("main.exam.dogru")}>
                {veri.res.dogru}
              </Descriptions.Item>
              <Descriptions.Item label={t("main.exam.yanlis")}>
                {veri.res.yanlis}
              </Descriptions.Item>
              <Descriptions.Item label={t("main.exam.bos")}>
                {veri.res.bos}
              </Descriptions.Item>
              {choiceNumber !== 2 && (
                <Descriptions.Item label={t("main.exam.net")}>
                  {veri.res.net.toFixed(2)}
                </Descriptions.Item>
              )}
            </Descriptions>
          )}
          <br />

          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
              <div
                className="cadiv"
                style={{ maxWidth: `${(choiceNumber + 2) * 38}px` }}
              >
                <Divider>{t("main.exam.cevaplar")}</Divider>
                {cevaplar &&
                  cevaplar.map((cevap, index) => (
                    <div
                      className={index % 2 === 0 ? "abg" : "bbg"}
                      key={index}
                    >
                      <span style={{ fontWeight: "bold", fontSize: "16px" }}>
                        {index + 1}.{" "}
                      </span>
                      <Radio.Group
                        defaultValue={cevaplar[index]}
                        buttonStyle="solid"
                        size="small"
                        onChange={(a) => answerSelected(a, index)}
                        //     disabled={veri.res.isOk}
                      >
                        <Space>
                          <Radio.Button
                            className="crb"
                            value="A"
                            disabled={veri.res.isOk}
                          >
                            {choiceNumber === 2 ? "D" : "A"}
                          </Radio.Button>
                          <Radio.Button
                            className="crb"
                            value="B"
                            disabled={veri.res.isOk}
                          >
                            {choiceNumber === 2 ? "Y" : "B"}
                          </Radio.Button>
                          {choiceNumber > 2 && (
                            <Radio.Button
                              className="crb"
                              value="C"
                              disabled={veri.res.isOk}
                            >
                              C
                            </Radio.Button>
                          )}
                          {choiceNumber > 3 && (
                            <Radio.Button
                              className="crb"
                              value="D"
                              disabled={veri.res.isOk}
                            >
                              D
                            </Radio.Button>
                          )}
                          {choiceNumber > 4 && (
                            <Radio.Button
                              className="crb"
                              value="E"
                              disabled={veri.res.isOk}
                            >
                              E
                            </Radio.Button>
                          )}
                          {!veri.res.isOk && (
                            <Radio.Button
                              className="crb irb"
                              value="X"
                              disabled={veri.res.isOk}
                            >
                              X
                            </Radio.Button>
                          )}
                          {veri.res.isOk && (
                            <>
                              <Divider type="vertical"></Divider>
                              <Button
                                size="small"
                                className="crb irb"
                                type={
                                  cevaplar[index] === "X" || keys[index] === "X"
                                    ? "default"
                                    : "primary"
                                }
                                danger={cevaplar[index] !== keys[index]}
                                style={{
                                  backgroundColor:
                                    cevaplar[index] === keys[index]
                                      ? "#34A856"
                                      : null,
                                }}
                              >
                                {choiceNumber !== 2
                                  ? keys[index]
                                  : keys[index] === "A"
                                  ? "D"
                                  : "Y"}
                              </Button>
                            </>
                          )}
                        </Space>
                      </Radio.Group>
                    </div>
                  ))}
                <br />
                {!veri.res.isOk && (
                  <Button block onClick={sendAnswers}>
                    {t("forms.gonder")}
                  </Button>
                )}
              </div>
            </Col>
            {veri.res.isOk && (
              <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                <div
                  style={{
                    border: "1px solid #ddd",
                    backgroundColor: "white",
                    boxShadow: "0 0 5px #eee",
                  }}
                >
                  <SYDivider renk="mavi">
                    {t("main.exam.sonucAnaliz")}
                  </SYDivider>
                  <div style={{ width: "100%" }}>
                    {<Pie {...configStar} data={stats} />}
                  </div>
                </div>
              </Col>
            )}
          </Row>
        </div>
      )}
    </div>
  );
}

export default StudentExamDetails;
