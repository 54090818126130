import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "nprogress/nprogress.css";
import "react-quill/dist/quill.snow.css";
import "./i18n";
import { Router } from "react-router-dom";
import { GlobalContextProvider } from "./context/GlobalContext";
import { UserContextProvider } from "./context/UserContext";
import { axHistory } from "./resources/axRouter";
import { ConfigProvider } from "antd";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
//import trTR from "antd/es/locale/tr_TR";
import "moment/locale/tr";
import locale from "antd/lib/locale/tr_TR";
const Loader = () => (
  <div className="loader-bg">
    <img
      src={require("./assets/images/progress.png")}
      alt="logo"
      width="250px"
    />
    <br />
    <div className="sk-folding-cube">
      <div className="sk-cube1 sk-cube"></div>
      <div className="sk-cube2 sk-cube"></div>
      <div className="sk-cube4 sk-cube"></div>
      <div className="sk-cube3 sk-cube"></div>
    </div>
  </div>
);
ReactDOM.render(
  <Suspense fallback={<Loader />}>
    <Router history={axHistory}>
      <UserContextProvider>
        <GlobalContextProvider>
          <ConfigProvider locale={locale}>
            <App />
          </ConfigProvider>
        </GlobalContextProvider>
      </UserContextProvider>
    </Router>
  </Suspense>,
  document.getElementById("root")
);
