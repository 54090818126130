import React, { useEffect, useState } from "react";
import { SYBox, SYDivider } from "../../resources/styledComponents";
import locale from "antd/es/date-picker/locale/tr_TR";
import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  notification,
  Popconfirm,
  Select,
  Space,
  Table,
} from "antd";
import { SYColors } from "../../resources/SYColors";
import { StaffService } from "../../services/StaffService";
import moment from "moment";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

function Bits() {
  const [bits, setBits] = useState([]);
  const [initialRender, setInitialRender] = useState(true);
  const [bitId, setBitId] = useState(null);
  const [form] = Form.useForm();
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      StaffService.getAllBitPackages().then((data) => {
        setBits(data.data);
      });
    }
  }, [initialRender]);
  const onReset = () => {
    form.resetFields();
  };

  const onFinish = (values) => {
    values = {
      ...values,
      endDate:
        values.endDate !== undefined && values.endDate !== null
          ? values.endDate.format("YYYY-MM-DDTHH:mm:ss")
          : null,
    };
    if (bitId === null) {
      StaffService.newBitPackage(values).then((data) => {
        setInitialRender(true);
        notification.success({
          message: "Başarılı",
          description: "Bit paketi başarı ile eklendi",
        });
        onReset();
      });
    } else {
      StaffService.updateBitPackage(bitId, values).then((data) => {
        setInitialRender(true);
        notification.success({
          message: "Başarılı",
          description: "Bit paketi başarı ile güncellendi",
        });
        onReset();
        setBitId(null);
      });
    }
  };

  const deleteBit = (id) => {
    StaffService.deleteBitPackage(id).then((data) => {
      notification.success({
        message: "Başarılı",
        description: "Bit paketi silindi",
      });
      setInitialRender(true);
    });
  };
  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  };
  const tailLayout = {
    wrapperCol: { offset: 6, span: 18 },
  };
  const editForm = (bit) => {
    setBitId(bit.id);
    bit = { ...bit, endDate: bit.endDate ? moment(bit.endDate) : null };
    form.setFieldsValue(bit);
    window.scrollTo(0, 0);
  };

  const columns = [
    {
      title: "Paket Adı",
      dataIndex: "bit",
      render: (bit) => <span>{bit.name}</span>,
    },
    {
      title: "Bit adedi",
      dataIndex: "bit",
      render: (bit) => <span>{bit.pieces}</span>,
    },
    {
      title: "Ücret",
      dataIndex: "bit",
      render: (bit) => <span>{bit.price}</span>,
    },
    {
      title: "Para Birimi",
      dataIndex: "bit",
      render: (bit) => <span>{bit.money}</span>,
    },
    {
      title: "Son Tarih",
      dataIndex: "bit",
      render: (bit) => (
        <span>
          {bit.endDate !== null
            ? moment(bit.endDate).local().format("DD MMM, ddd HH:mm")
            : "Yok"}
        </span>
      ),
    },
    {
      title: "Favori mi",
      dataIndex: "bit",
      render: (bit) => <span>{bit.isFavorite ? "Evet" : "Hayır"}</span>,
    },
    {
      title: "Sil",
      render: (text, record) => (
        <Space>
          <Popconfirm
            title="Paketi Silmek istediğinize emin misiniz?"
            onConfirm={() => deleteBit(record.bit.id)}
            okText="Evet"
            cancelText="İptal"
          >
            <Button
              size="small"
              type="ghost"
              danger
              icon={<DeleteOutlined />}
            />
          </Popconfirm>
          <Button
            size="small"
            type="primary"
            ghost
            icon={<EditOutlined />}
            onClick={() => editForm(record.bit)}
          />
        </Space>
      ),
    },
  ];
  return (
    <div>
      <SYBox
        maxWidth="600px"
        padding="20px"
        border="1px solid #ddd"
        bg={SYColors.f5}
      >
        <SYDivider renk="mavi">Yeni Bit Paketi Tanımlama</SYDivider>
        <Form
          {...layout}
          form={form}
          onFinish={onFinish}
          onReset={onReset}
          initialValues={{ isFavorite: false }}
        >
          <Form.Item
            label="Paket Adı"
            name="name"
            rules={[{ required: true, message: "Paket adı girmelisiniz" }]}
          >
            <Input placeholder="Paket adı giriniz" />
          </Form.Item>
          <Form.Item
            label="Bit Adedi"
            name="pieces"
            rules={[
              { required: true, message: "Bit adedi girmelisiniz" },
              { type: "number", min: 0, max: 10000 },
            ]}
          >
            <InputNumber />
          </Form.Item>
          <Form.Item
            label="Ücret"
            name="price"
            rules={[
              { required: true, message: "Ücret girmelisiniz" },
              { type: "number", min: 0, max: 10000 },
            ]}
          >
            <InputNumber />
          </Form.Item>
          <Form.Item
            label="Para Birimi"
            name="money"
            rules={[{ required: true, message: "Birim seçmelisiniz" }]}
          >
            <Select placeholder="Para birimi seciniz">
              <Select.Option key="tl" value="TL">
                TL
              </Select.Option>
              <Select.Option key="eur" value="EUR">
                EUR
              </Select.Option>
              <Select.Option key="usd" value="USD">
                USD
              </Select.Option>
              <Select.Option key="gbp" value="GBP">
                GBP
              </Select.Option>
              <Select.Option key="yen" value="YEN">
                YEN
              </Select.Option>
              <Select.Option key="krw" value="WON">
                WON
              </Select.Option>
            </Select>
          </Form.Item>

          <Form.Item label="Son Tarih var mı?" name="endDate">
            <DatePicker
              locale={locale}
              showTime
              format="DD MMM YY HH:mm"
              inputReadOnly
            />
          </Form.Item>
          <Form.Item label="Favori mi?" name="isFavorite">
            <Select>
              <Select.Option key="1" value={true}>
                Evet
              </Select.Option>
              <Select.Option key="2" value={false}>
                Hayır
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Space>
              <Button htmlType="reset">Temizle</Button>
              <Button htmlType="submit" type="primary">
                {bitId === null ? "Kaydet" : "Güncelle"}
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </SYBox>
      <br />
      <SYDivider orientation="left" renk="mavi">
        Tanımlanmış Paketler
      </SYDivider>
      <Table bordered dataSource={bits} columns={columns} size="small"></Table>
    </div>
  );
}

export default Bits;
