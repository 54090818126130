import {
  Button,
  DatePicker,
  Divider,
  Form,
  Input,
  notification,
  Popconfirm,
  Select,
  Space,
  Switch,
  Table,
} from "antd";
import React, { useEffect, useState } from "react";
import locale from "antd/es/date-picker/locale/tr_TR";
import { MainService } from "../../services/MainService";
import moment from "moment";
import { DeleteOutlined } from "@ant-design/icons";

function MainAlerts() {
  const [form] = Form.useForm();
  const [initialRender, setInitialRender] = useState(true);
  const [alerts, setAlerts] = useState([]);
  const [swt, setSwt] = useState(false);
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      MainService.getAllAlerts().then((data) => {
        setAlerts(data.data);
      });
    }
  }, [initialRender]);

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };
  const config = {
    rules: [{ type: "object", required: true, message: "Please select time!" }],
  };
  const bildirimKaydet = (values) => {
    values = {
      ...values,
      lastDate: values.lastDate.format("YYYY-MM-DDTHH:mm:ss"),
    };
    MainService.saveMainAlert(values).then((data) => {
      notification.success({
        message: "Başarılı",
        description: "Bildirim başarı ile eklendi",
      });
      setInitialRender(true);
      temizle();
    });
  };
  const temizle = () => {
    form.resetFields();
  };
  const activeChanged = (deger, id) => {
    MainService.changeAlertActive(id, deger).then((data) => {
      notification.success({
        message: "Başarılı",
        description: "Aktivasyon değiştirildi",
      });
      setInitialRender(true);
    });
  };
  const isModalChanged = (deger, id) => {
    MainService.changeIsModalActive(id, deger).then((data) => {
      notification.success({
        message: "Başarılı",
        description: "Aktivasyon değiştirildi",
      });
      setInitialRender(true);
    });
  };
  const deleteAlert = (id) => {
    MainService.deleteAlert(id).then((data) => {
      notification.success({
        message: "Başarılı",
        description: "Bildirim Silindi.",
      });
      setInitialRender(true);
    });
  };
  const columns = [
    {
      title: "Kime?",
      dataIndex: "alert",
      render: (alert) => (
        <span>
          {alert.whoare === 1
            ? "Öğrenci"
            : alert.whoare === 2
            ? "Öğretmen"
            : "Herkes"}
        </span>
      ),
    },
    {
      title: "Başlık",
      dataIndex: "alert",
      render: (alert) => <span>{alert.title}</span>,
    },
    {
      title: "Açıklama",
      dataIndex: "alert",
      render: (alert) => <span>{alert.description}</span>,
    },
    {
      title: "Tip",
      dataIndex: "alert",
      render: (alert) => (
        <span
          style={{
            padding: "5px",
            border: "1px solid #eee",
            color: "gray",
            backgroundColor:
              alert.type === "success"
                ? "#F6FFED"
                : alert.type === "warning"
                ? "#FFFBE6"
                : alert.type === "info"
                ? "#E7F7FF"
                : "#FFF2F0",
          }}
        >
          {alert.type === "success"
            ? "olumlu"
            : alert.type === "warning"
            ? "uyarı"
            : alert.type === "info"
            ? "genel"
            : "olumsuz"}
        </span>
      ),
    },
    {
      title: "Son Tarih",
      dataIndex: "alert",
      render: (alert) => (
        <span
          style={{ color: moment() > moment(alert.lastDate) ? "red" : "black" }}
        >
          {moment(new Date(alert.lastDate)).local().format("DD MMM YY , ddd")}
        </span>
      ),
    },
    {
      title: "Pencere? ",
      dataIndex: "alert",
      render: (alert) => (
        <Switch
          checked={alert.isModal}
          onChange={(a) => isModalChanged(a, alert.id)}
        />
      ),
    },
    {
      title: "Aktif mi?",
      dataIndex: "alert",
      render: (alert) => (
        <Switch
          checked={alert.isActive}
          onChange={(a) => activeChanged(a, alert.id)}
        />
      ),
    },
    {
      title: "İşlem",
      key: "action",
      render: (text, record) => (
        <Popconfirm
          title="Silmek istediğinize emin misiniz?"
          onConfirm={() => deleteAlert(record.alert.id)}
          okText="Evet"
          cancelText="İptal"
        >
          <Button
            size="small"
            type="primary"
            danger
            icon={<DeleteOutlined />}
          />
        </Popconfirm>
      ),
    },
  ];
  return (
    <div>
      <Divider style={{ color: "#2E77D0" }}>Yeni Bildirim Tanımlama</Divider>
      <Form
        form={form}
        style={{ maxWidth: "800px" }}
        {...layout}
        onFinish={bildirimKaydet}
        onReset={temizle}
        initialValues={{ isModal: false }}
      >
        <Form.Item
          label="Başlık"
          name="title"
          rules={[{ required: true, message: "Başlık girmelisiniz." }]}
        >
          <Input placeholder="Bildirim Başlığını giriniz" />
        </Form.Item>
        <Form.Item
          label="Detay"
          name="description"
          rules={[{ required: true, message: "Detay girmelisiniz." }]}
        >
          <Input.TextArea
            autoSize={{ minRows: 2, maxRows: 6 }}
            showCount
            placeholder="Bildirim detayını giriniz"
            maxLength={255}
            minLength={15}
          />
        </Form.Item>
        <Form.Item
          label="Bildirim Şekli"
          name="type"
          rules={[{ required: true, message: " Bildirim şekli seçmelisiniz." }]}
        >
          <Select placeholder="Tip Seçiniz">
            <Select.Option value="info" key="info">
              Genel Bilgilendirme
            </Select.Option>
            <Select.Option value="warning" key="warning">
              Genel Uyarı
            </Select.Option>
            <Select.Option value="success" key="success">
              Olumlu Bilgilendirme
            </Select.Option>
            <Select.Option value="error" key="error">
              Olumsuz Bilgilendirme
            </Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Kimler görecek"
          name="whoare"
          rules={[
            { required: true, message: "Kimlerin göreceğini seçmelisiniz." },
          ]}
        >
          <Select placeholder="Kimler görecek seçiniz">
            <Select.Option value={1} key="ogrenci">
              Sadece Öğrenciler
            </Select.Option>
            <Select.Option value={2} key="ogretmen">
              Sadece Öğretmenler
            </Select.Option>
            <Select.Option value={3} key="herkes">
              Herkes
            </Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="lastDate" label="Ne zamana kadar?" {...config}>
          <DatePicker locale={locale} showTime format="DD MMM YY HH:mm" />
        </Form.Item>
        <Form.Item name="isModal" label="Pencerede Aç">
          <Switch onChange={() => setSwt(!swt)} checked={swt}></Switch>
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Space>
            <Button type="default" htmlType="reset">
              Temizle
            </Button>
            <Button type="primary" htmlType="submit">
              Kaydet
            </Button>
          </Space>
        </Form.Item>
      </Form>
      <Divider style={{ color: "#2E77D0" }}>Tanımlanmış Bildirimler</Divider>
      <Table size="small" bordered columns={columns} dataSource={alerts} />
    </div>
  );
}

export default MainAlerts;
