import React, { createContext, useEffect, useState } from "react";
//import GlobalReducer from "./GlobalReducer";
import { HomeService } from "../services/HomeService";
import { useTranslation } from "react-i18next";

const initialState = {};
export const GlobalContext = createContext(initialState);
export const GlobalContextProvider = ({ children }) => {
  // const [state, dispatch] = useReducer(GlobalReducer, initialState);
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [bitValue, setBitValue] = useState(
    parseInt(localStorage.getItem("bitValue") || null)
  );

  const [unreadMessages, setUnreadMessages] = useState(0);
  const [unreadExams, setUnreadExams] = useState(0);
  const [unreadQuestions, setUnreadQuestions] = useState(0);
  const [kurum, setKurum] = useState(null);
  const [initialRender, setInitialRender] = useState(true);

  const lessonTypes = [
    { key: 1, value: t("global.derstipleri.1") },
    { key: 2, value: t("global.derstipleri.2") },
    { key: 3, value: t("global.derstipleri.3") },
    { key: 4, value: t("global.derstipleri.4") },
    { key: 5, value: t("global.derstipleri.5") },
    { key: 6, value: t("global.derstipleri.6") },
  ];
  const lessonReqTimes = [
    { key: 1, value: t("global.nezaman.hemen") },
    { key: 2, value: t("global.nezaman.1hafta") },
    { key: 3, value: t("global.nezaman.1ay") },
  ];
  const desc = [
    t("global.seviye.1"),
    t("global.seviye.2"),
    t("global.seviye.3"),
    t("global.seviye.4"),
    t("global.seviye.5"),
  ];

  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      HomeService.getCompanyData().then((data) => {
        console.log("company data:", data.data);
        document.title = data.data.name;
        setKurum(data.data);
      });
    }
  }, [initialRender]);

  useEffect(() => {
    localStorage.setItem("bitValue", bitValue);
  }, [bitValue]);
  return (
    <GlobalContext.Provider
      value={{
        tabValue,
        openModal,
        bitValue: parseInt(bitValue),
        setBitValue,
        setOpenModal,
        setTabValue,
        unreadMessages,
        setUnreadMessages,
        unreadExams,
        setUnreadExams,
        unreadQuestions,
        setUnreadQuestions,
        lessonTypes,
        desc,
        lessonReqTimes,
        kurum,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
